import React, { Suspense, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import Consent from "../pages/consent/Consent";

// context
import { reloadUser, useUserDispatch, useUserState } from "../context/UserContext";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import PatientCardPublic from "../pages/patientCardPublic/PatientCardPublic";

import {DndProvider} from 'react-dnd';
import {HTML5Backend} from "react-dnd-html5-backend";
import { hasRole, ROLE_ADMIN } from "../utils/UserUtils";

const Application = React.memo(({ isAuthenticated, user }) => {
  console.log("Application MEMO render", Date.now());
  return <AppInner isAuthenticated={isAuthenticated} user={user} />;
});

export default function App() {
  var userDispatch = useUserDispatch();
  // global
  var { isAuthenticated, user} = useUserState();
  console.log('RENDER APP')

  useEffect(() => {
    if (isAuthenticated && user == null){
      console.log('LOADING APP, RELOAD USER FROM BACKEND')
      reloadUser(userDispatch);
    }
  });

  return <Application isAuthenticated={isAuthenticated} user={user} />

}

function AppInner({isAuthenticated = false, user = null}) {
  let isAdmin = user ? hasRole([ROLE_ADMIN], user) : false;

  let defaultRoute = isAdmin ? "/app/dashboard" : "/app/patients";

  return (
    <DndProvider backend={HTML5Backend}>
      <HashRouter history="">
        <Switch>
          <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
          <Route exact path="/app" render={() => <Redirect to={defaultRoute} />} />

          <PrivateRoute path="/app" component={LayoutWithTranslation} />
          <PublicRoute path="/login" component={PageWithTranslations(Login)} />
          <PublicRoute path="/consent/:companyId" component={PageWithTranslations(Consent)} />
          <PublicRouteNoRedirect path="/p/:patientId/c/:cardId" component={PageWithTranslations(PatientCardPublic)} />
          <Route component={Error} />
        </Switch>
      </HashRouter>
    </DndProvider>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  function PublicRouteNoRedirect({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
            React.createElement(component, props)
        }
      />
    );
  }


}

// loading component for suspense fallback
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#FFFFFF",
    backgroundColor: theme.palette.primaryTransparent.main
  },
}));

function Loader() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

const LayoutWithTranslation = () => (
  <Suspense fallback={<Loader />}>
    <Layout />
  </Suspense>
);

const PageWithTranslations = (Component) => (props) => (
  <Suspense fallback={<Loader />}>
    <Component {...props} />
  </Suspense>
);