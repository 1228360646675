import { useTheme } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// styles
import useStyles from "./styles";
import { Typography } from "../../Wrappers";
import SelectionDialog from "../../SelectionDialog/SelectionDialog";
import { URL_ARTICLES_CREATE, URL_TEMPLATES_GET, URL_USER_DEFAULTS_CREATE } from "../../../utils/UrlConstants";
import { getTemplateColumns, TEMPLATE_COLUMNS } from "../../../utils/TemplateUtils";
import { reloadUser, useUserDispatch, useUserState } from "../../../context/UserContext";
import HtmlPreview from "../../HtmlPreview/HtmlPreview";
import {
  getUserDefault,
  USER_DEFAULT_NAME_PRIVATE,
  USER_DEFAULT_TYPE_TEMPLATE,
} from "../../../utils/UserDefaultsUtils";
import { API } from "../../../services/AuthService";
import { sendNotification } from "../../ToastContainerCustom/ToastContainerCustom";

export default function TemplateSelection({ template = null, type = null, onSelect= () => {}, value = null}) {

  var theme = useTheme();
  var classes = useStyles(theme);
  const {i18n} = useTranslation();
  var { user } = useUserState();
  var userDispatch = useUserDispatch();

  var privateTemplateDefault = getUserDefault(USER_DEFAULT_NAME_PRIVATE, user);
  var userDefaultPrivateTemplate = privateTemplateDefault && privateTemplateDefault.valueTemplate ? privateTemplateDefault.valueTemplate : null;
  if (userDefaultPrivateTemplate && !value){
    onSelect(userDefaultPrivateTemplate.id);
  }

  var [isSelectionOpen, setIsSelectionOpen] = useState(false);
  var [templateSelected, setTemplateSelected] = useState(userDefaultPrivateTemplate);



  var configuration = {
      entity : 'templates',
      apiGet : URL_TEMPLATES_GET,
      columns : getTemplateColumns(classes),
      selectableRows : "single",
      notIds : [],
  }

  function onSelectTemplate(row){
    console.log('selected Row '+JSON.stringify(row))
    setIsSelectionOpen(false);
    setTemplateSelected(row);
    let templateId = null;
    if (row){
      templateId = row.id;
    }
    onSelect(templateId);
  }

  function onSelectTemplateAsDefault(){
    // update the user default
    let api = URL_USER_DEFAULTS_CREATE;
    let form = { name : USER_DEFAULT_NAME_PRIVATE, type : USER_DEFAULT_TYPE_TEMPLATE, value : templateSelected.id};
    API().post(api, form)
      .then(res => {
        sendNotification(i18n.t('defaults.actions.create.success') , "success");
        reloadUser(userDispatch);
      }).catch(e => {
        sendNotification(i18n.t('defaults.actions.create.error'), "error");
      })

  }

  function onRemoveTemplate(){
    setTemplateSelected(null);
  }

  //Template Selected : {JSON.stringify(templateSelected)}

  return (
    <>
    <Grid container spacing={4} style={{marginBottom: "20px", padding: "20px", backgroundColor: '#eee'}}>

      <h2>{i18n.t('templates.suggestion.title')}</h2>

      <Grid container spacing={4} style={{marginTop: "20px", padding: "20px", backgroundColor: 'white'}}>
      <Grid item xs={12}>
        <Typography variant="h6" color="textSecondary">
          {templateSelected ? (
              <div>
                <div  style={{marginBottom: "10px"}}>{templateSelected.title}</div>
                <HtmlPreview value={templateSelected.value} />
              </div>
            ) :
            (
              <div>{i18n.t('templates.suggestion.noSelected')}</div>
            )}
        </Typography>
      </Grid>

      {templateSelected && userDefaultPrivateTemplate && userDefaultPrivateTemplate.id === templateSelected.id && (
        <Grid item xs={12} style={{textAlign: "right"}}>
          <div className={classes.templateDefault}>{i18n.t('templates.suggestion.default')}</div>
        </Grid>
      )}

      {templateSelected && (!userDefaultPrivateTemplate || userDefaultPrivateTemplate.id !== templateSelected.id) && (
        <Grid item xs={12} style={{textAlign: "right"}}>
          <Button
          classes={{ root: classes.buttonRow }}
          variant="outlined"
          size="small"
          color="warning"
          onClick={onSelectTemplateAsDefault}
          style={{marginLeft: "0px"}}
          >
            {i18n.t('templates.suggestion.setDefault')}
          </Button>
        </Grid>
      )}

      </Grid>

    </Grid>

      <Grid item xs={6} style={{textAlign: "left"}} >
        {templateSelected && (
          <Grid item xs={12} >
            <Button
              classes={{ root: classes.buttonRow }}
              variant="outlined"
              size="small"
              color="danger"
              onClick={onRemoveTemplate}
              style={{marginLeft: "0px"}}
            >
              {i18n.t('templates.suggestion.noTemplate')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6} style={{textAlign: "right"}} >
        <Button
          classes={{ root: classes.buttonRow }}
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {setIsSelectionOpen(true)}}
          style={{marginLeft: "0px"}}
        >
          {i18n.t('templates.suggestion.choose')}
        </Button>
      </Grid>

      <SelectionDialog open={isSelectionOpen} configuration={configuration} onClose={() => {setIsSelectionOpen(false)}} onSelect={onSelectTemplate}/>
    </>
  );
}