import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import ErrorContainer from "../../../../components/ErrorContainer";
import { Close as CloseIcon, History } from "@material-ui/icons";
import GeneralEditor from "../../../../components/GeneralEditor/GeneralEditor";
import ExerciseSuggestionsSelectionDialog
  from "../../../../components/SelectionDialog/ExerciseSuggestionSelectionDialog/ExerciseSuggestionsSelectionDialog";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

const styles = useStyles;

class Exercise extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entity : "exercises",
      dimensions: { width: 0, height: 0 },
      error : false,
      description : props.data.description,
      isOpenSelectSuggestion : false,
      injectValue : false
    };

    this.onDelete = this.onDelete.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onSubmitChange = this.onSubmitChange.bind(this);
    this.onSuggestionsOpen = this.onSuggestionsOpen.bind(this);
    this.onSuggestionsClose = this.onSuggestionsClose.bind(this);
    this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
  }

  componentDidMount() {

    let height = (this.container.offsetWidth - 32) / 16 * 9;

    this.setState({
      dimensions: {
        width: this.container.offsetWidth - 32,
        height: height,
      },
    });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    //console.log('Error detected componentDidCatch');
  }

  onDelete(){
    //console.log('on Delete');
    const {data} = this.props;
    if (this.props.onDelete){
      this.props.onDelete(data.id);
    }
  }

  onChangeDescription(data){
    //console.log('on change : '+event.target.value);
    this.setState({description : data, injectValue : false})
  }

  onSubmitChange(content){
    //console.log('OnSubmitChange');
    //const {description} = this.state;
    const {data} = this.props;

    let description = content;

    this.setState({description : description})

    if (this.props.onSave){
      this.props.onSave(data.id, {description : description});
    }
  }

  onSuggestionsOpen(){
    this.setState({isOpenSelectSuggestion : true})
  }

  onSuggestionsClose(){
    this.setState({isOpenSelectSuggestion : false})
  }

  onSelectSuggestion(row){
    //console.log('rowwwwwwww ' + JSON.stringify(row))
    //console.log('rowwwwwwww str' + String(row.description))
    this.setState({isOpenSelectSuggestion : false, description : String(row.description), injectValue : true})
  }

  render(){
    const {data, classes, t, autoSave, canEdit, style={}, compressed = false} = this.props;
    const {dimensions, error, description, isOpenSelectSuggestion, entity, injectValue} = this.state;
    const {width, height} = dimensions;
    const opts = {
      width: width,
      height: height
    };

    const youtubeId = getVideoId(data.exercise.value);
    //console.log('dimensions ' + JSON.stringify(dimensions));

    if (error){
      return <ErrorContainer msg={t('errors.component')}/>;
    }

    return(
      <>
        {error ? <ErrorContainer msg={t('errors.component')}/> : (
          <Card className={classes.root} style={{...style}}>
            <CardHeader
              title={data.exercise.title}
              subheader={data.exercise.description}
              ref={el => (this.container = el)}
              action={
                canEdit ? <CloseIcon aria-label="Delete" onClick={this.onDelete} className={classes.closeButton}/> : null
              }
            />

            {!compressed && (
              <CardContent>
                {width > 0 ? <YouTube videoId={youtubeId.id} opts={opts}/> : <></>}
              </CardContent>
            )}

            {!compressed && (
              <CardContent>
                {canEdit ? (
                  <GeneralEditor key={'exercise-'+data.exercise.id}
                                 data={description}
                                 onSave={this.onSubmitChange}
                                 placeholder={t('placeholders.addnote')}
                                 update={true}
                                 autoSave={autoSave}
                                 controlUpdate={injectValue}
                  />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: description }} className={classes.exerciseGeneral}/>
                )}
              </CardContent>
            )}

            {(canEdit && !compressed) && (
              <CardContent>
                <Tooltip title={t('patients.detail.'+entity+'.suggestions.button')}>
                  <IconButton className={classes.iconButton} onClick={this.onSuggestionsOpen} aria-label={"ariaLabel-exercise-"+data.exercise.id}>
                    <History className={classes.editIcon} />
                  </IconButton>
                </Tooltip>
              </CardContent>
            )}

            <ExerciseSuggestionsSelectionDialog open={isOpenSelectSuggestion}
                                                onClose={this.onSuggestionsClose}
                                                onSelect={this.onSelectSuggestion}
                                                id={data.exercise.id}
            />
          </Card>
        )}
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(Exercise));

Exercise.defaultProps = {
  canEdit : true
}