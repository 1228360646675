import React, { useState } from "react";
import { Grid} from "@material-ui/core";
import moment from "moment";
import { useTheme } from "@material-ui/styles";
import { BarChart, Bar } from "recharts";

// styles
import useStyles from "./styles";

// components
import Widget from "../../../../components/Widget";
import { Typography } from "../../../../components/Wrappers";
import UserPreview from "../../../../components/User/UserPreview";
import { convert, DATETIME_FORMAT } from "../../../../utils/DateUtils";
import { useTranslation } from "react-i18next";

export default function DashboardUserActivityStatistics({data, ...props}) {
  const { user, lastDay, lastWeek, lastMonth } = data;

  const classes = useStyles();
  const theme = useTheme();
  const {i18n} = useTranslation();


  function renderSingleRow(title, stats, days, showChart = true){
    return (

        <div className={classes.statCell} style={{width:"25%"}} >
          <Typography size="sm" color="text" colorBrightness="secondary">
            {title}
          </Typography>

          <Grid container alignItems="center">
            <Typography variant="h6">{stats.total}</Typography>
          </Grid>
          {showChart && (
            <Grid container style={{borderBottom:"1px solid #999"}}>
              <BarChart width={50} height={30} data={transformStatsDates(stats, days)}>
                <Bar
                  dataKey="value"
                  fill={theme.palette["primary"].main}
                  radius={10}
                  barSize={10}
                />
              </BarChart>
            </Grid>
          )}

        </div>
    )
  }

  function renderSingleInterval(title, singleIntervalData, days, showChart){
    const {statsPatients, statsPrivateCards, statsPublicCards, statsComments } = singleIntervalData;

    return (
      <div style={{padding:"5px 10px", borderRadius:"5px", backgroundColor:"#f5f5f5", margin:"5px 0px"}}>
        <Typography color="text" colorBrightness="secondary" weight="medium">
          {title}
        </Typography>

        <div className={classes.bottomStatsContainer}>
          {renderSingleRow(i18n.t('dashboard.titles.patients'), statsPatients, days, showChart)}
          {renderSingleRow(i18n.t('dashboard.titles.privateBlocks'), statsPrivateCards, days, showChart)}
          {renderSingleRow(i18n.t('dashboard.titles.publicBlocks'), statsPublicCards, days, showChart)}
          {renderSingleRow(i18n.t('dashboard.titles.comments'), statsComments, days, showChart)}
        </div>

      </div>
    )
  }

  return (
    <Widget
      header={
        <div className={classes.title}>
          <UserPreview size={"sm"} user={user} />
        </div>
      }
    >

        {renderSingleInterval(i18n.t('dashboard.labels.today'), lastDay, 1, false)}
        {renderSingleInterval(i18n.t('dashboard.labels.thisWeek'), lastWeek, 7, true)}
        {renderSingleInterval(i18n.t('dashboard.labels.thisMonth'), lastMonth, 31, true)}
    </Widget>
  );
}

function getRandomData() {
  return Array(7)
    .fill()
    .map(() => ({ value: Math.floor(Math.random() * 10) + 1 }));
}

function transformStatsDates(stats, days){
  let statsToReturn = [];
  let today = moment();
  let pastDate = moment().subtract(days -1,'d');

  //console.log('Today is : ' + today+ " pastDate is " + pastDate)

  for (let i = 0; i < days; i++){
    let actualValue = 0;

    //console.log('Current stats is : ' + JSON.stringify(stats))
    if (stats.dates){
      let currentLoopDate = pastDate.clone().add(i, 'd');
      let currentLoopDateString = convert(currentLoopDate, DATETIME_FORMAT);

      stats.dates.map((item) => {
        let itemDate = convert(item.date, DATETIME_FORMAT);

        if (itemDate === currentLoopDateString){
          actualValue = item.total;
        }

        //console.log('Current item is : ' + JSON.stringify(item) + " item date : " + itemDate + " loop date : " + currentLoopDateString)
      })
    }


    statsToReturn.push({value: actualValue});
  }

  return statsToReturn;

}