import React from "react";
import {
  Button,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import DevDebugJson from "../../../../components/Dev/DevDebugJson";
import { Typography } from "../../../../components/Wrappers";
import GeneralEditor from "../../../../components/GeneralEditor/GeneralEditor";
import { API } from "../../../../services/AuthService";
import { sendNotification } from "../../../../components/ToastContainerCustom/ToastContainerCustom";
import { URL_PATIENTS_UPDATE_DESCRIPTION } from "../../../../utils/UrlConstants";
import HtmlPreview from "../../../../components/HtmlPreview/HtmlPreview";
import * as Icons from "@material-ui/icons";


const styles = useStyles;

class DescriptionEditor extends React.Component {

  constructor(props) {
    super(props);


    this.state = {
      editMode : false,
      entity : 'patients',
      debug : false,
      error : false,
    };

  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentDidMount() {
    //this.resetDescription();
  }

  resetDescription(editMode = false) {
    const {data, debug = false} = this.props;

    //let description = (data && data.card && data.card.description) ? data && data.card && data.card.description : "";
    //if (debug) console.log('Reset Description with ' + description);
    this.setState({
      //description : description,
      editMode : editMode
    })
  }

  loadData(){
    let {debug = false} = this.props;

    if (this.props.onReload){
      if (debug) console.log('Reloading component DescriptionEditor')
      this.props.onReload();
    }
  }

  onSave(description) {
    console.log('SAVE');
    const {data, t} = this.props;

    let urlWithParam = URL_PATIENTS_UPDATE_DESCRIPTION.replace(":id", data.id);
    API().put(urlWithParam, { description })
      .then(res => {
        //if (!partialSave) sendNotification(t('patients.detail.description.save.ok'), "success");
        this.loadData();
      }).catch(e => {
      console.log('On get exception '+JSON.stringify(e));
      sendNotification(t('errors.save'), "error");
    })

  }


  render() {
    const { t, classes, data, debug = false} = this.props;
    const {entity, editMode} = this.state;

    let buttonText = t(entity+'.detail.description.buttonEdit');
    let initialDescription = "<p><span style=\"color: rgb(226,80,65);\">"+t(entity+'.detail.description.title')+"</span></p>"
    if (data && data.card && data.card.description && data.card.description!==""){
      initialDescription = data.card.description;
    } else {
      buttonText = t(entity+'.detail.description.buttonCreate');
    }

    let showTitle = false;


    return (
      <>
        <Grid item xs={12} style={{marginTop:"5px"}}>

          {showTitle && (
            <Typography color="text" colorBrightness="secondary">
              {t(entity+'.detail.description.title')}
            </Typography>
          )}

          {debug && (
            <DevDebugJson data={initialDescription} title={"initialDescription"} component={"DescriptionEditor"} />
          )}

          {!editMode && (
            <div>

              {initialDescription && initialDescription !== "" ? (
                <div style={{padding:"10px 0"}}>
                  <HtmlPreview value={initialDescription} mode={"MAX_HEIGHT"} contentStyle={{maxWidth:"100%"}}/>
                </div>
              ) : (
                <div/>
              )}

              <Grid xs={12} style={{paddingTop:"0px", textAlign :"right"}}>
                <Button variant="outlined" color="primary" size="small" onClick={() => {this.setState({editMode : true})}}>
                  {buttonText}
                </Button>
              </Grid>
            </div>

          )}

          {editMode && (
            <Grid container style={{marginTop:"5px", border:"3px solid #EEE", padding:"20px"}}>

              <GeneralEditor data={initialDescription}
                             onSave={(content) => {this.onSave(content)}}
                             placeholder={t(entity+'.detail.description.placeholder')}
                             autoSave={true}
                             activeOverlay={true}
                             overlayedTitle={(<Typography size="md"><b>{t(entity+'.detail.description.title')}</b></Typography>)}
                             additionalTitle={(<Typography color="text" colorBrightness="secondary">{t(entity+'.detail.description.title')}</Typography>)}
                             mode={"FLAT"}
              />

              <Grid xs={12} style={{paddingTop:"10px", textAlign :"right"}}>
                <Button variant="outlined" color="primary" size="small" onClick={() => {this.resetDescription(false)}}>
                  {t('buttons.close') + " " + t(entity+'.detail.description.title')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(DescriptionEditor));

