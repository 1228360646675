import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { Close as CloseIcon } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Notification from "../Notification";

const styles = useStyles;

class ToastContainerCustom extends React.Component {
  render(){
    const {classes} = this.props;

    return (
      <ToastContainer
        toastClassName={classes.notification}
        className={classes.toastsContainer}
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        progressClassName={classes.notificationProgress}
      />
    );
  }
}

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

export function sendNotification(message, type) {

  let innerType = 'shipped'
  if (type === "error"){
    innerType = 'defence';
  }

  let options = {
    type: type,
    position: toast.POSITION.TOP_RIGHT,
  };

  let props = {
    type: innerType,
    shadowless : true,
    message : message,
    variant : "contained",
    color : type
  }



  return toast(
    <Notification
      {...props}
    />,
    options,
  );
}

export default withTranslation()(withStyles(styles, { withTheme: true})(ToastContainerCustom));