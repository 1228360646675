const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fullContainer: {
    paddingBottom: "0px"
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  tab : {
    border: "1px solid #EEE",
    borderRadius: "2px",
    padding: "10 12"
  },
  gridButtonBlock : {
    textAlign : "right",
    paddingTop: 5
  },
  fullHeightBodyScroll: {
    height: "100%",
    //overflowY:"auto",
    marginBottom:"20px"
  }
});

export default styles;