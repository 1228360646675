export const CACHE_PATIENTS_LIST = "PATIENTS_LIST";

export function storeInCache(name, value){
  localStorage.setItem(name, JSON.stringify(value));
}

export function getFromCache(name, defaultValue = null){
  let valueFromCache = localStorage.getItem(name);
  if (valueFromCache === null) {
    return defaultValue;
  } else {
    return JSON.parse(valueFromCache);
  }
}

export function cleanCache(){
  let cacheToClean = [
    CACHE_PATIENTS_LIST
  ];

  cacheToClean.map((item) => {
    localStorage.removeItem(item);
  })

  console.log('Cache clean. ' + JSON.stringify(cacheToClean))
}