import React, { Component } from "react";
import {
  Grid, IconButton,
} from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";

import useStyles from "./styleCommentItem";

// styles
// components
import * as Icons from "@material-ui/icons";
import { Typography } from "../../../../components/Wrappers";
import UserPreview from "../../../../components/User/UserPreview";
import Card from "@material-ui/core/Card";
import { convert } from "../../../../utils/DateUtils";
import DevDebugJson from "../../../../components/Dev/DevDebugJson";
import HtmlPreview from "../../../../components/HtmlPreview/HtmlPreview";
import { MEDIAQUERY_BIG, MEDIAQUERY_MEDIUM } from "../../../../components/Responsive/ResponsiveBlock";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Tooltip from "@material-ui/core/Tooltip";

export default function CommentItem ({
                                       data,
                                       deleteButton=false,
                                       onDelete = ()=>{},
                                       debug = false
}){

  var classes = useStyles();
  const {i18n} = useTranslation();

  const isBig = useMediaQuery(MEDIAQUERY_BIG);
  const isMedium = useMediaQuery(MEDIAQUERY_MEDIUM);

  let size = "xs";
  let classArrowContainer = classes.userIconArrowContainer;
  if (isBig){
  } else if (isMedium){
    classArrowContainer = classes.userIconArrowContainerSmall;
  } else {
    classArrowContainer = classes.userIconArrowContainerSmall;
  }

  return (
    <Grid item xs={12}>
      <Card className={classes.commentContent}>

        <DevDebugJson component={"CommentItem"} data={data} title={"data"} debug={debug}/>

        <div className={classes.createdAtContainer}>
          <Typography color="text" colorBrightness="secondary" size="sm">
            {i18n.t('generic.createdAt')} {convert(data.createdAt)}
          </Typography>
        </div>

        <div className={classes.commentUsers}>

          <div className={classes.userContainer}>
            <UserPreview key={"comment-user-"+data.id} size={size} user={data.createdBy} />
          </div>

          <div className={classArrowContainer}>
            <Icons.ArrowForward className={classes.userIconArrow} />
          </div>

          <div className={classes.userContainer}>
            <UserPreview size={size} user={data.user} />
          </div>

        </div>

        <div className={classes.messageContainer}>
          <HtmlPreview value={data.value}/>
        </div>

        {deleteButton && (
          <div className={classes.deleteButtonContainer}>
            <Tooltip title={i18n.t("patients.detail.comment.create.buttons.delete")}>
              <IconButton className={classes.iconButton}
                          size={"small"}
                          onClick={() => {
                            console.log("Delete item comment : " + data.id);
                            onDelete();
                          }}
              >
                <Icons.Delete className={classes.editIcon} style={{fontSize:"18px"}}/>
              </IconButton>
            </Tooltip>
          </div>
        )}


      </Card>
    </Grid>
  )
}