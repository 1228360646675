import React from "react";
import Widget from "../../../../components/Widget";
import { Grid } from "@material-ui/core";
import DevDebugJson from "../../../../components/Dev/DevDebugJson";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "../../../../components/Wrappers";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

export default function DashboardStatsTimedGeneric({
                                                 data,
                                                 title = null,
                                                 loading,
                                                 error,
                                                 debug = false,
                                                 ...props
}) {
  var classes = useStyles();
  var theme = useTheme();
  const {i18n} = useTranslation();

  let {total = 0, totalToday = 0, totalLastWeek = 0, totalLastMonth = 0} = data ? data : {};

  if (!title) title = i18n.t('dashboard.titles.patientsCreated')

  return (
    <Widget
      title={title}
      upperTitle
      disableWidgetMenu
    >
      {loading && (
        <CircularProgress />
      )}

      <div className={classes.visitsNumberContainer}>
        <Typography size="xl" weight="medium">
          {total}
        </Typography>
      </div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography color="text" colorBrightness="secondary">
            {i18n.t('dashboard.labels.today')}
          </Typography>
          <Typography size="md">{totalToday}</Typography>
        </Grid>
        <Grid item>
          <Typography color="text" colorBrightness="secondary">
            {i18n.t('dashboard.labels.thisWeek')}
          </Typography>
          <Typography size="md">{totalLastWeek}</Typography>
        </Grid>
        <Grid item>
          <Typography color="text" colorBrightness="secondary">
            {i18n.t('dashboard.labels.thisMonth')}
          </Typography>
          <Typography size="md">{totalLastMonth}</Typography>
        </Grid>
      </Grid>

      {debug && (
        <div>
          <DevDebugJson data={data} title={"data"} component={"DashboardStatsPatients"} />
          <DevDebugJson data={loading} title={"loading"} component={"DashboardStatsPatients"} />
        </div>
      )}
    </Widget>
  )
}


