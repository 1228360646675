const styles = theme => ({
  notificationProgress: {
    visibility: "hidden",
  },
  notification: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    boxShadow: "none",
    overflow: "visible",
  },
  toastsContainer: {
    width: 400,
    marginTop: theme.spacing(6),
    right: 0,
  },
  notificationCloseButton: {
    position: "absolute",
    right: theme.spacing(2),
  },
});

export default styles;