import React, { Component } from "react";
import {
  Button,
  CircularProgress,
  Grid, IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import {
  URL_ARTICLES_CREATE,
  URL_CARD_BLOCK_DELETE,
  URL_CARD_COMMENT_ADD,
  URL_CARD_COMMENT_DELETE,
  URL_CARD_COMMENT_UPDATE,
  URL_PRIVATE_CARD_BLOCK_DELETE,
  URL_PRIVATE_CARD_GENERAL_UPDATE,
  URL_PRIVATE_CARD_ORDER_UPDATE,
} from "../../../../utils/UrlConstants";
import { API } from "../../../../services/AuthService";
import ErrorContainer from "../../../../components/ErrorContainer";
import Widget from "../../../../components/Widget";
import ToastContainerCustom, { sendNotification } from "../../../../components/ToastContainerCustom/ToastContainerCustom";
import GeneralEditor from "../../../../components/GeneralEditor/GeneralEditor";
import * as Icons from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CreatePrivateSectionFormDialog
  from "../../../../components/FormDialog/CreateSectionFormDialog/CreatePrivateSectionFormDialog";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableTabPanel from "../../../../components/DraggableTabPanel/DraggableTabPanel";
import { Typography } from "../../../../components/Wrappers";
import ExerciseSelectionDialog
  from "../../../../components/SelectionDialog/ExerciseSelectionDialog/ExerciseSelectionDialog";
import UserSelectionDialog from "../../../../components/SelectionDialog/UserSelectionDialog/UserSelectionDialog";
import { UserStateContext } from "../../../../context/UserContext";
import UserAvatar from "../../../../components/UserAvatar";
import UserPreview from "../../../../components/User/UserPreview";
import Card from "@material-ui/core/Card";
import CommentItem from "./CommentItem";
import { hasRole, ROLE_ADMIN } from "../../../../utils/UserUtils";

const styles = useStyles;

class CommentSection extends React.Component {

  constructor(props) {
    super(props);

    //console.log('PROPS ' + JSON.stringify(props.match));
    //match.params.patientId

    this.state = {
      debug : false,
      entity : 'patients',
      urlAddComment : URL_CARD_COMMENT_ADD,
      urlDeleteComment : URL_CARD_COMMENT_DELETE,
      urlUpdateComment : URL_CARD_COMMENT_UPDATE,
      isLoading : true,
      error : false,
      isOpenAddComment : false,
      isOpenAddCommentUserSelection : false,
      isOpenDeletePublicBlock : false,

      selectedUserComment : null,
      commentValue : "",
    };

    this.onAddComment = this.onAddComment.bind(this);
    this.onCloseAddComment = this.onCloseAddComment.bind(this);

  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentDidMount() {
    const {match} = this.props;
  }

  loadData(){
    if (this.props.onReload){
      this.props.onReload();
    }
  }

  onAddComment(open=true){
    this.setState({isOpenAddComment : open})
  }

  onCloseAddComment(){
    this.setState({isOpenAddComment : false})
  }

  onOpenAddCommentUserSelection(){
    this.setState({isOpenAddCommentUserSelection : true})
  }
  onCloseAddCommentUserSelection(){
    this.setState({isOpenAddCommentUserSelection : false})
  }

  onSubmitChange(content){
    let {t, data} = this.props;
    let {entity, urlAddComment, selectedUserComment} = this.state;

    let form = {
      value : content,
      userId : selectedUserComment.id
    }

    let url = urlAddComment.replace(":cardId", data.card.id)

    API().post(url, form)
      .then(res => {
        sendNotification(t(entity+'.detail.comment.create.success'), "success");
        this.setState({selectedUserComment : null, isOpenAddCommentUserSelection : false}, () => {
          this.loadData();
          this.setState({isOpenAddComment : false})
        })
      }).catch(e => {
        sendNotification(t(entity+'.detail.comment.create.error'), "error");
    })
  }

  onDelete(item){
    let {t, data} = this.props;
    let {entity, urlDeleteComment} = this.state;

    let url = urlDeleteComment.replace(":cardId", data.card.id).replace(":commentId", item.id)
    API().delete(url)
      .then(res => {
        sendNotification(t(entity+'.detail.comment.delete.success'), "success");
        this.loadData();
      }).catch(e => {
      console.log('On get exception ' + JSON.stringify(e));
      sendNotification(t(entity+'.detail.comment.delete.error'), "error");
    })
  }

  onSelectUserComment(user){
      this.setState({selectedUserComment : user, isOpenAddCommentUserSelection : false})
  }

  renderButton(){
    const { t, classes} = this.props;
    const {entity, selectedUserComment} = this.state;

    return (
      <Button
        classes={{ root: classes.button }}
        variant={selectedUserComment ? "outlined" : "contained"}
        size={"small"}
        color={"primary"}
        onClick={() => {this.onOpenAddCommentUserSelection()}}
      >
        {t(entity+".detail.comment.create.buttons.select")}
      </Button>
    )
  }

  render() {
    const { t, classes, data, isLoading} = this.props;
    const {error, entity, isOpenAddComment, isOpenAddCommentUserSelection, selectedUserComment} = this.state;

    let user = this.context.user;

    let isAdmin = hasRole([ROLE_ADMIN], user)


    return (
      <>

            { error ? <ErrorContainer msg={t('errors.loadData')} /> : (
              <>
                {isLoading ? (
                  <CircularProgress size={26}/>
                ) : (
                  <>


                    <Grid item xs={12}>
                      <Widget
                        title={t(entity+'.detail.comment.title') + " (" +data.card.cardComments.length + ")"}
                        subTitle={t(entity+'.detail.comment.subTitle')}
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu = {true}
                        leftBorder = "orange"
                        preTitle={(<Icons.Block style={{color : "orange"}}/>)}
                        titleAndSubtitleSameRow={false}
                      >


                        <Grid container spacing={4}>

                          <Grid item xs={12}>
                            <Button size="small" variant="outlined" color="primary" onClick={() => this.onAddComment(!isOpenAddComment)}>
                              {isOpenAddComment ? t(entity+".detail.comment.create.buttons.hide") : t(entity+".detail.comment.create.buttons.add")}
                            </Button>
                          </Grid>

                          {isOpenAddComment ? (
                            <Grid item xs={12}>
                              <Widget
                                subTitle={t(entity+".detail.comment.create.buttons.add")}
                                upperTitle
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu = {true}
                              >

                                <Grid container spacing={4} className={classes.containerUserSelect}>

                                    {selectedUserComment ? (
                                      <>
                                        <Grid item xs={12} md={12}>
                                          <div>
                                            <UserPreview user={selectedUserComment} />
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          {this.renderButton()}
                                        </Grid>
                                      </>
                                    ) : (
                                      <Grid item xs={12}>
                                        {this.renderButton()}
                                      </Grid>
                                    )}


                                </Grid>

                                {selectedUserComment && (
                                  <GeneralEditor key={'comment-editor'} data={""}
                                                 onSave={(content) => {this.onSubmitChange(content)}}
                                                 buttonSaveText={t(entity+".detail.comment.create.buttons.save")}
                                                 placeholder={t(entity+'.detail.comment.create.value.placeholder')}
                                                 buttonVariant={"contained"}
                                                 buttonColor={"primary"}
                                  />
                                )}

                              </Widget>

                            </Grid>
                          ) : (

                            <div></div>
                          )}

                        </Grid>


                        <Grid container spacing={4}>
                          {data.card.cardComments && data.card.cardComments.map((item, index) => {
                            return (
                              <CommentItem data={item}
                                           deleteButton={isAdmin}
                                           onDelete={()=>{
                                             console.log("From comment section deleted item : " + JSON.stringify(item))
                                             this.onDelete(item)
                                           }}
                              />
                            )
                          })}

                        </Grid>

                      </Widget>
                    </Grid>

                  </>
                )
                }
              </>
            ) }
        <UserSelectionDialog open={isOpenAddCommentUserSelection}
                             onClose={() => {this.onCloseAddCommentUserSelection()}}
                             onSelect={(items) => {this.onSelectUserComment(items[0])}}
                             notId={[user.id]}
                             title={t(entity+".detail.comment.create.user.label")}
        />
        <ToastContainerCustom />
      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(CommentSection));
CommentSection.contextType = UserStateContext;

