import { useTheme } from "@material-ui/styles";
import React, { useState } from "react";
// styles
import useStyles from "./styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import * as Icons from "@material-ui/icons";
import { useTranslation } from "react-i18next";

export default function HtmlPreview({ value = "" , mode = "NORMAL", open=true, maxHeight="5em", containerStyle={}, contentStyle = {}}) {
  let [openContent, setOpenContent] = useState(open)

  var theme = useTheme();
  var classes = useStyles(theme);
  const {i18n} = useTranslation();

  if (mode === "NORMAL"){
    return (
      <div className={classes.htmlPreviewBox}
           dangerouslySetInnerHTML={{ __html: value }}
           style={{...containerStyle, ...contentStyle}}
      />
    )
  } else if (mode === "HIDE"){
    return (
      <div style={{...containerStyle}}>
        {openContent ? (
          <div>
            <div className={classes.htmlPreviewBox}
                 dangerouslySetInnerHTML={{ __html: value }}
                 style={{...contentStyle}}
            />

            <div style={{textAlign:"right"}} onClick={() => setOpenContent(!openContent)}>
              <Button color="textSecondary" size="small" onClick={() => setOpenContent(!openContent)}>
                {i18n.t('buttons.showLess')}
              </Button>
            </div>
          </div>
        ) : (
          <div>

            <div className={classes.htmlPreviewBox}
                 style={{maxHeight:maxHeight, overflow:"hidden", ...contentStyle}}
                 dangerouslySetInnerHTML={{ __html: value }}
            />

            <div style={{textAlign:"right"}} onClick={() => setOpenContent(!openContent)}>
              <Button color="textSecondary" size="small" onClick={() => setOpenContent(!openContent)}>
                {i18n.t('buttons.showMore')}
              </Button>
            </div>
          </div>

        )}
      </div>

    );
  } else if (mode === "MAX_HEIGHT"){
    return (
      <div className={classes.contentMaxHeight}
           dangerouslySetInnerHTML={{ __html: value }}
           style={{overflow:"auto",...containerStyle, ...contentStyle}}
      />
    )
  }



}