import { convert } from "./DateUtils";
import React from "react";
import HtmlPreview from "../components/HtmlPreview/HtmlPreview";
import UserPreview from "../components/User/UserPreview";
import {
  URL_DICTIONARIES_CREATE,
  URL_DICTIONARIES_DELETE,
  URL_DICTIONARIES_GET,
  URL_DICTIONARIES_GET_BY_ID, URL_DICTIONARIES_UPDATE,
} from "./UrlConstants";
import * as formUtils from "./FormUtils";
import { hasRole, ROLE_ADMIN } from "./UserUtils";

export function getDictionaryConfiguration(user, classes){
  return {
    entity : 'dictionaries',
    apiGet : URL_DICTIONARIES_GET,
    apiGetById : URL_DICTIONARIES_GET_BY_ID,
    apiDelete : URL_DICTIONARIES_DELETE,
    apiCreate : URL_DICTIONARIES_CREATE,
    apiUpdate : URL_DICTIONARIES_UPDATE,
    columns : getDictionaryColumns(classes),
    formValidation : {
      title: {
        type: formUtils.REQUIRED_INPUT,
      },
      value : {
        type : formUtils.REQUIRED_INPUT_HTML,
        editor : true
      }
    },
    formApiMapper : (res) => {
      return {
        title : res.data.title,
        value : res.data.value,
      }
    },
    deleteRow : hasRole([ROLE_ADMIN], user)
  }
}

export function getDictionaryColumns(classes) {
  return [
    {
      name: 'title',
    },
    {
      name: 'value',
      options: {
        customBodyRender: (value) => {
          return <HtmlPreview value={value} contentStyle={{minWidth:"15rem"}}/>
        }
      }
    },
    {
      name: 'createdBy',
      options : {
        sortDirection: 'none',
        customBodyRender:(value) => {
          return <UserPreview size={"sm"} user={value} />
        }
      }
    },
    {
      name: 'createdAt',
      options: {
        sortDirection: 'none',
        customBodyRender: (value) => {
          return convert(value)
        }
      }
    }
  ];
}