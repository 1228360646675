import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  container : {
    padding:"10px",
    border : "1px solid #EEE",
    display: 'flex',
    flexDirection:"row",
    alignItems:'center',
    borderRadius: "5px"
  },
  containerAvatar : {
    marginRight:"10px"
  },
  containerName : {
    fontWeight:"bold",
    fontSize : "15px"
  },

  text: {
    color: "white",
  },


  containerSmall : {
    padding:"5px",
    border : "1px solid #EEE",
    display: 'flex',
    flexDirection:"row",
    alignItems:'center',
    borderRadius: "5px"
  },
  containerNameSmall : {
    fontWeight:"bold",
    fontSize : "12px"
  },
  containerAvatarSmall : {
    marginRight:"3px"
  },

  containerxSmall : {
    padding:"5px",
    border : "1px solid #EEE",
    display: 'flex',
    flexDirection:"row",
    alignItems:'center',
    borderRadius: "5px"
  },
  containerNamexSmall : {
    fontWeight:"bold",
    fontSize : "10px"
  },
  containerAvatarxSmall : {
    marginRight:"3px"
  },
}));
