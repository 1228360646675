import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DataTable from "../../DataTable";
import { URL_EXERCISES_GET} from "../../../utils/UrlConstants";
import { convert } from "../../../utils/DateUtils";
import { getThumbnail } from "../../../utils/YoutubeUtils";

const styles = useStyles;

class ExerciseSelectionDialog extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      entity : "exercises",
      enableButton : false,
      api : URL_EXERCISES_GET,
      columns : [
        {
          name: 'title',
        },
        {
          name: 'type',
          options : {
            filter: true,
            filterOptions: {
              names: ['youtube', 'others']
            },
          }
        },
        {
          name: 'value',
          options : {
            customBodyRender: (value => {
              return (<a href={value} target="_blank" rel="noopener noreferrer" className="link">{value}</a>)
            })
          }
        },
        {
          name: 'value',
          label : 'Preview',
          options: {
            customBodyRender: (value => {
              return (<img src={getThumbnail(value)} alt="youtube preview" className={classes.thumbnail} /> )
            })
          }
        },
        {
          name: 'createdAt',
          options: {
            sortDirection: 'none',
            customBodyRender: (value) => {
              return convert(value)
            }
          }
        }
      ],
      rowsSelected : []
    };
    this.baseState = this.state;
    this.onClose = this.onClose.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRowsSelect = this.onRowsSelect.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.open === true && this.props.open === false) {
      //console.log('reopening');
      this.setState(this.baseState);
    }
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
  }

  onSelect(){
    const {rowsSelected} = this.state;
    if (this.props.onSelect){
      this.props.onSelect(rowsSelected);

      // call the api
      // notify
      // close
    }
  }

  onRowsSelect(rowsSelected){
    console.log('rowsSelected : ' + JSON.stringify(rowsSelected));
    this.setState({
      rowsSelected : rowsSelected,
      enableButton : (rowsSelected.length > 0)
    })
  }

  render (){
    const { classes, t, open, onClose } = this.props;
    const { enableButton, api, entity, columns } = this.state;

    return (
      <>
        <Grid container spacing={4}>
          <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth="xl">
            <DialogTitle id="form-dialog-title">{t('patients.detail.'+entity+'.add')}</DialogTitle>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <DataTable entity={entity} api={api} columns={columns} selectableRows="multiple" disableToolbarSelect={true} onRowsSelect={this.onRowsSelect} notId={this.props.notId} selectedProperty={"id"} selectedEntireItem={false} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose}>
                {t("buttons.cancel")}
              </Button>
              <Button onClick={this.onSelect} color="primary" disabled={!enableButton}>
                <div>{t("buttons.select")}</div>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        </>
    );

  }
}

export default withTranslation()(withStyles(styles)(ExerciseSelectionDialog));