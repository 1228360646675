import React from "react";
import {
  Button,
  CircularProgress,
  Grid, IconButton, Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import {
  URL_CARD_ARTICLE_ADD,
  URL_CARD_ARTICLE_DELETE,
  URL_CARD_ARTICLE_UPDATE,
  URL_CARD_EXERCISE_ADD,
  URL_CARD_EXERCISE_DELETE,
  URL_CARD_EXERCISE_UPDATE,
  URL_PATIENTS_GET_BY_ID,
  URL_CARD_GENERAL_UPDATE, URL_CARD_BLOCK_DELETE, URL_CARD_ORDER_UPDATE, URL_CARD_EXERCISE_ORDER_UPDATE,
} from "../../../../utils/UrlConstants";
import { API } from "../../../../services/AuthService";
import ErrorContainer from "../../../../components/ErrorContainer";
import Widget from "../../../../components/Widget";
import ExerciseSelectionDialog from "../../../../components/SelectionDialog/ExerciseSelectionDialog/ExerciseSelectionDialog";
import ToastContainerCustom, { sendNotification } from "../../../../components/ToastContainerCustom/ToastContainerCustom";
import Article from "../Article/Article";
import ArticleSelectionDialog from "../../../../components/SelectionDialog/ArticleSelectionDialog/ArticleSelectionDialog";
import GeneralEditor from "../../../../components/GeneralEditor/GeneralEditor";
import * as Icons from "@material-ui/icons";
import CreatePublicSectionFormDialog
  from "../../../../components/FormDialog/CreateSectionFormDialog/CreatePublicSectionFormDialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableTabPanel from "../../../../components/DraggableTabPanel/DraggableTabPanel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { convert } from "../../../../utils/DateUtils";
import ExerciseDraggableContainer from "../Exercise/ExerciseDraggableContainer";

const styles = useStyles;

class PublicSection extends React.Component {

  constructor(props) {
    super(props);

    //console.log('PROPS ' + JSON.stringify(props.match));
    //match.params.patientId

    this.state = {
      debug : false,
      entity : 'patients',
      data : { fullName : ''},
      url : URL_PATIENTS_GET_BY_ID,
      urlAddExercise : URL_CARD_EXERCISE_ADD,
      urlDeleteExercise : URL_CARD_EXERCISE_DELETE,
      urlUpdateExercise : URL_CARD_EXERCISE_UPDATE,
      urlAddArticle : URL_CARD_ARTICLE_ADD,
      urlDeleteArticle : URL_CARD_ARTICLE_DELETE,
      urlUpdateArticle : URL_CARD_ARTICLE_UPDATE,
      urlUpdateGeneral : URL_CARD_GENERAL_UPDATE,
      urlDeleteBlock : URL_CARD_BLOCK_DELETE,
      urlUpdateOrder : URL_CARD_ORDER_UPDATE,
      urlExcerciseUpdateOrder : URL_CARD_EXERCISE_ORDER_UPDATE,
      isLoading : true,
      error : false,
      isOpenSelectExercise :  false,
      isOpenSelectArticle :  false,
      isOpenAddSection : false,
      isOpenDeletePublicBlock : false,
      activeTabId : 0,
      openUpdateSection : null
    };

    this.onAddExercise = this.onAddExercise.bind(this);
    this.onCloseAddExercise = this.onCloseAddExercise.bind(this);
    this.onSelectAddExercise =  this.onSelectAddExercise.bind(this);
    this.onDeleteExercise = this.onDeleteExercise.bind(this);
    this.onUpdateExercise = this.onUpdateExercise.bind(this);

    this.onAddArticle = this.onAddArticle.bind(this);
    this.onCloseAddArticle = this.onCloseAddArticle.bind(this);
    this.onSelectAddArticle =  this.onSelectAddArticle.bind(this);
    this.onDeleteArticle = this.onDeleteArticle.bind(this);
    this.onUpdateArticle = this.onUpdateArticle.bind(this);
    this.onUpdateGeneral = this.onUpdateGeneral.bind(this);

    this.onAddSection = this.onAddSection.bind(this);
    this.onCloseAddSection = this.onCloseAddSection.bind(this);
    this.onSelectAddSection = this.onSelectAddSection.bind(this);

    this.openDeletePublicBlockDialog = this.openDeletePublicBlockDialog.bind(this);
    this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);
    this.onDeletePublicBlock = this.onDeletePublicBlock.bind(this);

    this.setActiveTabId = this.setActiveTabId.bind(this);
    this.onChangeOrderTabs = this.onChangeOrderTabs.bind(this);

    this.onButtonPublicCardClick = this.onButtonPublicCardClick.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentDidMount() {
    const {match} = this.props;
  }

  loadData(){
    if (this.props.onReload){
      this.props.onReload();
    }
  }

  onAddExercise(){
    this.setState({isOpenSelectExercise : true})
  }

  onCloseAddExercise(){
    this.setState({isOpenSelectExercise : false})
  }

  onAddSection(){
    this.setState({isOpenAddSection : true})
  }

  onCloseAddSection(){
    this.setState({isOpenAddSection : false})
  }

  onSelectAddSection(){
    this.setState({isOpenAddSection : false, activeTabId : 0})
    this.loadData();
  }

  onSelectAddExercise(exercises){
    //console.log('Adding exercises : ' + JSON.stringify(exercises));
    const {t, data} = this.props;
    const {urlAddExercise} = this.state;

    this.setState({isOpenSelectExercise : false})

    let blockId = this.getBlockId();
    if (blockId == null){
      sendNotification(t('patients.detail.exercises.addFail'), "error");
    } else {
      let urlWithParam = urlAddExercise.replace(":cardId", data.card.id).replace(":blockId", blockId);

      API().put(urlWithParam, {exercises : exercises})
        .then(res => {
          sendNotification(t('patients.detail.exercises.addOk'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception '+JSON.stringify(e));
        sendNotification(t('patients.detail.exercises.addFail'), "error");
      })
    }

  }

  onDeleteExercise(id){
    const {t, data} = this.props;
    const {urlDeleteExercise} = this.state;

    let blockId = this.getBlockId();
    if (blockId == null){
      sendNotification(t('patients.detail.exercises.deleteFail'), "error");
    } else {

      let urlWithParam = urlDeleteExercise.replace(":cardId", data.card.id).replace(":blockId", blockId).replace(":exerciseCardId", id);

      console.log('Deleting exercise-card with id : ' + id);
      API().delete(urlWithParam)
        .then(res => {
          sendNotification(t('patients.detail.exercises.deleteOk'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.exercises.deleteFail'), "error");
      })
    }
  }

  onUpdateExercise(id, request){
    const {t, data, autoSave} = this.props;
    const {urlUpdateExercise} = this.state;

    let blockId = this.getBlockId();
    if (blockId == null){
      sendNotification(t('patients.detail.exercises.updateFail'), "error");
    } else {

      let urlWithParam = urlUpdateExercise.replace(":cardId", data.card.id).replace(":blockId", blockId).replace(":exerciseCardId", id);

      console.log('Updating exercise-card with id : ' + id);
      API().put(urlWithParam, request)
        .then(res => {
          if (!autoSave){
            sendNotification(t('patients.detail.exercises.updateOk'), "success");
          }
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.exercises.updateFail'), "error");
      })
    }
  }

  onAddArticle(){
    this.setState({isOpenSelectArticle : true})
  }

  onCloseAddArticle(){
    this.setState({isOpenSelectArticle : false})
  }

  onSelectAddArticle(articles){
    //console.log('Adding exercises : ' + JSON.stringify(exercises));
    const {t, data} = this.props;
    const {urlAddArticle} = this.state;

    this.setState({isOpenSelectArticle : false})

    let blockId = this.getBlockId();
    if (blockId == null){
      sendNotification(t('patients.detail.articles.addFail'), "error");
    } else {

      let urlWithParam = urlAddArticle.replace(":cardId", data.card.id).replace(":blockId", blockId);

      API().put(urlWithParam, { articles: articles })
        .then(res => {
          sendNotification(t('patients.detail.articles.addOk'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.articles.addFail'), "error");
      })
    }
  }

  onDeleteArticle(id){
    const {t, data} = this.props;
    const {urlDeleteArticle} = this.state;

    let blockId = this.getBlockId();
    if (blockId == null){
      console.log('block id is null');
      sendNotification(t('patients.detail.articles.deleteFail'), "error");
    } else {

      let urlWithParam = urlDeleteArticle.replace(":cardId", data.card.id).replace(":blockId", blockId).replace(":articleCardId", id);

      console.log('Deleting article-card with id : ' + id);
      API().delete(urlWithParam)
        .then(res => {
          sendNotification(t('patients.detail.articles.deleteOk'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.articles.deleteFail'), "error");
      })
    }
  }

  onUpdateArticle(id, request){
    const {t, data} = this.props;
    const { urlUpdateArticle} = this.state;

    let blockId = this.getBlockId();
    if (blockId == null){
      sendNotification(t('patients.detail.articles.updateFail'), "error");
    } else {

      let urlWithParam = urlUpdateArticle.replace(":cardId", data.card.id).replace(":blockId", blockId).replace(":articleCardId", id);

      console.log('Updating article-card with id : ' + id);
      API().put(urlWithParam, request)
        .then(res => {
          sendNotification(t('patients.detail.articles.updateOk'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.articles.updateFail'), "error");
      })
    }
  }

  getBlockId(){
    const {data, isLoading, autoSave} = this.props;
    const {error, activeTabId} = this.state;
    return (!isLoading && !error && data.card.cardBlocks[activeTabId]) ? data.card.cardBlocks[activeTabId].id : null;
  }

  onUpdateGeneral(blockId, content){
    const {t,data, autoSave} = this.props;
    const {urlUpdateGeneral} = this.state;

    let id = data.card.id;
    if (blockId == null){
      sendNotification(t('patients.detail.general.updateFail'), "error");
    } else {

    let urlWithParam = urlUpdateGeneral.replace(":cardId", id).replace(":blockId", blockId);

      console.log('Updating general-card with id : '+id+' and block id : '+blockId);
      API().put(urlWithParam, { description : content })
        .then(res => {
          if (!autoSave){
            sendNotification(t('patients.detail.general.updateOk'), "success");
          }
          this.loadData();
        }).catch(e => {
          console.log('On get exception '+JSON.stringify(e));
          sendNotification(t('patients.detail.general.updateFail'), "error");
      })
    }
  }

  openDeletePublicBlockDialog(){
    this.setState({isOpenDeletePublicBlock : true});
  }

  onCloseDeleteDialog(){
    this.setState({isOpenDeletePublicBlock : false});
  }

  onDeletePublicBlock() {
    const {t, data} = this.props;
    const {urlDeleteBlock} = this.state;

    this.setState({isOpenDeletePublicBlock : false, activeTabId: 0})

    let blockId = this.getBlockId();
    if (blockId == null){
      console.log('block id is null');
      sendNotification(t('patients.detail.block.delete.fail'), "error");
    } else {

      let urlWithParam = urlDeleteBlock.replace(":cardId", data.card.id).replace(":blockId", blockId);

      API().delete(urlWithParam)
        .then(res => {
          sendNotification(t('patients.detail.block.delete.ok'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.block.delete.fail'), "error");
      })
    }
  }

  setActiveTabId(tabId){
    this.setState({activeTabId : tabId, openUpdateSection : null});
  }

  onChangeOrderTabs(item, idx1, idx2) {
    const {urlUpdateOrder, activeTabId} = this.state;
    const {t, data} = this.props;

    console.log('Dropped Change onChangeOrderTabs : ' + JSON.stringify(item) + " idx1 : " + idx1 + " idx2 : " + idx2);

    if (idx1 == activeTabId){
      this.setState({activeTabId : idx2})
    }

    let id = data.card.id;
    let urlWithParam = urlUpdateOrder.replace(":cardId", id);
    API().put(urlWithParam, { item1 : idx1, item2 : idx2 })
      .then(res => {
        this.loadData();
      }).catch(e => {
      console.log('On get exception '+JSON.stringify(e));
      sendNotification(t('errors.save'), "error");
    })
  }

  onChangeOrderExercises(item, idx1, idx2) {
    const {urlExcerciseUpdateOrder} = this.state;
    const {t, data} = this.props;

    console.log('Dropped Change onChangeOrderExercises : ' + JSON.stringify(item) + " idx1 : " + idx1 + " idx2 : " + idx2);

    let id = data.card.id;
    let blockId = this.getBlockId();

    let urlWithParam = urlExcerciseUpdateOrder.replace(":cardId", id).replace(":blockId", blockId);
    API().put(urlWithParam, { item1 : idx1, item2 : idx2 })
      .then(res => {
        this.loadData();
      }).catch(e => {
      //console.log('On get exception '+JSON.stringify(e));
      //sendNotification(t('errors.save'), "error");
    })
  }

  getPatientCard(){
    const {data} = this.props;

    if (data.card){
      let getUrl = window.location;
      let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
      return baseUrl + "#/p/"+data.id+"/c/"+data.card.id;
    }

    return "";
  }

  onButtonPublicCardClick(){
    let url = this.getPatientCard();
    let win = window.open(url, '_blank');
    win.focus();
  }


  render() {
    const { t, classes, data, isLoading, autoSave } = this.props;
    const {error, entity, isOpenSelectExercise, isOpenSelectArticle, isOpenAddSection, debug, activeTabId, isOpenDeletePublicBlock, openUpdateSection} = this.state;

    let name = ""
    if (!isLoading && !error) {
      name = data.fullName;
    }

    let notIdsExercises = []
    let notIdsArticles = []


    let block = !isLoading && !error ? data.card.cardBlocks[activeTabId] : null;


    if (block && block.exercises != null){
      for (let i = 0 ; i < block.exercises.length; i++){
        notIdsExercises.push(block.exercises[i].exercise.id);
      }
    }

    if (block && block.articles != null){
      for (let i = 0 ; i < block.articles.length; i++){
        notIdsArticles.push(block.articles[i].article.id);
      }
    }

    const header = (
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h5" color="textSecondary">
            {t(entity+'.detail.publicInfoTitle')}
          </Typography>
        </Grid>
        <Grid item xs={6} style={{textAlign: "right"}}>
          <div style={{display:'flex', flexDirection:"row", alignItems:"center"}}>
            <div style={{flex:1}}>
            </div>
            <div>
              <Typography color="text" colorBrightness="secondary">
                {t(entity+'.detail.dashboard.visits.title')} : {data.card.views}
              </Typography>
            </div>
            <div style={{marginLeft:"10px", marginRight:"10px"}}>
              <Typography color="text" colorBrightness="secondary">
                {t(entity+'.detail.dashboard.visits.last')} : {(data.card.lastViewAt) ? convert(data.card.lastViewAt) : "--"}
              </Typography>
            </div>

            <CopyToClipboard
              text={this.getPatientCard()}
              onCopy={() => sendNotification(t(entity+".detail.copied"),"success")}
            >
              <Button
                variant="outlined"
                size="large"
                color="primary"
              >
                <Icons.Share />
              </Button>
            </CopyToClipboard>

            <Button
              classes={{ root: classes.button }}
              variant="contained"
              size="large"
              color="primary"
              onClick={this.onButtonPublicCardClick}
              style={{marginLeft: "10px", marginRight: "10px"}}
            >
              {t(entity+'.detail.button')}
            </Button>
          </div>

        </Grid>
      </Grid>
    )


    return (
      <>

            { error ? <ErrorContainer msg={t('errors.loadData')} /> : (
              <>
                {isLoading ? (
                  <CircularProgress size={26}/>
                ) : (
                  <>
                    <Grid item xs={12}>

                      <Widget
                        title={t(entity+'.detail.publicInfoTitle')}
                        subTitle={t(entity+'.detail.publicInfoSubTitle')}
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu = {true}
                        leftBorder = "green"
                        preTitle={(<Icons.Check style={{color : "green"}}/>)}
                        header={header}
                      >

                        <DraggableTabPanel id="public" blocks={data.card.cardBlocks} onChange={this.setActiveTabId} activeTabId={activeTabId} onChangeActiveTab={this.setActiveTabId} onChangeOrder={this.onChangeOrderTabs} onAdd={this.onAddSection} addTooltip={t(entity+".detail.publicInfoAddSubTitle")}/>

                        <Grid container spacing={4}>
                          <Grid item xs={12}>

                            <Grid container spacing={4}>
                              <Grid item xs={12}>
                                {(block && block.canEdit) ? (
                                  <Tooltip title={t(entity+".detail.publicInfoRemoveSubTitle")}>
                                    <IconButton className={classes.iconButton} onClick={() => {this.setState({openUpdateSection : 1})}}>
                                      <Icons.Delete className={classes.editIcon} />
                                    </IconButton>
                                  </Tooltip>
                                ):(<></>)}
                              </Grid>
                            </Grid>

                            {(!block || openUpdateSection === 0) ? (
                              <Grid container spacing={4}>
                                <Grid item xs={12}>
                                  <Widget
                                    subTitle={t(entity+".detail.publicInfoAddSubTitle")}
                                    upperTitle
                                    bodyClass={classes.fullHeightBody}
                                    className={classes.card}
                                    disableWidgetMenu = {true}
                                    button={t(entity+".detail.publicInfoAddButton")}
                                    onButtonClick={this.onAddSection}
                                  >
                                  </Widget>
                                </Grid>
                              </Grid>
                            ) : (<></>)}



                            {
                              (block && openUpdateSection === 1) ? (
                                <>
                                  <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                      <Widget
                                        subTitle={t(entity+".detail.publicInfoRemoveSubTitle")}
                                        upperTitle
                                        bodyClass={classes.fullHeightBody}
                                        className={classes.card}
                                        disableWidgetMenu = {true}
                                        button={t(entity+".detail.publicInfoRemoveButton") + " : '" + block.title+"'"}
                                        onButtonClick={this.openDeletePublicBlockDialog}
                                        buttonColor="secondary"
                                      >
                                      </Widget>
                                      <Dialog
                                        open={isOpenDeletePublicBlock}
                                        onClose={this.onCloseDeleteDialog}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                      >
                                        <DialogTitle id="alert-dialog-title">{t(entity+'.detail.block.delete.title')}</DialogTitle>
                                        <DialogContent>
                                          <DialogContentText id="alert-dialog-description">
                                            {t(entity+'.detail.block.delete.description')}
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                          <Button onClick={this.onCloseDeleteDialog}>
                                            {t('buttons.cancel')}
                                          </Button>
                                          <Button onClick={this.onDeletePublicBlock} color="primary" autoFocus>
                                            {t('buttons.delete')}
                                          </Button>
                                        </DialogActions>
                                      </Dialog>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (<></>)
                            }

                            {(openUpdateSection != null) ? (
                              <Grid container spacing={4}>
                                <Grid item xs={12} style={{textAlign: "right"}}>
                                  <Button variant="outlined" color="primary" size="small" onClick={() => {this.setState({openUpdateSection : null})}}>
                                    {t('buttons.cancel')}
                                  </Button>
                                </Grid>
                              </Grid>
                            ) : (<></>)}

                          </Grid>
                        </Grid>



                        {(block) ? (
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Widget
                                title={t(entity+'.detail.general.title')}
                                upperTitle
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu = {true}
                              >
                                <Grid container spacing={4}>
                                  {block.canEdit ? (
                                    <GeneralEditor
                                      key={"block-section-"+block.id}
                                      data={block.general ? block.general : ""}
                                      onSave={(content) => {this.onUpdateGeneral(block.id, content)}}
                                      placeholder={t('placeholders.addSuggestion')}
                                      autoSave={autoSave}
                                      activeOverlay={true}
                                      overlayedTitle={(<Typography size="md"><b>{name}</b> ({block.title})</Typography>)}
                                    />
                                  ) : (
                                    <div style={{margin: "20px"}} dangerouslySetInnerHTML={{ __html: block.general }} className={classes.publicBlockGeneral}/>
                                  )}

                                </Grid>
                              </Widget>
                            </Grid>

                            <Grid item xs={12}>
                              <Widget
                                title={t(entity+'.detail.exercises.title')}
                                upperTitle
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu = {true}
                                button={block.canEdit ? t(entity+'.detail.exercises.add') : null} onButtonClick={this.onAddExercise}
                              >
                                <ExerciseDraggableContainer exercises={block.exercises}
                                                            onDelete={this.onDeleteExercise}
                                                            onSave={this.onUpdateExercise}
                                                            autoSave={autoSave}
                                                            canEdit={block.canEdit}
                                                            itemType={"block-"+block.id+"-excercises"}
                                                            onChangeOrder={(item, idx1, idx2) => this.onChangeOrderExercises(item, idx1, idx2)}
                                />


                              </Widget>
                            </Grid>

                            <Grid item xs={12}>
                              <Widget
                                title={t(entity+'.detail.articles.title')}
                                upperTitle
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu = {true}
                                button={block.canEdit ? t(entity+'.detail.articles.add') : null}
                                onButtonClick={this.onAddArticle}
                              >
                                <Grid container spacing={4}>
                                  {block.articles.map(item => {
                                  return (
                                  <Article data={item} key={item.id} onDelete={this.onDeleteArticle} onSave={this.onUpdateArticle} autoSave={autoSave} canEdit={block.canEdit}/>
                                );
                              })}
                                </Grid>
                              </Widget>
                            </Grid>
                          </Grid>
                        ) : (
                          <div></div>
                        )}

                      </Widget>
                    </Grid>

                  </>
                )
                }
              </>
            ) }

        <ExerciseSelectionDialog open={isOpenSelectExercise} onClose={this.onCloseAddExercise} onSelect={this.onSelectAddExercise} notId={notIdsExercises}/>
        <ArticleSelectionDialog open={isOpenSelectArticle} onClose={this.onCloseAddArticle} onSelect={this.onSelectAddArticle} notId={notIdsArticles} />
        <CreatePublicSectionFormDialog open={isOpenAddSection} onClose={this.onCloseAddSection} onSubmit={this.onSelectAddSection} cardId={(data && data.card) ? data.card.id : null} />

        <ToastContainerCustom />

      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(PublicSection));

