import React from "react";
import Widget from "../../../../components/Widget";
import { Grid } from "@material-ui/core";
import DevDebugJson from "../../../../components/Dev/DevDebugJson";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "../../../../components/Wrappers";
import { Line, LineChart } from "recharts";
import useStyles from "../styles";
import { useTheme } from "@material-ui/styles";
import BigStat from "../BigStat/BigStat";
import DashboardUserActivityStatistics from "../DashboardUserActivityStatistics/DashboardUserActivityStatistics";

export default function DashboardStatsUsers({data, loading, error, debug = false, ...props}) {
  var classes = useStyles();
  var theme = useTheme();

  return (

    <>
      {loading && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}

      {data && data.map(stat => (
        <Grid item md={4} sm={6} xs={12} key={"user-stat-"+stat.user.id}>
          <DashboardUserActivityStatistics data={stat}/>
        </Grid>
      ))}

      {debug && (
        <div>
          <DevDebugJson data={data} title={"data"} component={"DashboardStatsUsers"} />
          <DevDebugJson data={loading} title={"loading"} component={"DashboardStatsUsers"} />
        </div>
      )}
    </>
  )
}

