function getId(url){
  let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  let match = url.match(regExp);
  return (match&&match[7].length===11)? match[7] : false;
}

export function getThumbnail(url){
  let id = getId(url);
  //console.log('id is : '+ id);
  return "https://img.youtube.com/vi/"+id+"/0.jpg";
}