import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";
import VpnKeyIcon from '@material-ui/icons/VpnKey';

// components
import {
  URL_TEMPLATES_CREATE,
  URL_TEMPLATES_DELETE,
  URL_TEMPLATES_GET,
  URL_TEMPLATES_GET_BY_ID,
  URL_TEMPLATES_UPDATE, URL_USER_CHANGE_PASSWORD,
  URL_USER_CREATE,
  URL_USER_DELETE,
  URL_USER_GET, URL_USER_GET_BY_ID,
  URL_USER_UPDATE,
} from "../../utils/UrlConstants";
import * as formUtils from "../../utils/FormUtils";
import EntityManager from "../../components/EntityManager/EntityManager";
import { getTemplateColumns } from "../../utils/TemplateUtils";
import { getUserColumns, hasRole, ROLE_ADMIN } from "../../utils/UserUtils";
import { UserStateContext } from "../../context/UserContext";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { VpnKey } from "@material-ui/icons";
import GeneralCreateUpdateFormDialog
  from "../../components/FormDialog/GeneralCreateUpdateFormDialog/GeneralCreateUpdateFormDialog";

const styles = useStyles;

class Templates extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      isOpenDialogChangePassword : false,
      changePasswordId : null,
      error: false,
    };

    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmitChangePassword = this.onSubmitChangePassword.bind(this)


  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  onChangePassword(itemId, item){
    console.log('on change password - item : ' + JSON.stringify(item) + " ID : " + itemId)
    this.setState({
      changePasswordId : itemId,
      isOpenDialogChangePassword : true
    })
  }

  onSubmitChangePassword(){
    this.setState({
      changePasswordId : null,
      isOpenDialogChangePassword : false
    })
  }

  render() {
    let {classes, t} = this.props;
    let {isOpenDialogChangePassword, changePasswordId} = this.state;

    let user = this.context.user;

    let configuration = {
      entity : 'users',
      apiGet : URL_USER_GET,
      apiGetById : URL_USER_GET_BY_ID,
      apiDelete : URL_USER_DELETE,
      apiCreate : URL_USER_CREATE,
      apiUpdate : URL_USER_UPDATE,
      columns : getUserColumns(classes, t),
      formValidation : {
        name: {
          type: formUtils.REQUIRED_INPUT
        },
        surname: {
          type: formUtils.REQUIRED_INPUT
        },
        email : {
          type : formUtils.EMAIL
        },
        username : {
          type : formUtils.REQUIRED_INPUT
        },
        password : {
          type : formUtils.REQUIRED_INPUT,
          min : 8
        },
        profilePicture : {
          type : formUtils.INPUT_TEXTAREA,
        }
      },
      formValidationUpdate : {
        name: {
          type: formUtils.REQUIRED_INPUT
        },
        surname: {
          type: formUtils.REQUIRED_INPUT
        },
        email : {
          type : formUtils.EMAIL
        },
        username : {
          type : formUtils.REQUIRED_INPUT
        },
        profilePicture : {
          type : formUtils.INPUT_TEXTAREA,
        }
      },
      formApiMapper : (res) => {
        return {
          name : res.data.name,
          surname : res.data.surname,
          email : res.data.email,
          username : res.data.username,
          profilePicture : res.data.profilePicture,
        }
      },
      deleteRow : hasRole([ROLE_ADMIN], user),
      buttonsSelect : (itemId, item) => {
        return (
          <Tooltip title={t('users.changePassword.title')}>
            <IconButton className={classes.iconButton} onClick={() => this.onChangePassword(itemId)} aria-label={t('users.changePassword.title')}>
              <VpnKeyIcon className={classes.editIcon} />
            </IconButton>
          </Tooltip>
      )},
      titleField : (form) => {return form['name'] + " " + form["surname"]}
    }

    let configurationChangePassword = {
      apiGet : URL_USER_GET_BY_ID.replace(":id", changePasswordId),
      apiUpdate : URL_USER_CHANGE_PASSWORD.replace(":id", changePasswordId),
      apiCreate : null,
      formValidation : {
        password : {
          type : formUtils.REQUIRED_INPUT,
          min : 8
        }
      },
      formValidationUpdate : null,
      formApiMapper : null,
      formRequestMapper : null,
      entity: 'users.changePassword',
      titleField: (form) => {return ""}
    }

    return (
      <>
        <EntityManager configuration={configuration}  />
        <GeneralCreateUpdateFormDialog configuration={configurationChangePassword}
                                       open={isOpenDialogChangePassword}
                                       onSubmit={this.onSubmitChangePassword}
                                       onClose={() => {this.setState({isOpenDialogChangePassword : false})}}
                                       id={changePasswordId}
                                       size="xl"/>
      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Templates));
Templates.contextType = UserStateContext;