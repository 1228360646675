const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  thumbnail : {
    width: 150
  }
});

export default styles;