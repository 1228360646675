import React from "react";
import {
  Button,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import { URL_ARTICLES_CREATE, URL_ARTICLES_DELETE, URL_ARTICLES_GET } from "../../utils/UrlConstants";
import { convert } from "../../utils/DateUtils";
import DataTable from "../../components/DataTable";
import CreateArticleFormDialog from "../../components/FormDialog/CreateArticleFormDialog/CreateArticleFormDialog";
import ToastContainerCustom, { sendNotification } from "../../components/ToastContainerCustom/ToastContainerCustom";
import BlogArticleSelectionDialog
  from "../../components/SelectionDialog/BlogArticleSelectionDialog/BlogArticleSelectionDialog";
import { API } from "../../services/AuthService";

import noImgPlaceholder from "../../images/no_image_ph.png";
import { UserStateContext } from "../../context/UserContext";
import { hasRole, ROLE_ADMIN } from "../../utils/UserUtils";

const styles = useStyles;

class Articles extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      error: false,
      isOpenDialogCreate : false,
      isOpenDialogUpdate : false,
      isOpenSelectArticle : false,
      updateId : null,
      isReloadData : false,
      entity : 'articles',
      api : URL_ARTICLES_GET,
      apiDelete : URL_ARTICLES_DELETE,
      columns : [
        {
          name: 'title',
        },
        {
          name : 'description'
        },
        {
          name: 'type',
          options : {
            filter: true,
            filterOptions: {
              names: ['wordpress', 'others']
            },
          }
        },
        {
          name: 'value',
          options : {
            customBodyRender: (value => {
              return (<a href={value} target="_blank" rel="noopener noreferrer" className="link">{value}</a>)
            })
          }
        },
        {
          name: 'coverUrl',
          label : 'Preview',
          options: {
            customBodyRender: (value => {
              return (<img src={value ? value : noImgPlaceholder} alt="cover image" className={classes.coverImageThumb} /> )
            })
          }
        },
        {
          name: 'createdAt',
          options: {
            sortDirection: 'none',
            customBodyRender: (value) => {
              return convert(value)
            }
          }
        }
      ]
    };

    // This binding is necessary to make `this` work in the callback
    this.onButtonTitleClick = this.onButtonTitleClick.bind(this);
    this.onSubmitCreate = this.onSubmitCreate.bind(this);
    this.onCloseCreate = this.onCloseCreate.bind(this);
    this.onReloadData = this.onReloadData.bind(this);
    this.onUpdateSelected = this.onUpdateSelected.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);
    this.onCloseEdit = this.onCloseEdit.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onCloseImport = this.onCloseImport.bind(this);
    this.onSelectImport = this.onSelectImport.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  onButtonTitleClick(){
    console.log('oonButtonTitleClick');
    this.setState({isOpenDialogCreate : true})
  }

  onSubmitCreate(){
    this.setState({isOpenDialogCreate : false, isReloadData : true})
  }

  onCloseCreate(){
    this.setState({isOpenDialogCreate : false})
  }

  onUpdateSelected(id, data){
    this.setState({isOpenDialogUpdate : true, updateId : id})
  }

  onSubmitEdit(){
    this.setState({isOpenDialogUpdate : false, isReloadData : true})
  }

  onCloseEdit(){
    this.setState({isOpenDialogUpdate : false})
  }

  onImport(){
    this.setState({isOpenSelectArticle : true})
  }

  onCloseImport(){
    this.setState({isOpenSelectArticle : false})
  }

  onSelectImport(data){
    const {t} = this.props;
    const {entity} = this.state;
    console.log("Selected " + JSON.stringify(data));

    if (data.length > 0) {
      let api = URL_ARTICLES_CREATE;
      let total = data.length;
      let count = 0;
      for (let i = 0; i < data.length; i++){
        let form = { title : data[i].title, description : data[i].description, type : 'wordpress', value : data[i].url, coverUrl : data[i].coverUrl};
        API().post(api, form)
          .then(res => {
            sendNotification(t(entity+'.create.success') + " : " + data[i].title, "success");
            count++;
            if (count === total){
              this.setState({isOpenSelectArticle : false, isReloadData : true})
            }
          }).catch(e => {
            sendNotification(t(entity+'.create.error')  + " : " + data[i].title, "error");
            count++;
            if (count === total){
              this.setState({isOpenSelectArticle : false, isReloadData : true})
            }
        })
      }


    }

  }

  onReloadData(){
    this.setState({
      isReloadData : false
    })
  }

  render() {
    const { t } = this.props;
    const { isOpenDialogCreate, isOpenDialogUpdate, isOpenSelectArticle, isReloadData, entity, api, columns, apiDelete, updateId} = this.state;
    let user = this.context.user;

    return (
      <>
        <PageTitle title={t(entity+'.title')} button={t(entity+'.button')} onButtonClick={this.onButtonTitleClick}>
          <Button
            onClick={this.onImport}
            variant="outlined"
            size="large"
            color="primary"
          >
            {t(entity+".import")}
          </Button>
        </PageTitle>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DataTable entity={entity}
                       api={api}
                       apiDelete={apiDelete} columns={columns} reload={isReloadData} onReload={this.onReloadData} onUpdate={this.onUpdateSelected}
                       deleteRow={hasRole([ROLE_ADMIN], user)}
            />
          </Grid>
        </Grid>
        <CreateArticleFormDialog open={isOpenDialogCreate} onSubmit={this.onSubmitCreate} onClose={this.onCloseCreate}/>
        <CreateArticleFormDialog open={isOpenDialogUpdate} onSubmit={this.onSubmitEdit} onClose={this.onCloseEdit} id={updateId} isCreate={false}/>
        <BlogArticleSelectionDialog open={isOpenSelectArticle} onClose={this.onCloseImport} onSelect={this.onSelectImport} />
        <ToastContainerCustom />
      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Articles));
Articles.contextType = UserStateContext;