import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

import logo from "../../images/logo.png";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { useTranslation } from "react-i18next";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  const {i18n} = useTranslation();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        //console.log("Enter key was pressed. Run your function.");
        // callMyFunction();
        if (loginValue.length === 0 || passwordValue.length === 0){
          // do nothing
          //console.log('missed fields for login - User : ' + loginValue);
        } else {
          console.log('Login...');
          loginUser(
            userDispatch,
            loginValue,
            passwordValue,
            props.history,
            setIsLoading,
            setError,
          )
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [loginValue, passwordValue]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img className={classes.logotypeImage} src={logo} alt="Reactiva logo" />
        <Typography className={classes.logotypeText}>
          Reactiva Sants
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
            <React.Fragment>
              <Typography variant="h1" className={classes.greeting}>
                {i18n.t('login.title')}
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {i18n.t('errors.login')}
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={e => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Adress"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    className={classes.loginButton}
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                  >
                    Login
                  </Button>
                )}
              </div>
            </React.Fragment>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © {new Date().getFullYear()} - Reactiva
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
