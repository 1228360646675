import React, {useState} from "react";
import {toast} from "react-toastify";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Button from "@material-ui/core/Button";
import { stopEvent } from "../../utils/UIUtils";
import {getDateTimezone} from "../../utils/UTCTimeUtils";

export default function DevDebugJson({
                                       data, title, component,
                                       onClick=null,
                                       debug=true, ...props
}){
  let [open, setOpen] = useState(false)

  if (!debug) return null;

  let titleToShow = title;
  let isJson = false;
  let processStack = false;

  //console.log('For title : ' + title + " type is : " +(typeof data)+" is boolean ? " + ('boolean' === (typeof data) ? "true" : "false"))


  if ((data && data.constructor && data.constructor.name) || 'boolean' === (typeof data)){
    let additionalTitle = data.constructor.name;

    if (data instanceof Date){
      additionalTitle = data.constructor.name + " offset TZ : "+getDateTimezone(data)
      isJson = true
    } else if (data instanceof Array) {
      additionalTitle = data.constructor.name + " size : " + data.length;
      isJson = true
    } else if ('boolean' === (typeof data)){
      additionalTitle = "boolean";
      isJson = true
    } else if (data instanceof Object){
      isJson = true
      if (data.hasOwnProperty("componentStack")){
        processStack = true;
      }
    }

    titleToShow = (
      <span>
          {title} <span style={{fontSize:"90%", color:"#999", marginLeft:"5px"}}>({additionalTitle})</span>
      </span>

    )
  }

  let content = "";
  try {
    content = (!open && isJson) ? JSON.stringify(data) : (!isJson ? data : JSON.stringify(data, null, '   '));
  } catch (e){
    console.error(e);
    console.error(data)
    content = "Error parsing JSON"
  }

  return (
    <div style={{
      padding: "10px",
      border: "1px solid #DDD",
      marginTop: "10px",
      marginBottom: "10px",
      backgroundColor: "#eee"
    }}>

      <div className={"flex-support-row"}
           onClick={(e) => {
             if (onClick) {

             } else {
               stopEvent(e)
               setOpen(!open)
             }
           }

           }
           style={{ cursor: "pointer" }}
      >
        <div style={{ fontWeight: "bold", flex: 1, overflow: "hidden", whiteSpace: "nowrap" }}>
          {titleToShow}
        </div>

        {component && (
          <div style={{ fontSize: "12px", fontWeight: 'bold', color: "#999" }}>{component}</div>
        )}

      </div>


      <div style={{ backgroundColor: "white", padding: "5px", border: "1px solid #DDD", marginTop: "3px" }}>
        {open ? (
          <div>
            <div style={{ marginBottom: "10px", textAlign: "right" }}>
              <CopyToClipboard
                text={JSON.stringify(data)}
                onCopy={() => toast.success("JSON Copied")}
              >
                <Button
                  size="sm"
                  color="secondary"
                >
                  <i className={"fa fa-clipboard"}/>
                </Button>
              </CopyToClipboard>
            </div>
            <div style={{ fontSize: "11px" }}>
              <pre>
                {JSON.stringify(data, null, '   ')}
              </pre>
            </div>
          </div>

        ) : (
          <div style={{ fontSize: "11px", maxHeight: "12px", overflow: "hidden" }}>{JSON.stringify(data)}</div>
        )}
      </div>


    </div>
  )
}