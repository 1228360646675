import React, { useState } from "react";
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography, Button,
} from "@material-ui/core";
import { Close as CloseIcon, MoreVert as MoreIcon } from "@material-ui/icons";
import classnames from "classnames";

// styles
import useStyles from "./styles";
import * as Icons from "@material-ui/icons";

export default function Widget({
  children,
  title,
  subTitle,
  noBodyPadding,
  bodyClass,
  disableWidgetMenu,
  header,
  leftBorder,
  preTitle,
  postTitle,
  buttonColor,
  buttonSize,
  titleAndSubtitleSameRow=true,
  ...props
}) {
  var classes = useStyles();

  // local
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  var style = leftBorder ? {border : "1px solid " + leftBorder} : {}
  var addonTitle = preTitle ? (<div style={{marginRight:"5px"}}>{preTitle}</div>) : (<></>)
  var addonTitlePost = postTitle ? postTitle : (<></>)
  var color = buttonColor ? buttonColor : "primary";
  var buttonSize = buttonSize ? buttonSize : 'large';

  let additionalStyle = {}
  if (!titleAndSubtitleSameRow){
    additionalStyle = {flexDirection:"column", alignItems:"flex-start"}
  }

  return (
    <div className={classes.widgetWrapper}>
      <Paper className={classes.paper} classes={{ root: classes.widgetRoot }} style={style}>
        <div className={classes.widgetHeader}
             style={{...additionalStyle}}
        >
          {header ? (
            header
          ) : (
            <React.Fragment>
              {title && (
                <>
                  <div style={{display:"flex", flexDirection:"row",alignItems:"center"}}>
                    <div style={{flex:1}}>
                      <Typography variant="h5" color="textSecondary" >
                        {addonTitle} {title} {addonTitlePost}
                      </Typography>
                    </div>
                    {props.customButton && (
                      <div>
                        {props.customButton}
                      </div>
                    )}
                  </div>
                </>
              )}

              {subTitle && (
                <div>
                  <Typography variant="h6" color="textSecondary">
                    {subTitle}
                  </Typography>
                </div>
              )}

              {!disableWidgetMenu && (
                <IconButton
                  color="primary"
                  classes={{ root: classes.moreButton }}
                  aria-owns="widget-menu"
                  aria-haspopup="true"
                  onClick={() => setMoreMenuOpen(true)}
                  buttonRef={setMoreButtonRef}
                >
                  <MoreIcon />
                </IconButton>
              )}
              {props.button && (
                <Button
                  classes={{ root: classes.button }}
                  variant="contained"
                  size={buttonSize}
                  color={color}
                  onClick={props.onButtonClick}
                >
                  {props.button}
                </Button>
              )}
              {props.deleteButton && (
                <CloseIcon onClick={props.onDeleteButtonClick} />
              )}

            </React.Fragment>
          )}
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id="widget-menu"
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography>Edit</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Copy</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Delete</Typography>
        </MenuItem>
        <MenuItem>
          <Typography>Print</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}
