import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { API } from "../../../services/AuthService";

import * as formUtils from '../../../utils/FormUtils';
import { URL_EXERCISES_CREATE } from "../../../utils/UrlConstants";
import FeedbackFormDialog from "../FeedbackFormDialog/FeedbackFormDialog";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { sendNotification } from "../../ToastContainerCustom/ToastContainerCustom";

const styles = useStyles;

class CreateExerciseFormDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entity : "exercises",
      api : URL_EXERCISES_CREATE,
      form : {
        title : "",
        type : "Youtube",
        value : "",
      },
      formValidation : {
        title: {
          type: formUtils.REQUIRED_INPUT
        },
        type: {
          type: formUtils.REQUIRED_INPUT
        },
        value : {
          type : formUtils.REQUIRED_URL
        }
      },
      formError : {
        name : false,
        email : false
      },

      enableButton : true,
      loadingButton : false
    };
    this.baseState = this.state;
    // This binding is necessary to make `this` work in the callback
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.open === true && this.props.open === false) {
      //console.log('reopening');
      this.setState(this.baseState);
    }
  }

  onClickSubmit() {
    console.log('Onclick submit');
    const { form, formError, api, entity } = this.state;
    const { onSubmit, t } = this.props;

    if (this.checkAll()) {
      this.setState({enableButton : false, loadingButton : true});
      console.log('post api '+JSON.stringify(form));
      API().post(api, form)
        .then(res => {
          this.setState({loadingButton : false, enableButton : true});
          sendNotification(t(entity+'.create.success'), "success");

          if (onSubmit) onSubmit();
        }).catch(e => {
          console.log('error api '+e);
          this.setState({loadingButton : false, enableButton : true});
          sendNotification(t(entity+'.create.error'), "error");
      })
    } else {
      let errors = formUtils.getFormValidation(this.state.formValidation, this.state.form);
      console.log('errors '+JSON.stringify(errors));
      this.setState({...formError, formError : errors});
    }
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
  }


  checkAll(){
    // console.log('check all');
    return formUtils.checkForm(this.state.formValidation, this.state.form);
  }

  handleChange(event){
    formUtils.handleInputChange(event, this);
  }

  handleChangeSelect(event){
    formUtils.handleInputChange(event, this, "type");
  }

  render() {
    const { classes, t, open, onClose } = this.props;
    const { enableButton, loadingButton, formError, form, entity } = this.state;

    return (
      <>
        <Grid container spacing={4}>
          <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <DialogTitle id="form-dialog-title">{t(entity+".create.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(entity+".create.description")}
              </DialogContentText>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  autoFocus
                  required
                  error={formError.title}
                  margin="dense"
                  id="title"
                  label={t(entity+(".create.form.title.label"))}
                  helperText={formError.title ? t(entity+(".create.form.title.error")) : ""}
                  onChange={this.handleChange}
                  value={form.title}
                  fullWidth
                />
                <FormControl
                  fullWidth>
                  <InputLabel id="type-label">{t(entity+(".create.form.type.label"))}</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={form.type}
                    onChange={this.handleChangeSelect}
                  >
                    <MenuItem value="Youtube">Youtube</MenuItem>
                    <MenuItem value="Others">Others</MenuItem>
                  </Select>
                  <FormHelperText>{formError.type ? t(entity+(".create.form.type.error")) : ""}</FormHelperText>
                </FormControl>
                <TextField
                  required
                  error={formError.value}
                  margin="dense"
                  id="value"
                  label={t(entity+(".create.form.value.label"))}
                  helperText={formError.value ? t(entity+(".create.form.value.error")) : ""}
                  onChange={this.handleChange}
                  value={form.value}
                  fullWidth
                />
              </form>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose}>
                {t(entity+(".create.form.buttons.cancel"))}
              </Button>
              <Button onClick={this.onClickSubmit} color="primary" disabled={!enableButton}>
                {loadingButton ? (
                  <CircularProgress size={26}  />
                ) : (
                  <div>{t(entity+(".create.form.buttons.ok"))}</div>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(CreateExerciseFormDialog));
