import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { API } from "../../../services/AuthService";

import * as formUtils from '../../../utils/FormUtils';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { sendNotification } from "../../ToastContainerCustom/ToastContainerCustom";
import GeneralEditor from "../../GeneralEditor/GeneralEditor";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Checkbox from "@material-ui/core/Checkbox";
import DevDebugJson from "../../Dev/DevDebugJson";

const styles = useStyles;

class GeneralCreateUpdateFormDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      controls : this.getControls(),
      form : this.getForm(),
      formError : this.getFormError(),
      enableButton : true,
      loadingButton : false,
      enableButtonCover : true,
      loadingButtonCover : false,
      debug : false
    };
    this.baseState = this.state;
    // This binding is necessary to make `this` work in the callback
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    let {id, open} = this.props;
    let isCreate = this.isCreate();

    //console.log('reopening and reloading data with id : ' +id+ " open : " + open + " prevProps.open : " + prevProps.open + " isCreate : "+ isCreate);
    if (open === true && prevProps.open === false) {
      console.log('reopening and reloading data with id : ' +id);
      this.setState(this.baseState);
      if (!isCreate) this.refreshData(id);
    }
  }

  refreshData(id){
    const {configuration} = this.props;
    const {apiGet, formApiMapper} = configuration;

    console.log('refreshing data');

    if (id != null) {
      this.setState({isLoading : true})

      API().get(apiGet)
        .then(res => {
          console.log('response ' + JSON.stringify(res.data))

          this.setState(
            {
              form : formApiMapper(res),
              isLoading : false
            }
          )

        }).catch(e => {
        console.log('On get exception '+JSON.stringify(e));
        this.setState({ error : true });
      })
    }
  }

  onClickSubmit() {
    console.log('Onclick submit');
    const { form, formError, api } = this.state;
    const { onSubmit, t, id, configuration } = this.props;
    const {apiCreate, apiUpdate, entity, formRequestMapper} = configuration;

    let formValidationToUse = this.getFormValidation();

    if (this.checkAll()) {
      this.setState({enableButton : false, loadingButton : true});

      let submitForm = form;
      if (formRequestMapper){
        console.log('manage formRequestMapper');
        submitForm = formRequestMapper(form);
      }
      console.log('post api '+JSON.stringify(submitForm));

      if (id) {
        API().put(apiUpdate, submitForm)
          .then(res => {
            this.setState({loadingButton : false, enableButton : true});
            sendNotification(t(entity+'.update.success'), "success");
            if (onSubmit) onSubmit(res);
          }).catch(e => {
          console.log('error api '+e);
          this.setState({loadingButton : false, enableButton : true});
          sendNotification(t(entity+'.update.error'), "error");
        })
      } else {
        API().post(apiCreate, submitForm)
          .then(res => {
            this.setState({ loadingButton: false, enableButton: true });
            sendNotification(t(entity + '.create.success'), "success");
            if (onSubmit) onSubmit(res);
          }).catch(e => {
          console.log('error api ' + e);
          this.setState({ loadingButton: false, enableButton: true });
          sendNotification(t(entity + '.create.error'), "error");
        })
      }


    } else {
      let errors = formUtils.getFormValidation(formValidationToUse, this.state.form);
      console.log('Submit errors '+JSON.stringify(errors));
      this.setState({...formError, formError : errors});
    }
  }

  onClose() {
    const { onClose } = this.props;
    console.log('On Close CreateUpdateFormDialog')
    onClose();
  }

  checkAll(){
    let formValidationToUse = this.getFormValidation();
    // console.log('check all');
    return formUtils.checkForm(formValidationToUse, this.state.form);
  }

  handleChange(event){
    formUtils.handleInputChange(event, this);
  }

  handleChangeSelect(event, key){
    formUtils.handleInputChange(event, this, key);
  }

  handleChangeCheckbox(event, key){
    formUtils.handleCheckboxChange(event, this, key);
  }

  handleChangeOption(event, key){
    formUtils.handleInputChange(event, this, key);
  }

  handleChangeEditor(data, key, controlChange){
    let formValidationToUse = this.getFormValidation();

    //console.log('Received in Update Form ' + data)
    formUtils.handleValueChange(data, key, this, formValidationToUse)
    this.setState({controls : { ...this.state.controls, [key] : false }})
  }

  getForm(){

    let formValidationToUse = this.getFormValidation();
    console.log('Getting FORM ' + JSON.stringify(formValidationToUse))

    let keys = Object.keys(formValidationToUse);
    let form = {};
    for(let i=0; i<keys.length; i++){
      let key = keys[i];
      let entry = formValidationToUse[key];
      console.log('entry : ' + JSON.stringify(entry))
      let defaultValue = "";
      if (entry.defaultValue){
        defaultValue = entry.defaultValue;
      }
      form[key] = defaultValue;
      if (entry.editor){

      }
    }
    return form;
  }

  getControls(){

    let formValidationToUse = this.getFormValidation();

    let keys = Object.keys(formValidationToUse);
    let controls = {};
    for(var i=0; i<keys.length; i++){
      let key = keys[i];
      let entry = formValidationToUse[key];
      if (entry.editor){
        controls[key] = true;
      }
    }
    return controls;
  }

  getFormError(){

    let formValidationToUse = this.getFormValidation();

    let keys = Object.keys(formValidationToUse);
    let form = {};
    for(var i=0; i<keys.length; i++){
      let key = keys[i];
      form[key] = false;
    }
    return form;
  }

  getFormValidation(){
    const {configuration } = this.props;
    const {formValidation, formValidationUpdate} = configuration;

    if (this.isCreate()){
      return formValidation;
    } else {
      return formValidationUpdate ? formValidationUpdate : formValidation;
    }
  }

  renderItems(){
    const { t, configuration } = this.props;
    const { formError, form, debug} = this.state;
    const {entity} = configuration;


    let formValidationToUse = this.getFormValidation();

    if (debug) console.log('render items - isCreate : ' + (this.isCreate() ? 'true' : 'false') + " form validation to use : " + JSON.stringify(formValidationToUse))

    let retElements = [];
    let keys = Object.keys(formValidationToUse);
    for(var i=0; i<keys.length; i++){
      let key = keys[i];
      if (debug) console.log('Form key : ' + key)
      let entry = formValidationToUse[key];
      if (entry.render){
        retElements.push (entry.render(form[key]))
      } else {
        let autoFocus = false;
        if (entry.autoFocus){
          autoFocus = entry.autoFocus;
        }

        let required = true;
        if (entry.hasOwnProperty('required')){
          required = entry.required;
        }

        if (entry.type === formUtils.CHECKBOX){
          retElements.push(
            <FormControlLabel
              control={
                <Checkbox
                  id={key}
                  checked={form[key]}
                  onChange={(event)=>{
                    this.handleChangeCheckbox(event, key)
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  required={required}
                />
              }
              label={t(entity + (".create.form." + key + ".label"))}
            />
          )
        } else if (entry.select) {
          retElements.push(
            <FormControl fullWidth>
              <InputLabel id={key + "-label"}>{t(entity + (".create.form." + key + ".label"))}</InputLabel>
              <Select
                labelId={key + "-label"}
                id={key}
                value={form[key]}
                onChange={(event) => {
                  this.handleChangeSelect(event, key)
                }}
              >
                {
                  entry.select.map((item) => {
                    return <MenuItem value={item.id}>{item.label}</MenuItem>
                  })
                }
              </Select>
              <FormHelperText>{formError[key] ? t(entity + (".create.form." + key + ".error")) : ""}</FormHelperText>
            </FormControl>
          )
        } else if (entry.options) {
          if (entry.type === formUtils.REQUIRED_OPTION) {
            required = true;
          } else {
            required = false;
          }

          let inline = true;
          let radioStyle = {}
          if (inline){
            radioStyle = {display: 'flex', flexDirection: 'row', gap: '10px'}
          }

          retElements.push(
            <FormControl fullWidth>
              <FormLabel error={formError[key]} component="legend">{t(entity + (".create.form." + key + ".label"))} {required ? "*" : ""}</FormLabel>
              <RadioGroup error={formError[key]} aria-label={key} name={key} value={form[key]}
                          onChange={(event) => {
                            this.handleChangeOption(event, key)
                          }}
                          style={{ ...radioStyle}}
              >
                {
                  entry.options.map((item) => {
                    return <FormControlLabel  value={item.id} control={<Radio/>} label={item.label}/>
                  })
                }
              </RadioGroup>
              <FormHelperText error={formError[key]}>{formError[key] ? t(entity + (".create.form." + key + ".error")) : ""}</FormHelperText>
            </FormControl>
          )
        } else if (entry.textarea){
          retElements.push(
            <FormControl fullWidth>
              <FormLabel component="legend">{t(entity + (".create.form." + key + ".label"))} {required ? "*" : ""}</FormLabel>
              <br/>
              <TextareaAutosize style={{padding: "10px"}}
                                aria-label="minimum height"
                                id={key}
                                name={key}
                                rowsMin={3}
                                onChange={this.handleChange}
                                value={form[key]}
                                placeholder={t('placeholders.addText')}
              />
            </FormControl>
          )
        } else if (entry.editor) {
          retElements.push(
            <FormControl fullWidth>
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">{t(entity+(".create.form."+key+".label"))}</label>
              <GeneralEditor key={"private-block-section-"+key}
                             data={form[key]}
                             placeholder={t('placeholders.addText')}
                             onChange={(data, ctrl) => {this.handleChangeEditor(data, key, ctrl)}}
                             update={true}
                             controlUpdate={this.state.controls[key]}
              />
              <FormHelperText>{formError[key] ? t(entity+(".create.form."+key+".error")) : ""}</FormHelperText>
            </FormControl>
          )
        } else {
          if (debug) console.log('Field (else) : ' + key)
          retElements.push(
            <FormControl fullWidth>
              <TextField
                labelId={key+"-label"}
                autoFocus={autoFocus}
                required={required}
                error={formError[key]}
                margin="dense"
                id={key}
                label={t(entity+(".create.form."+key+".label"))}
                helperText={formError[key] ? t(entity+(".create.form."+key+".error")) : ""}
                onChange={this.handleChange}
                value={form[key]}
                fullWidth
              />
            </FormControl>
            )
        }
      }
    }
    return retElements;
  }

  isCreate(){
    const {id} = this.props;
    return (!id);
  }

  render() {
    const { classes, t, open, onClose, id, configuration, size, debug} = this.props;
    const { enableButton, loadingButton, form } = this.state;
    const {entity, titleField} = configuration;

    let isCreate = this.isCreate();

    //console.log(JSON.stringify(form));
    //console.log("title is " + form[titleField])

    return (
      <>
        <Grid container spacing={4}>
          <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} maxWidth={size}>
            <DialogTitle id="form-dialog-title">
              {isCreate ? t(entity+".create.title") : t(entity+".update.title").replace(":title", titleField ? titleField(form) : "")}
            </DialogTitle>
            <DialogContent>

              {debug && (
                <DevDebugJson data={form} title={"form"} />
              )}

              <DialogContentText>
                {t(entity+".create.description")}
              </DialogContentText>

              <form className={classes.root} noValidate autoComplete="off">

                {
                  this.renderItems()
                }

              </form>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose}>
                {t(entity+(".create.form.buttons.cancel"))}
              </Button>
              <Button onClick={()=>{this.onClickSubmit()}} color="primary" disabled={!enableButton}>
                {loadingButton ? (
                  <CircularProgress size={26}  />
                ) : (
                  <div>{isCreate ? t(entity+(".create.form.buttons.ok")) : t("buttons.save")}</div>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    );
  }
}

GeneralCreateUpdateFormDialog.defaultProps = {
  configuration : {
    entity : 'GeneralCreateUpdateFormDialog'
  },
  size : "sm",
  titleField : "title"
}

export default withTranslation()(withStyles(styles, { withTheme: true})(GeneralCreateUpdateFormDialog));
