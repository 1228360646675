import React from "react";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function ErrorContainer(props) {
  var classes = useStyles();

  return (
    <div className={classes.errorContainer}>
      <Typography className={classes.typo} variant="h4" size="sm">
        {props.msg}
      </Typography>
    </div>
  );
}
