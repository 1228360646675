import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import ExerciseDraggable from "./ExerciseDraggable";
import update from "immutability-helper";

export default function ExerciseDraggableContainer({exercises, itemType, onDelete=()=>{}, onSave=()=>{}, autoSave, canEdit, onChangeOrder=()=>{}, ...props}){
  let [items, setItems] = useState(exercises);
  let [compressed, setCompressed] = useState(false);

  useEffect(() => {
    setItems(exercises)
  }, [exercises])

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      console.log('move item drag : ' + dragIndex + " to : " + hoverIndex);
      const dragCard = items[dragIndex]
      setItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
      //console.log('active tab : ' + activeTabId + " dragIndex " + dragIndex + " hoverIndex " + hoverIndex)
      //if (dragIndex === activeTabId){
      //  onChangeActiveTab(hoverIndex)
      //}
    },
    [items],
  )

  const onDrop = useCallback(
    (item, hoverIndex) => {

      if (item.orderValue !== hoverIndex) {
        console.log('On change Order ' + JSON.stringify(item) + " hoverIndex : " + hoverIndex)
        onChangeOrder(item, item.orderValue, hoverIndex);
      }
    },
    [items],
  )

  const onDrag = useCallback(
    (isDragging) => {
      console.log('Change container status dragging to : ' + isDragging)
      setCompressed(isDragging)
    },
    [items],
  )

  return (
    <Grid container spacing={4}>
        {
          items.map((item, indexItem) => {
            return (
                <ExerciseDraggable key={item.id}
                                   item={item}
                                   onDelete={()=>{onDelete(item.id)}}
                                   onSave={(item, data)=>{onSave(item.id, data)}}
                                   autoSave={autoSave}
                                   canEdit={canEdit}
                                   moveItem={moveItem}
                                   onDrop={onDrop}
                                   onDrag={onDrag}
                                   itemType={itemType}
                                   index={indexItem}
                                   compressed={compressed}
                />

            );
          })
        }
    </Grid>
  )
}