const styles = theme => ({
  root: {
  },
  closeButton : {
    cursor : "pointer"
  },
  cardButtonBlock : {
    textAlign : "right",
    paddingTop: 5
  },
  cardExercise : {
    [theme.breakpoints.down('sm')]: {
      padding: "20px 0px !important",
    },
  }
});

export default styles;