import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import ErrorContainer from "../../../../components/ErrorContainer";
import { Close as CloseIcon, History } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import GeneralEditor from "../../../../components/GeneralEditor/GeneralEditor";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ArticleSuggestionsSelectionDialog
  from "../../../../components/SelectionDialog/ArticleSuggestionsSelectionDialog/ArticleSuggestionsSelectionDialog";
import noImgPlaceholder from "../../../../images/no_image_ph.png";

const styles = useStyles;

class Article extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entity : "articles",
      dimensions: { width: 0, height: 0 },
      error : false,
      description : props.data.description,
      originalDescription : props.data.description,
      isOpenSelectSuggestion : false,
      injectValue : false
    };

    this.onDelete = this.onDelete.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onSubmitChange = this.onSubmitChange.bind(this);
    this.onSuggestionsOpen = this.onSuggestionsOpen.bind(this);
    this.onSuggestionsClose = this.onSuggestionsClose.bind(this);
    this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
  }

  componentDidMount() {
    let height = (this.container.offsetWidth - 32) / 16 * 9;

    this.setState({
      dimensions: {
        width: this.container.offsetWidth - 32,
        height: height,
      },
    });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    //console.log('Error detected componentDidCatch');
  }

  onDelete(){
    //console.log('on Delete');
    const {data} = this.props;
    if (this.props.onDelete){
      this.props.onDelete(data.id);
    }
  }

  onChangeDescription(data){
    //console.log('on changedescription article : '+data);
    this.setState({description : data, injectValue : false})
  }

  onSubmitChange(){
    //console.log('OnSubmitChange');
    const {description} = this.state;
    const {data} = this.props;

    if (this.props.onSave){
      this.props.onSave(data.id, {description : description});
    }
  }

  onSuggestionsOpen(){
    this.setState({isOpenSelectSuggestion : true})
  }

  onSuggestionsClose(){
    this.setState({isOpenSelectSuggestion : false})
  }

  onSelectSuggestion(row){
    this.setState({isOpenSelectSuggestion : false, originalDescription: String(row.description), description : String(row.description), injectValue : true})
  }

  render(){
    const {data, classes, t, canEdit} = this.props;
    const {dimensions, error, description, isOpenSelectSuggestion, originalDescription, entity, injectValue} = this.state;

    const {width, height} = dimensions;

    let changed = data.description !== description;

    return(
      <>
        <Grid item xs={12} md={4} lg={4} >
          {error ? <ErrorContainer msg={t('errors.component')}/> : (
            <Card className={classes.root}>
              <CardHeader
                title={data.article.title}
                subheader={data.article.description}
                ref={el => (this.container = el)}
                action={
                  canEdit ? <CloseIcon aria-label="Delete" onClick={this.onDelete} className={classes.closeButton}/> : null
                }
              />
              <CardContent>
                <img width={width} height={height} src={data.article.coverUrl ? data.article.coverUrl : noImgPlaceholder} alt="cover image" className={classes.coverImage} onError={(e)=>{e.target.onerror = null; e.target.src=noImgPlaceholder}} />
              </CardContent>
              <CardContent>
                <a href={data.article.value} target="_blank" rel="noopener noreferrer" className="link">{data.article.value}</a>
              </CardContent>
              <CardContent>
                {canEdit ? (
                  <GeneralEditor originalData={originalDescription} data={description} onChange={this.onChangeDescription} placeholder={t('placeholders.addnote')} button={false} update={true} controlUpdate={injectValue} />
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: description }} className={classes.exerciseGeneral}/>
                )}
              </CardContent>
              {changed ?
                <CardContent className={classes.cardButtonBlock} >
                  <Button variant="outlined" color="primary" size="small" onClick={this.onSubmitChange}>
                    {t('buttons.save')}
                  </Button>
                </CardContent>
                : <></>}

              {canEdit && (
              <CardContent>
                <Tooltip title={t('patients.detail.'+entity+'.suggestions.button')}>
                  <IconButton className={classes.iconButton} onClick={this.onSuggestionsOpen} aria-label={"ariaLabel-"+entity+"-"+data.article.id}>
                    <History className={classes.editIcon} />
                  </IconButton>
                </Tooltip>
              </CardContent>
              )}
              <ArticleSuggestionsSelectionDialog open={isOpenSelectSuggestion} onClose={this.onSuggestionsClose} onSelect={this.onSelectSuggestion} id={data.article.id}/>
            </Card>
          )}
        </Grid>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Article));

Article.defaultProps = {
  canEdit : true
}