import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components

import EntityManager from "../../components/EntityManager/EntityManager";
import {
 getConfigurationEntityManagerPatients,
} from "../../utils/PatientsUtils";
import { patientDetail } from "../../context/PatientContext";
import { UserStateContext } from "../../context/UserContext";

const styles = useStyles;

class Patients extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      error: false,
    };


  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  onRowClick(data){
    //console.log("on click : " + JSON.stringify(data));
    const {history} = this.props;
    patientDetail(data.id, history)
  }

  render() {
    let {classes, t} = this.props;
    let user = this.context.user;
    //console.log('USer in context : ' + JSON.stringify(this.context.user))

    let configuration = getConfigurationEntityManagerPatients(user, t, classes, (data) => {this.onRowClick(data)})

    return (
      <EntityManager configuration={configuration}  />
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Patients));
Patients.contextType = UserStateContext;