import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import YouTube from "react-youtube";
import getVideoId from "get-video-id";
import ErrorContainer from "../../../components/ErrorContainer";

const styles = useStyles;

class ExercisePublic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dimensions: { width: 0, height: 0 },
      error : false,
      description : props.data.description
    };

  }

  componentDidMount() {

    let height = (this.container.offsetWidth - 32) / 16 * 9;

    this.setState({
      dimensions: {
        width: this.container.offsetWidth - 32,
        height: height,
      },
    });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
  }

  render(){
    const {data, classes, t} = this.props;
    const {dimensions, error} = this.state;
    const {width, height} = dimensions;
    const opts = {
      width: width,
      height: height
    };

    const youtubeId = getVideoId(data.exercise.value);

    return(
      <>
      <Grid item xs={12} md={4} lg={4} className={classes.cardExercise}>
        {error ? <ErrorContainer msg={t('errors.component')}/> : (
          <Card className={classes.root}>
            <CardHeader
              title={data.exercise.title}
              subheader={data.exercise.description}
              ref={el => (this.container = el)}
            />
            <CardContent>
              {width > 0 ? <YouTube videoId={youtubeId.id} opts={opts}/> : <></>}
            </CardContent>
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: data.description }}/>
            </CardContent>
          </Card>
        )}
      </Grid>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(ExercisePublic));