export const URL_USER_ME_GET = "/api/v1/users/me";
export const URL_USER_CREATE = "/api/v1/users";
export const URL_USER_GET = "/api/v1/users";
export const URL_USER_GET_BY_ID = "/api/v1/users/:id";
export const URL_USER_DELETE = "/api/v1/users/:id";
export const URL_USER_UPDATE = "/api/v1/users/:id";
export const URL_USER_DEFAULTS_CREATE = "/api/v1/users/defaults";
export const URL_USER_CHANGE_PASSWORD = "/api/v1/users/:id/password";

export const URL_DASHBOARD_STATS_PATIENTS_GET = "/api/v1/dashboard/stats/patients";
export const URL_DASHBOARD_STATS_USERS_GET = "/api/v1/dashboard/stats/users";
export const URL_DASHBOARD_STATS_MARKETING_GET = "/api/v1/dashboard/stats/marketing";
export const URL_DASHBOARD_STATS_CONSENT_GET = "/api/v1/dashboard/stats/consent";

export const URL_PATIENTS_CREATE = "/api/v1/patients";
export const URL_PATIENTS_GET = "/api/v1/patients";
export const URL_PATIENTS_GET_ALL = "/api/v1/patients/all";
export const URL_PATIENTS_GET_BY_ID = "/api/v1/patients/:id";
export const URL_PATIENTS_DELETE = "/api/v1/patients/:id";
export const URL_PATIENTS_UPDATE = "/api/v1/patients/:id";
export const URL_PATIENTS_UPDATE_DESCRIPTION = "/api/v1/patients/:id/description";

export const URL_CARD_BLOCK_CREATE = "/api/v1/cards/:cardId/blocks";
export const URL_CARD_BLOCK_DELETE = "/api/v1/cards/:cardId/blocks/:blockId";
export const URL_CARD_ORDER_UPDATE = "/api/v1/cards/:cardId/blocks/order";

export const URL_EXERCISES_CREATE = "/api/v1/exercises";
export const URL_EXERCISES_GET = "/api/v1/exercises";
export const URL_EXERCISES_GET_BY_ID = "/api/v1/exercises/:id";
export const URL_EXERCISES_DELETE = "/api/v1/exercises/:id";
export const URL_EXERCISES_UPDATE = "/api/v1/exercises/:id";
export const URL_EXERCISES_SUGGESTIONS = "/api/v1/exercises/:id/suggestions";

export const URL_ARTICLES_CREATE = "/api/v1/articles";
export const URL_ARTICLES_GET = "/api/v1/articles";
export const URL_ARTICLES_GET_BY_ID = "/api/v1/articles/:id";
export const URL_ARTICLES_DELETE = "/api/v1/articles/:id";
export const URL_ARTICLES_UPDATE = "/api/v1/articles/:id";
export const URL_ARTICLES_SUGGESTIONS = "/api/v1/articles/:id/suggestions";

export const URL_BLOGS_ARTICLES_GET = "/api/v1/blogs/articles";
export const URL_BLOGS_METADATA_GET = "/api/v1/blogs/metadata";

export const URL_CARD_EXERCISE_ADD = "/api/v1/cards/:cardId/blocks/:blockId/exercises";
export const URL_CARD_EXERCISE_DELETE = "/api/v1/cards/:cardId/blocks/:blockId/exercises/:exerciseCardId";
export const URL_CARD_EXERCISE_UPDATE = "/api/v1/cards/:cardId/blocks/:blockId/exercises/:exerciseCardId";
export const URL_CARD_EXERCISE_ORDER_UPDATE = "/api/v1/cards/:cardId/blocks/:blockId/exercises/order";

export const URL_CARD_ARTICLE_ADD = "/api/v1/cards/:cardId/blocks/:blockId/articles";
export const URL_CARD_ARTICLE_DELETE = "/api/v1/cards/:cardId/blocks/:blockId/articles/:articleCardId";
export const URL_CARD_ARTICLE_UPDATE = "/api/v1/cards/:cardId/blocks/:blockId/articles/:articleCardId";

export const URL_CARD_COMMENT_ADD = "/api/v1/cards/:cardId/comments";
export const URL_CARD_COMMENT_DELETE = "/api/v1/cards/:cardId/comments/:commentId";
export const URL_CARD_COMMENT_UPDATE = "/api/v1/cards/:cardId/comments/:commentId";

export const URL_CARD_GENERAL_UPDATE = "/api/v1/cards/:cardId/blocks/:blockId/general";

export const URL_PRIVATE_CARD_BLOCK_CREATE = "/api/v1/cards/:cardId/private/blocks";
export const URL_PRIVATE_CARD_BLOCK_DELETE = "/api/v1/cards/:cardId/private/blocks/:blockId";
export const URL_PRIVATE_CARD_GENERAL_UPDATE = "/api/v1/cards/:cardId/private/blocks/:blockId/general";
export const URL_PRIVATE_CARD_ORDER_UPDATE = "/api/v1/cards/:cardId/private/blocks/order";

export const URL_TEMPLATES_CREATE = "/api/v1/templates";
export const URL_TEMPLATES_GET = "/api/v1/templates";
export const URL_TEMPLATES_GET_BY_ID = "/api/v1/templates/:id";
export const URL_TEMPLATES_DELETE = "/api/v1/templates/:id";
export const URL_TEMPLATES_UPDATE = "/api/v1/templates/:id";

export const URL_DICTIONARIES_CREATE = "/api/v1/dictionaries";
export const URL_DICTIONARIES_GET = "/api/v1/dictionaries";
export const URL_DICTIONARIES_GET_BY_ID = "/api/v1/dictionaries/:id";
export const URL_DICTIONARIES_DELETE = "/api/v1/dictionaries/:id";
export const URL_DICTIONARIES_UPDATE = "/api/v1/dictionaries/:id";

/** PUBLIC **/
export const URL_PUBLIC_PATIENT = "/api/public/p/:patientId/c/:cardId";
export const URL_PUBLIC_CONSENT = "/api/public/c/:companyId";

