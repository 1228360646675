import React, { useEffect, useState, useRef} from "react";
import useStyles from "./styles";
import { withRouter, useParams } from "react-router-dom";

import {
  Grid,
  Button,
  TextField, CircularProgress,FormControl, FormLabel, RadioGroup, Radio
} from "@material-ui/core";

import SignatureCanvas from 'react-signature-canvas'

import { useTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { COLOR_REACTIVA } from "../../utils/ColorsUtils";
import DevDebugJson from "../../components/Dev/DevDebugJson";
import { validateEmail } from "../../utils/FormUtils";
import { API } from "../../services/AuthService";
import { URL_PUBLIC_CONSENT } from "../../utils/UrlConstants";
import { generateKey } from "../../utils/StringUtils";
import logo from "../../images/logo.png";

function Consent(props) {
  const { companyId} = useParams();

  const [mode, setMode] = useState("FULLFILL"); // FULLFILL, SUCCESS
  const [formData, setFormData] = useState(getEmptyFormData());
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const [canvasUUID, setCanvasUUID] = useState(generateKey());
  const [dimensions, setDimensions] = useState({ width: 500, height: 200 });
  const containerRef = useRef(null);
  const sigCanvas = useRef(null);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) tempErrors.name = "El nombre es obligatorio";
    if (!formData.surname) tempErrors.surname = "El apellido es obligatorio";
    if (!formData.dni) tempErrors.dni = "El DNI es obligatorio";
    if (!formData.address) tempErrors.address = "La dirección es obligatoria";
    if (!formData.phone) tempErrors.phone = "El teléfono es obligatorio";
    if (!formData.generalConsent) tempErrors.generalConsent = "Debes marcar esto si deseas continuar";
    if (!formData.sign) tempErrors.sign = "Debes firmar para continuar"
    if (formData.email && !validateEmail(formData.email)) tempErrors.email = "El email no es válido.";
    //if (!formData.birthDate) tempErrors.birthDate = "La fecha de nacimiento es obligatoria";

    if (!formData.birthDate) {
      tempErrors.birthDate = "La fecha de nacimiento es obligatoria y en el formato DD/MM/YYYY";
    } else {
      const birthDatePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/;
      if (!birthDatePattern.test(formData.birthDate)) {
        tempErrors.birthDate = "La fecha de nacimiento no es válida. Debe estar en formato DD/MM/YYYY";
      }
    }

    if (formData.minor){
      if (!formData.minorParentDni) tempErrors.minorParentDni = "El DNI de la madre/padre es obligatorio";
      if (!formData.minorParentName) tempErrors.minorParentName = "El nombre de la madre/padre es obligatorio";
      if (!formData.minorParentSurname) tempErrors.minorParentSurname = "El apellido de la madre/padre es obligatorio";
    }

    if (!formData.sex) tempErrors.sex = "El sexo es obligatorio";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = (width * 9) / 16;
        setDimensions({
          width,
          height

        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    setIsFormValid(validate() && formData.generalConsent);
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Handle form submission logic here
      console.log('Form data:', formData);
    }
  };

  function getEmptyFormData(){
    return {
      name: '',
      surname: '',
      dni: '',
      address: '',
      phone: '',
      email: '',
      minor : false,
      minorParentName: '',
      minorParentSurname : '',
      minorParentDni : '',
      marketingConsent: false,
      generalConsent: false,
      sex : '',
      birthDate: '',
      sign: null,
    }
  }

  function cleanFormForNextPatient(){
    setFormData(getEmptyFormData());
    setCanvasUUID(generateKey());
    setMode("FULLFILL")
    validate();


    window.location.href = "https://reactivasants.com/"
  }

  function cleanSign(){
    setCanvasUUID(generateKey());
    setFormData({
      ...formData,
      sign : null
    })
  }

  const handleChangeBirthDate = (e) => {
    let value = e.target.value;
    const inputType = e.nativeEvent.inputType;

    // If the inputType indicates deletion, set the value directly without formatting
    if (inputType === "deleteContentBackward" || inputType === "deleteContentForward") {
      setFormData({
        ...formData,
        birthDate: value,
      });
      return;
    }

    // Remove all non-digit characters
    value = value.replace(/\D/g, '');

    // Add "/" after the second digit
    if (value.length >= 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
    // Add "/" after the fifth digit
    if (value.length >= 5) {
      value = value.slice(0, 5) + '/' + value.slice(5);
    }

    setFormData({
      ...formData,
      birthDate: value,
    });
  };

  function save(){
    console.log('Saving form : ' + JSON.stringify(formData))

    setSaving(true)

    let urlWithParam = URL_PUBLIC_CONSENT.replace(":companyId", companyId);

    let request ={
      name: formData.name,
      surname: formData.surname,
      dni: formData.dni,
      address: formData.address,
      phone: formData.phone,
      email: formData.email,
      marketingConsent: formData.marketingConsent,
      minor: formData.minor,
      minorParentName: formData.minorParentName,
      minorParentSurname : formData.minorParentSurname,
      minorParentDni : formData.minorParentDni,
      sign: formData.sign,
      sex : formData.sex,
      birthdate: formData.birthDate,
    }

    console.log('Saving request : ' + JSON.stringify(request))

    API(false).post(urlWithParam, request).then(res => {

      console.log('Save ok!!!!')

      setMode("SUCCESS")

      setSaving(false)

      // TODO: toast

    }).catch(e => {
      console.error('On get exception '+JSON.stringify(e));
      console.error(e)

      setSaving(false)
    })

  }

  var classes = useStyles();
  const {i18n} = useTranslation();

  let {sign} = formData;

  if (mode === "SUCCESS") {
    // TODO : we must do something
    return (
      <Grid container className={classes.container}>
        <div className={classes.content}>

          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <img className={classes.logotypeImage} src={logo} alt="Reactiva logo"/>
          </div>

          <div className={classes.title}>
            Consentimiento Informado
          </div>

          <div className={classes.success}>
            ¡ Gracias, que disfrutes de tu primera sesión en ReActiva !
          </div>

          <div style={{ marginTop: "20px" }}>

            <div className={classes.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isFormValid}
                className={classes.button}
                size={"large"}
                onClick={() => {
                  cleanFormForNextPatient();
                }}
              >
                Echa un ojo a nuestros servicios
                o disfruta de la sala de espera :)
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.content}>

        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <img className={classes.logotypeImage} src={logo} alt="Reactiva logo"/>
        </div>

        <div className={classes.title}>
          Consentimiento Informado
        </div>


        <div>
          <form onSubmit={handleSubmit}>

            <div className={classes.generalForm}>

              <div className={classes.nameSurnameRow}>
                <div className={classes.formCell}>
                  <TextField
                    label="Nombre"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                </div>
                <div className={classes.formCell}>
                  <TextField
                    label="Apellidos"
                    name="surname"
                    value={formData.surname}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.surname}
                    helperText={errors.surname}
                  />
                </div>
              </div>

              <div className={classes.nameSurnameRow}>
                <div className={classes.formCell}>
                  <TextField
                    label="DNI o NIE"
                    name="dni"
                    value={formData.dni}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.dni}
                    helperText={errors.dni}
                  />
                </div>
                <div className={classes.formCell}>
                  <FormControl component="fieldset" className={classes.formControl} required error={!!errors.sex}>
                    <FormLabel component="legend" className={"MuiInputLabel-shrink"}>{"Sexo"}</FormLabel>
                    <RadioGroup row
                                aria-label="sex"
                                name="sex"
                                value={formData.sex}
                                onChange={handleChange}
                    >
                      <FormControlLabel value="f" control={<Radio/>} label={i18n.t("patients.fields.sex.f")}/>
                      <FormControlLabel value="m" control={<Radio/>} label={i18n.t("patients.fields.sex.m")}/>
                      <FormControlLabel value="ALL" control={<Radio/>} label={i18n.t("patients.fields.sex.ALL")}/>
                    </RadioGroup>
                    {!!errors.sex && (
                      <FormHelperText>{errors.sex}</FormHelperText>
                    )}
                  </FormControl>



                </div>
              </div>


              <div className={classes.nameSurnameRow}>

                <div className={classes.formCell}>
                  <TextField
                    label="Fecha de nacimiento"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChangeBirthDate}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.birthDate}
                    helperText={errors.birthDate}
                  />
                </div>

                <div className={classes.formCell}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.minor}
                        onChange={handleChange}
                        name="minor"
                      />
                    }
                    label="El paciente es MENOR de edad"

                  />
                </div>

              </div>

              {formData.minor && (
                <div className={classes.nameSurnameRow}
                     style={{fontSize:"18px", fontWeight:"bold", padding:"10px", marginTop:"10px"}}
                >
                  Informaciones de la madre / padre o tutor legal del paciente:
                </div>
              )}

              {formData.minor && (
                <div className={classes.nameSurnameRow}>
                  <div className={classes.formCell}>
                    <TextField
                      label="Nombre"
                      name="minorParentName"
                      value={formData.minorParentName}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.minorParentName}
                      helperText={errors.minorParentName}
                    />
                  </div>
                  <div className={classes.formCell}>
                    <TextField
                      label="Apellidos"
                      name="minorParentSurname"
                      value={formData.minorParentSurname}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.minorParentSurname}
                      helperText={errors.minorParentSurname}
                    />
                  </div>
                </div>
              )}


              <div className={classes.nameSurnameRow}>

                <div className={classes.formCell}>
                  <TextField
                    label="Dirección"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.address}
                    helperText={errors.address}
                  />
                </div>

                <div className={classes.formCell}>
                  <TextField
                    label="Teléfono"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </div>

              </div>

              {/* Contact Phone and email */}
              <div className={classes.nameSurnameRow}>

                {formData.minor && (
                  <div className={classes.formCell}>
                    <TextField
                      label="DNI o NIE"
                      name="minorParentDni"
                      value={formData.minorParentDni}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      required
                      error={!!errors.minorParentDni}
                      helperText={errors.minorParentDni}
                    />
                  </div>
                )}

                <div className={classes.formCell}>
                  <TextField
                    label="Correo electrónico - Opcional"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    fullWidth
                    margin="normal"
                  />
                </div>


                {!formData.minor && <div className={classes.formCell} />}

              </div>

            </div>

            <div className={classes.contentPage}>
              <div className={classes.subsectionTitle}>
                Cláusula de protección de datos informativa pacientes
              </div>

              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionHeader}>RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</span>
                <span className={classes.subsectionContent}>
                De conformidad con lo que establece el Reglamento Europeo 679/2016 y la L.O. 3/2018, le informamos que los datos de carácter personal que comunique durante el servicio de fisioterapia, así como las imágenes personales que se realicen al paciente con el consentimiento del abajo firmante, serán confidenciales y formarán parte de un tratamiento, cuyo Responsable es EVA FEDERICI, con NIE: Y3769546K y domicilio en C/ Sant Medir, num. 16, local 2, 08028, Barcelona.
              </span>
              </div>

              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionHeader}>FINALIDAD</span>
                <span className={classes.subsectionContent}>
                1. La finalidad de su creación, existencia y mantenimiento es registro y tratamiento de los datos de carácter personal de pacientes con el objeto de evaluar su estado de salud, prestarle el servicio de fisioterapia y gestionar su historia clínica                     2. Gestión administrativa, facturación, contabilidad y obligaciones legales.
              </span>
              </div>
            </div>


            <div className={classes.checkContainer}>
            <div className={classes.formCellFull}
                   style={{}}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.marketingConsent}
                      onChange={handleChange}
                      name="marketingConsent"
                    />
                  }
                  label="Marcando la casilla, autoriza a que sus datos puedan ser utilizados por vía electrónica, sms o whatsapp para enviarle publicidad del centro, talleres, charlas y actividades que pueden interesarte."

                />
              </div>
            </div>
            <div className={classes.checkContainer}>
              <div className={classes.formCellFull}
                   style={{}}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.generalConsent}
                      onChange={handleChange}
                      name="generalConsent"
                      error={!!errors.generalConsent}
                      style={{ color: !!errors.generalConsent ? "red" : "" }}
                      required={true}
                    />
                  }
                  label="Marcando la casilla, autoriza a que sus datos puedan ser utilizados por vía electrónica, sms o whatsapp para recordarle los horarios de las visitas y enviarle las facturas (obligatorio)"

                />

                {!!errors.generalConsent && (
                  <FormHelperText error>
                    {errors.generalConsent}
                  </FormHelperText>
                )}
              </div>

            </div>

            <div className={classes.contentPage}>
              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionHeader}>DESTINATARIOS DE LA INFORMACIÓN</span>
                <span className={classes.subsectionContent}>
                  Estos datos serán tratados por EVA FEDERICI y los colaboradores del Responsable en caso de que fuera necesario, y serán sometidos a secreto profesional para poder prestarle el servicio solicitado. Le recordamos que está prohibida la entrega de resultados médicos a terceras personas que no sean el paciente, excepto si presentan el DNI del paciente y una autorización firmada
                </span>
              </div>

              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionHeader}>PLAZO DE CONSERVACIÓN</span>
                <span className={classes.subsectionContent}>
                  Los datos de carácter personal se conservarán en cumplimiento de los plazos legales de prescripción que resulten de aplicación
                </span>
              </div>


              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionHeader}>NECESIDAD DE OBTENCIÓN DE DATOS Y CONSENTIMIENTO</span>
                <span className={classes.subsectionContent}>
                  Para conseguir la finalidad descrita, es preciso que usted aporte la totalidad de los datos que se le soliciten. Las consecuencias de la negativa a suministrar los
                  datos necesarios para la finalidad de los tratamientos y de su tratamiento, implicará la imposibilidad del profesional para conseguir la correcta prestación de sus servicios. Mediante la firma del presente
            documento, EVA FEDERICI entiende que le ha dado consentimiento para todos los tratamientos de datos de carácter personal anteriormente descritos, y especialmente, para el tratamiento de los datos relativos a su salud que sean necesarios para la correcta prestación de los servicios.
                </span>
              </div>

              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionHeader}>DERECHOS</span>
                <span className={classes.subsectionContent}>
                    Puede ejercer sus derechos de información, acceso, rectificación, oposición, supresión, limitación
              de tratamiento y portabilidad de los datos personalmente, a EVA FEDERICI en C/ Sant Medir, num. 16, local 2,
              08028, Barcelona. Podrá dirigirse a la Autoridad de Control competente para presentar la reclamación que
              considere oportuna.
                  </span>
              </div>

            </div>

            <div className={classes.contentPage}>
              <div className={classes.subsectionTitle}>
                CONSENTIMIENTO INFORMADO FISIOTERAPIA GENERAL
              </div>

              <div className={classes.subsectionBlock}>
                <span className={classes.subsectionContent}>
                  Yo, el abajo firmante
Mayor de edad, he pedido asistencia de fisioterapia - osteopatia en REACTIVA ( Centro de fisioterapia , osteopatia, terapias integrales ).
Declaro que estoy informada/o , desde la perspectiva de la fisioterapeuta, respecto del alcance de mi patología y de la naturaleza del tratamiento que se me ha propuesto, en qué consiste, alternativas de tratamiento y si existe algún riesgo.
He recibido contestación a todas las cuestiones que he querido plantear de modo que, entendiéndose me he adecuadamente enterado, autorizo la aplicación del tratamiento descrito, en el bien entendido que puedo hacerlo suspender libremente.
En consecuencia, doy mi consentimiento
                </span>
              </div>
            </div>

            <div className={classes.signContainer}
                 ref={containerRef}
            >
              <div className={classes.subsectionTitle}
                   style={{ color: (!!errors.sign ? "red" : "#666"), marginBottom: "10px" }}
              >
                FIRMAR AQUI
              </div>

              <div style={{ marginBottom: "20px" }}>
                <Button
                  type="submit"
                  variant="outlined" color="primary"
                  size={"small"}
                  onClick={() => {
                    if (!saving) cleanSign();
                  }}
                >
                  Limpiar la firma
                </Button>
              </div>


              <div style={{ backgroundColor: "#eee" }}>
                <SignatureCanvas key={canvasUUID}
                                 penColor={COLOR_REACTIVA}
                                 ref={sigCanvas}
                                 dotSize={4}
                                 canvasProps={{
                                   width: dimensions.width,
                                   height: dimensions.height,
                                   className: "sigCanvas",
                                 }}
                                 onEnd={() => {
                                   console.log("End Sign");
                                   const dataUrl = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");

                                   let base64Image = dataUrl ? dataUrl.split(",")[1] : null;

                                   setFormData({
                                     ...formData,
                                     sign: base64Image,
                                   });
                                 }}
                                 backgroundColor={"#eee"}
                />
              </div>


              {!!errors.sign && (
                <FormHelperText error>
                  {errors.sign}
                </FormHelperText>
              )}

              {/*  */}


            </div>

            <div style={{ marginTop: "20px" }}>

              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isFormValid}
                  className={classes.button}
                  size={"large"}
                  onClick={() => {
                    if (!saving) save();
                  }}
                >
                  {saving ? <CircularProgress size={26} className={classes.loginLoader}/> : "Doy mi consentimiento"}
                </Button>

                {false && (
                  <div>
                    <DevDebugJson data={sign} title={"sign"} component={"data"}/>
                    <DevDebugJson data={companyId} title={"companyId"} component={"data"}/>
                  </div>
                )}


              </div>

            </div>


          </form>
        </div>

      </div>
    </Grid>
  )
}

export default withRouter(Consent);