import React from 'react';
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const styles = useStyles;


class FeedbackFormDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.onClose = this.onClose.bind(this);
  }

  onClose(){
    if (this.props.onClose){
      this.props.onClose();
    }
  }

  render() {
    const { classes } = this.props;
    const { successMessage, errorMessage, success, error} = this.props;

    return (
      <>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={success}
          autoHideDuration={6000}
          onClose={this.onClose}
        >
          <SnackbarContent message={successMessage} className={classes.snack}/>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={error}
          autoHideDuration={6000}
          onClose={this.onClose}
        >
          <SnackbarContent message={errorMessage} className={classes.snackError}/>
        </Snackbar>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(FeedbackFormDialog));