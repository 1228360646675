import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  errorContainer: {
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    padding : theme.spacing(2),
    backgroundColor: theme.palette.error.dark,
    borderRadius: 5
  },
  typo: {
    color: theme.palette.text.white,
  }
}));
