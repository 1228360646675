import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

import EntityManager from "../../components/EntityManager/EntityManager";
import { UserStateContext } from "../../context/UserContext";
import {getDictionaryConfiguration } from "../../utils/DictionaryUtils";

const styles = useStyles;

class Dictionaries extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      error: false,
    };

  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  render() {
    let {classes, t} = this.props;
    let user = this.context.user;

    let configuration = getDictionaryConfiguration(user, classes);

    return (
      <EntityManager configuration={configuration}  />
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Dictionaries));
Dictionaries.contextType = UserStateContext;