import React, {Component} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const MEDIAQUERY_BIG = "(min-width: 1824px)";
export const MEDIAQUERY_MEDIUM = "(min-width: 1200px)";

export const withMediaQuery = (queries = []) => Component => props => {
    const mediaProps = {}
    queries.forEach(q => {
        mediaProps[q[0]] = useMediaQuery(q[1])
    })
    return <Component {...mediaProps} {...props} />
}

