import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getCookieWithDefault(cookieName, defaultValue = null){
  let cookieValue = cookies.get(cookieName);
  console.log('Cookie ' + cookieName + " value detected : " + cookieValue)
  if (cookieValue == null || cookieValue === ""){
    return defaultValue;
  }
  return cookieValue;
}

export function setCookie(cookieName, cookieValue, path = '/'){
  cookies.set(cookieName, cookieValue, { path: path });
}

export const COOKIE_AUTO_SAVE = "AUTO_SAVE";

export const DEFAULT_COOKIE_AUTO_SAVE = true;