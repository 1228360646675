const styles = theme => ({
  root: {

  },
  gridButtonBlock : {
    textAlign : "right",
    paddingTop: 5
  },

  generalEditor : {
    minHeight: "275px !important",
    maxHeight: "60vh !important",
    border: "1px solid #F1F1F1 !important",
    padding: "5px !important",
    borderRadius: "2px !important"
  },

  generalEditorFlat : {
    minHeight: "100px !important",
    maxHeight: "40vh !important",
    border: "1px solid #F1F1F1 !important",
    padding: "5px !important",
    borderRadius: "2px !important"
  },

  generalEditorOverlayed : {
    minHeight: "85vh !important",
    maxHeight: "85vh !important",
    border: "1px solid #F1F1F1 !important",
    padding: "5px !important",
    borderRadius: "2px !important"
  },

  infoLabel : theme.infoLabel,
  successLabel : theme.successLabel,

  generalEditorOverlay:{
    backgroundColor: "white",
    position:"fixed",
    top:0,
    left:0,
    width:"100%",
    height:"100%",
    zIndex: 9999,
  }
  

});

export default styles;