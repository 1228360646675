const styles = theme => ({
  privateBlockGeneral : theme.showHtmlText,
  containerUserSelect : {
    marginBottom:"10px"
  },


});

export default styles;



