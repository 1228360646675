import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  userIconArrowContainer : {
    padding:"0px 1em",
    margin:"auto"
  },
  userIconArrowContainerSmall : {
    padding: "0px 5px",
    margin: "auto"
  },
  commentContent : {
    padding:"20px"
  },
  commentUsers : {
    display:'flex',
    flexDirection: "row",
    alignItems:"initial"
  },
  userIconArrow:{
    fontSize:"18px"
  },
  userContainer : {
    flex: 1
  },
  messageContainer : {
    margin:"5px 0px"
  },
  createdAtContainer : {
    textAlign:"right"
  },
  deleteButtonContainer : {
    textAlign:"right"
  }
}))