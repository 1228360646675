const local = {
  //API_BASE_PATH: "http://localhost:9090",
  API_BASE_PATH: "http://localhost:9090",
};

const dev = {
  API_BASE_PATH: "https://api.reactivasants.com",
};

console.log('the environment is '+process.env.REACT_APP_STAGE)

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'local'
  ? local
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
