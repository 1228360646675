import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import ErrorContainer from "../../../components/ErrorContainer";
import noImgPlaceholder from "../../../images/no_image_ph.png";

const styles = useStyles;

class ArticlePublic extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dimensions: { width: 0, height: 0 },
      error : false,
      description : props.data.description
    };

  }

  componentDidMount() {
    let height = (this.container.offsetWidth - 32) / 16 * 9;

    this.setState({
      dimensions: {
        width: this.container.offsetWidth - 32,
        height: height,
      },
    });
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    //console.log('Error detected componentDidCatch');
  }

  render(){
    const {data, classes, t} = this.props;
    const {dimensions, error, description} = this.state;

    const {width, height} = dimensions;

    return(
      <>
        <Grid item xs={12} md={4} lg={4} className={classes.cardArticle}>
          {error ? <ErrorContainer msg={t('errors.component')}/> : (
            <Card className={classes.root}>
              <CardHeader
                title={data.article.title}
                subheader={data.article.description}
                ref={el => (this.container = el)}
              />
              <CardContent>
                <img width={width} height={height} src={data.article.coverUrl ? data.article.coverUrl : noImgPlaceholder} alt="cover image" className={classes.coverImage} onError={(e)=>{e.target.onerror = null; e.target.src=noImgPlaceholder}}/>
              </CardContent>
              <CardContent>
                <a href={data.article.value} target="_blank" rel="noopener noreferrer" className="link">{data.article.value}</a>
              </CardContent>
              <CardContent>
                <div dangerouslySetInnerHTML={{ __html: data.description }}/>
              </CardContent>
            </Card>
          )}
        </Grid>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(ArticlePublic));