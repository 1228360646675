import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation, withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import useStyles from "./styles";
import { API } from "../../../services/AuthService";

import * as formUtils from '../../../utils/FormUtils';
import { URL_PRIVATE_CARD_BLOCK_CREATE } from "../../../utils/UrlConstants";

import { sendNotification } from "../../ToastContainerCustom/ToastContainerCustom";
import { UserProvider, UserStateContext, useUserDispatch, useUserState } from "../../../context/UserContext";
import { Typography } from "../../Wrappers";
import TitleSectionSuggestion from "../../Suggestions/TitleSectionSuggestion/TitleSectionSuggestion";
import TemplateSelection from "../../Template/TemplateSelection/TemplateSelection";
import { TEMPLATE_TYPE_PRIVATE } from "../../../utils/TemplateUtils";

const styles = useStyles;

class CreatePrivateSectionFormDialog extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entity : "patients",
      api : URL_PRIVATE_CARD_BLOCK_CREATE,
      form : {
        title : "",
        templateId : null
      },
      formValidation : {
        title: {
          type: formUtils.REQUIRED_INPUT
        }
      },
      formError : {
        title : false,
      },
      enableButton : true,
      loadingButton : false
    };
    this.baseState = this.state;
    // This binding is necessary to make `this` work in the callback
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checkAll = this.checkAll.bind(this);
  }

  componentDidMount(){
    //const user = this.context;
    //console.log('user in context is '+);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.open === true && this.props.open === false) {
      //console.log('reopening');
      this.setState(this.baseState);
    }
  }

  onClickSubmit() {
    console.log('Onclick submit');
    const { cardId} = this.props;
    const { form, formError, entity } = this.state;
    let {api} = this.state;
    const { onSubmit, t } = this.props;

    if (this.checkAll()) {
      this.setState({enableButton : false, loadingButton : true});
      console.log('post api '+JSON.stringify(form));

      api = api.replace(":cardId", cardId);

      API().post(api, form)
        .then(res => {
          this.setState({loadingButton : false, enableButton : true});
          sendNotification(t(entity+'.detail.privateBlock.create.success'), "success");
          if (onSubmit) onSubmit();
        }).catch(e => {
          console.log('error api '+e);
          this.setState({loadingButton : false, enableButton : true});
          sendNotification(t(entity+'.detail.privateBlock.create.error'), "error");
      })
    } else {
      let errors = formUtils.getFormValidation(this.state.formValidation, this.state.form);
      console.log('errors '+JSON.stringify(errors));
      this.setState({...formError, formError : errors});
    }
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
  }



  checkAll(){
    // console.log('check all');
    return formUtils.checkForm(this.state.formValidation, this.state.form);
  }

  handleChange(event){
    formUtils.handleInputChange(event, this);
  }

  handleChangeTemplate = (templateId) => {
    console.log('SELECTING TEMPLATE : '+templateId)
    let {form} = this.state;
    this.setState({form : {...form, templateId : templateId}})
  }



  render() {
    const { classes, t, open, onClose } = this.props;
    const { enableButton, loadingButton, formError, form, entity } = this.state;

    return (
      <>
        <Grid container spacing={4}>
          <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth="md">
            <DialogTitle id="form-dialog-title">{t(entity+".detail.privateBlock.create.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(entity+".detail.privateBlock.create.description")}
              </DialogContentText>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  autoFocus
                  required
                  error={formError.title}
                  margin="dense"
                  id="title"
                  label={t(entity+(".detail.privateBlock.create.form.title.label"))}
                  helperText={formError.title ? t(entity+(".detail.privateBlock.create.form.title.error")) : ""}
                  onChange={this.handleChange}
                  value={form.title}
                  fullWidth
                />
              </form>

              <Grid container spacing={4} style={{marginTop: "10px", marginBottom: "10px"}}>
                <TitleSectionSuggestion title={t('suggestion.title')} onClick={(value) => {this.setState({form : { title : value}})}} />
              </Grid>

              <Grid container spacing={4} style={{marginTop: "20px", marginBottom: "20px", padding: "20px", backgroundColor: '#eee'}}>
                  <TemplateSelection type={TEMPLATE_TYPE_PRIVATE} onSelect={this.handleChangeTemplate} value={form.templateId}/>
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose}>
                {t(entity+(".detail.privateBlock.create.form.buttons.cancel"))}
              </Button>
              <Button onClick={this.onClickSubmit} color="primary" disabled={!enableButton}>
                {loadingButton ? (
                  <CircularProgress size={26}  />
                ) : (
                  <div>{t(entity+(".detail.privateBlock.create.form.buttons.ok"))}</div>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    );
  }
}

export default withTranslation()(withStyles(styles)(CreatePrivateSectionFormDialog));
CreatePrivateSectionFormDialog.contextType = UserStateContext;