import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

  templateDefault : {
    color: "green"
  }

}));

