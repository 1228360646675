import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import { CircularProgress, Grid } from "@material-ui/core";
import useStyles from "./styles";
import { API } from "../../../services/AuthService";

import * as formUtils from '../../../utils/FormUtils';
import {
  URL_ARTICLES_CREATE,
  URL_ARTICLES_GET_BY_ID,
  URL_ARTICLES_UPDATE,
  URL_BLOGS_METADATA_GET,
} from "../../../utils/UrlConstants";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { sendNotification } from "../../ToastContainerCustom/ToastContainerCustom";
import noImgPlaceholder from "../../../images/no_image_ph.png";
import { Typography } from "../../Wrappers";

const styles = useStyles;

class CreateArticleFormDialog extends React.Component {

  constructor(props) {
    super(props);

    const {isCreate} = props;

    this.state = {
      entity : "articles",
      api : isCreate ? URL_ARTICLES_CREATE : URL_ARTICLES_UPDATE,
      apiGet : URL_ARTICLES_GET_BY_ID,
      apiMetadata : URL_BLOGS_METADATA_GET,
      form : {
        title : "",
        description : "",
        type : "wordpress",
        value : "",
        coverUrl : "",
      },
      formValidation : {
        title: {
          type: formUtils.REQUIRED_INPUT
        },
        type: {
          type: formUtils.REQUIRED_INPUT
        },
        value : {
          type : formUtils.REQUIRED_URL
        },
        coverUrl : {
          type : formUtils.URL
        }
      },
      formError : {
        name : false,
        email : false,
        coverUrl : false
      },

      enableButton : true,
      loadingButton : false,
      enableButtonCover : true,
      loadingButtonCover : false,
    };
    this.baseState = this.state;
    // This binding is necessary to make `this` work in the callback
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.onRequestMetadata = this.onRequestMetadata.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {

    const {isCreate} = this.props;

    if (nextProps.open === true && this.props.open === false) {
      //console.log('reopening');
      this.setState(this.baseState);
      if (!isCreate) this.refreshData(nextProps.id);
    }
  }

  refreshData(id){
    const {apiGet} = this.state;

    console.log('refreshing data');

    if (id != null) {
      this.setState({isLoading : true})
      let url = apiGet.replace(":id", id);

      API().get(url)
        .then(res => {
          console.log('response ' + JSON.stringify(res.data))

          this.setState(
            {
              form : {
                title : res.data.title,
                type : res.data.type,
                description : res.data.description,
                value : res.data.value,
                coverUrl : res.data.coverUrl
              },
              isLoading : false
            }
          )

        }).catch(e => {
        console.log('On get exception '+JSON.stringify(e));
        this.setState({ error : true });
      })
    }
  }

  onClickSubmit() {
    console.log('Onclick submit');
    const { form, formError, api, entity } = this.state;
    const { onSubmit, t, isCreate, id } = this.props;

    if (this.checkAll()) {
      this.setState({enableButton : false, loadingButton : true});
      console.log('post api '+JSON.stringify(form));


      if (isCreate) {
        API().post(api, form)
          .then(res => {
            this.setState({ loadingButton: false, enableButton: true });
            sendNotification(t(entity + '.create.success'), "success");
            if (onSubmit) onSubmit();
          }).catch(e => {
          console.log('error api ' + e);
          this.setState({ loadingButton: false, enableButton: true });
          sendNotification(t(entity + '.create.error'), "error");
        })
      } else {
        let url = api.replace(':id', id);

        API().put(url, form)
          .then(res => {
            this.setState({loadingButton : false, enableButton : true});
            sendNotification(t(entity+'.update.success'), "success");
            if (onSubmit) onSubmit();
          }).catch(e => {
          console.log('error api '+e);
          this.setState({loadingButton : false, enableButton : true});
          sendNotification(t(entity+'.update.error'), "error");
        })
      }


    } else {
      let errors = formUtils.getFormValidation(this.state.formValidation, this.state.form);
      console.log('errors '+JSON.stringify(errors));
      this.setState({...formError, formError : errors});
    }
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
  }

  checkAll(){
    // console.log('check all');
    return formUtils.checkForm(this.state.formValidation, this.state.form);
  }

  handleChange(event){
    formUtils.handleInputChange(event, this);


    if (event.target.id === "value"){
      console.log('loading informations')
      setTimeout(() => {  this.onRequestMetadata(); }, 100);
    }
  }

  handleChangeSelect(event){
    formUtils.handleInputChange(event, this, "type");
  }

  onRequestMetadata(){
    const {t} = this.props;
    const {apiMetadata, form} = this.state;

    this.setState({enableButtonCover : false, loadingButtonCover : true});
    let request = {
      "url" : form.value
    }

    console.log('post api '+JSON.stringify(request));

    API().post(apiMetadata, request)
      .then(res => {
        let newForm = {...form, coverUrl : res.data.coverUrl, title : res.data.title, description : res.data.description, type : res.data.type };
        console.log('nuevo form ' + JSON.stringify(newForm) + " metadata retrieved : " + JSON.stringify(res));

        this.setState({loadingButtonCover : false, enableButtonCover : true, form : newForm});
      }).catch(e => {
      console.log('error api '+e);
      this.setState({loadingButtonCover : false, enableButtonCover : true});
      sendNotification(t('blogArticles.metadata.error'), "error");
    })
  }

  render() {
    const { classes, t, open, onClose, isCreate } = this.props;
    const { enableButton, loadingButton, formError, form, entity, enableButtonCover, loadingButtonCover } = this.state;


    return (
      <>
        <Grid container spacing={4}>
          <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
            <DialogTitle id="form-dialog-title">{t(entity+".create.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t(entity+".create.description")}
              </DialogContentText>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField
                  autoFocus
                  required
                  error={formError.value}
                  margin="dense"
                  id="value"
                  label={t(entity+(".create.form.value.label"))}
                  helperText={formError.value ? t(entity+(".create.form.value.error")) : ""}
                  onChange={this.handleChange}
                  value={form.value}
                  fullWidth
                />

                <div style={{textAlign: "center", padding:"10px 0px 40px 0px"}}>
                  <Button variant="outlined" color="primary" size="small" onClick={this.onRequestMetadata} disabled={!enableButtonCover}>
                    {t(entity+(".create.retrieveDate"))}
                  </Button>
                </div>

                <TextField
                  required
                  error={formError.title}
                  margin="dense"
                  id="title"
                  label={t(entity+(".create.form.title.label"))}
                  helperText={formError.title ? t(entity+(".create.form.title.error")) : ""}
                  onChange={this.handleChange}
                  value={form.title}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="description"
                  label={t(entity+(".create.form.description.label"))}
                  onChange={this.handleChange}
                  value={form.description}
                  multiline
                  rows={2}
                  fullWidth
                />
                <FormControl
                  fullWidth>
                  <InputLabel id="type-label">{t(entity+(".create.form.type.label"))}</InputLabel>
                  <Select
                    labelId="type-label"
                    id="type"
                    value={form.type}
                    onChange={this.handleChangeSelect}
                  >
                    <MenuItem value="wordpress">Wordpress</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                  <FormHelperText>{formError.type ? t(entity+(".create.form.type.error")) : ""}</FormHelperText>
                </FormControl>

                <TextField
                  error={formError.coverUrl}
                  margin="dense"
                  id="coverUrl"
                  label={t(entity+(".create.form.coverUrl.label"))}
                  helperText={formError.value ? t(entity+(".create.form.coverUrl.error")) : ""}
                  onChange={this.handleChange}
                  value={form.coverUrl}
                  fullWidth
                />
                <div style={{textAlign:"center", width:"100%", paddingTop:"20px"}}>
                  <Typography className={classes.typo} size="md">
                    {t(entity+(".create.imagePreview"))}
                  </Typography>
                  <div style={{width:"100%", paddingTop:"20px"}}>
                    {loadingButtonCover ? (
                      <CircularProgress size={26}  />
                    ) : (
                    <img src={form.coverUrl ? form.coverUrl : noImgPlaceholder} alt="cover image" className={classes.coverImageThumb} onError={(e)=>{e.target.onerror = null; e.target.src=noImgPlaceholder}} />
                    )}
                  </div>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose}>
                {t(entity+(".create.form.buttons.cancel"))}
              </Button>
              <Button onClick={this.onClickSubmit} color="primary" disabled={!enableButton}>
                {loadingButton ? (
                  <CircularProgress size={26}  />
                ) : (
                  <div>{isCreate ? t(entity+(".create.form.buttons.ok")) : t("buttons.save")}</div>
                )}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    );
  }
}

CreateArticleFormDialog.defaultProps = {
  isCreate : true
}

export default withTranslation()(withStyles(styles, { withTheme: true})(CreateArticleFormDialog));
