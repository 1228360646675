export default function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
    return null;
}

export function generateKey(lenght = 10, onlyLowerCase = false) {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    if (onlyLowerCase){
        possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    }

    for (let i = 0; i < lenght; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export function cleanCode(code){
    if (code){
        //let newCode = code.replace(/\s/g, '-');
        let newCode =code.replace(/[^a-zA-Z0-9]/g,'-');
        return newCode.toLowerCase();
    }
    return "";
}

export function cleanForCheck(code){
    if (code){
        return code.replace(/\s{2,}/g, ' ');
    }
    return code;
}

// test with : ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç
function removeAccents(text) {
    if (text){
        return text.replace(/[ÀÁÂÃÄÅ]/g, 'A')
          .replace(/[àáâãäå]/g, 'a')
          .replace(/[ÈÉÊË]/g, 'E')
          .replace(/[èéêë]/g, 'e')
          .replace(/[ÌÍÎÏ]/g, 'I')
          .replace(/[ìíîï]/g, 'i')
          .replace(/[ÒÓÔÕÖ]/g, 'O')
          .replace(/[òóôõö]/g, 'o')
          .replace(/[ÙÚÛÜ]/g, 'U')
          .replace(/[ùúûü]/g, 'u')
          .replace(/[Ñ]/g, 'N')
          .replace(/[ñ]/g, 'n')
          .replace(/[ç]/g, 'c')
          .replace(/[Ç]/g, 'C');
    }

    return "";

}

function removeSpecialChars(text) {
    if (typeof text != "string") {
        return null;
    }

    let pattern = /[^\x20\x2D0-9A-Z\x5Fa-z\xC0-\xD6\xD8-\xF6\xF8-\xFF]/g;
    return text.replace(pattern, '');
}

export function normalizeString(text){
    return (removeSpecialChars(removeAccents(text)));
}

export function createUsername(name, surname){
    return (normalizeString(name.toLowerCase()).replace(/\s+/g, '.'))+"."+(normalizeString(surname.toLowerCase()).replace(/\s+/g, '.'))
}

export function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}


export function normalizeSearch(text){
    let normalized = removeAccents(text);
    return normalized.toLowerCase();
}

export function normalizeTextForSearch(text){
    return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}