import React from "react";
import {
  Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import { URL_EXERCISES_DELETE, URL_EXERCISES_GET } from "../../utils/UrlConstants";
import { convert } from "../../utils/DateUtils";
import DataTable from "../../components/DataTable";
import CreateExerciseFormDialog from "../../components/FormDialog/CreateExerciseFormDialog/CreateExerciseFormDialog";
import { getThumbnail } from "../../utils/YoutubeUtils";
import ToastContainerCustom from "../../components/ToastContainerCustom/ToastContainerCustom";
import UpdateExerciseFormDialog from "../../components/FormDialog/UpdateExerciseFormDialog/UpdateExerciseFormDialog";
import { UserStateContext } from "../../context/UserContext";
import { hasRole, ROLE_ADMIN } from "../../utils/UserUtils";

const styles = useStyles;

class Exercises extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      error: false,
      isOpenDialogCreate : false,
      isOpenDialogUpdate : false,
      updateId : null,
      isReloadData : false,
      entity : 'exercises',
      api : URL_EXERCISES_GET,
      apiDelete : URL_EXERCISES_DELETE,
      columns : [
        {
          name: 'title',
        },
        {
          name: 'type',
          options : {
            filter: true,
            filterOptions: {
              names: ['youtube', 'others']
            },
          }
        },
        {
          name: 'value',
          options : {
            customBodyRender: (value => {
              return (<a href={value} target="_blank" rel="noopener noreferrer" className="link">{value}</a>)
            })
          }
        },
        {
          name: 'value',
          label : 'Preview',
          options: {
            customBodyRender: (value => {
              return (<img src={getThumbnail(value)} alt="youtube preview" className={classes.thumbnail} /> )
            })
          }
        },
        {
          name: 'createdAt',
          options: {
            sortDirection: 'none',
            customBodyRender: (value) => {
              return convert(value)
            }
          }
        }
      ]
    };

    // This binding is necessary to make `this` work in the callback
    this.onButtonTitleClick = this.onButtonTitleClick.bind(this);
    this.onSubmitCreate = this.onSubmitCreate.bind(this);
    this.onCloseCreate = this.onCloseCreate.bind(this);
    this.onReloadData = this.onReloadData.bind(this);
    this.onUpdateSelected = this.onUpdateSelected.bind(this);
    this.onSubmitEdit = this.onSubmitEdit.bind(this);
    this.onCloseEdit = this.onCloseEdit.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  onButtonTitleClick(){
    console.log('oonButtonTitleClick');
    this.setState({isOpenDialogCreate : true})
  }

  onSubmitCreate(){
    this.setState({isOpenDialogCreate : false, isReloadData : true})
  }

  onCloseCreate(){
    this.setState({isOpenDialogCreate : false})
  }

  onUpdateSelected(id, data){
    this.setState({isOpenDialogUpdate : true, updateId : id})
  }

  onSubmitEdit(){
    this.setState({isOpenDialogUpdate : false, isReloadData : true})
  }

  onCloseEdit(){
    this.setState({isOpenDialogUpdate : false})
  }

  onReloadData(){
    this.setState({
      isReloadData : false
    })
  }

  render() {
    const { t } = this.props;
    const { isOpenDialogCreate, isOpenDialogUpdate, isReloadData, entity, api, apiDelete, columns, updateId} = this.state;
    let user = this.context.user;

    return (
      <>
        <PageTitle title={t(entity+'.title')} button={t(entity+'.button')} onButtonClick={this.onButtonTitleClick}/>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DataTable entity={entity} api={api} apiDelete={apiDelete} columns={columns} reload={isReloadData} onReload={this.onReloadData} onUpdate={this.onUpdateSelected} deleteRow={hasRole([ROLE_ADMIN], user)}/>
          </Grid>
        </Grid>
        <CreateExerciseFormDialog open={isOpenDialogCreate} onSubmit={this.onSubmitCreate} onClose={this.onCloseCreate}/>
        <UpdateExerciseFormDialog open={isOpenDialogUpdate} onSubmit={this.onSubmitEdit} onClose={this.onCloseEdit} id={updateId}/>
        <ToastContainerCustom />
      </>
    )
  }
}

export default withTranslation()(withStyles(styles)(Exercises));
Exercises.contextType = UserStateContext;