import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Group as GroupIcon,
  LibraryBooks as LibraryIcon,
  ArrowBack as ArrowBackIcon,
  Accessibility as AccessibilityIcon,
  Person as PersonIcon,
  LibraryAdd,
  Keyboard as KeyboardIcon,
  Home as HomeIcon,
  GroupWork,
  SupervisorAccount,
  AccountTree,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useTranslation } from "react-i18next";
import { useUserState } from "../../context/UserContext";
import { hasRole, ROLE_ADMIN } from "../../utils/UserUtils";

const structure = [
  { id: 99, label: "dashboard.title", link: "/app/dashboard", icon: <HomeIcon />, roles : [ROLE_ADMIN] },
  { id: 100, label: "patients.titleAdmin", link: "/app/patients/list", icon: <AccountTree />, roles : [ROLE_ADMIN] },
  { id: 101, label: "patients.title", link: "/app/patients", icon: <GroupIcon /> },
  { id: 104, label: "exercises.title", link: "/app/exercises", icon: <AccessibilityIcon /> },
  { id: 112, label: "articles.title", link: "/app/articles", icon: <LibraryIcon /> },
  { id: 113, label: "templates.title", link: "/app/templates", icon: <LibraryAdd /> },
  { id: 114, label: "dictionaries.title", link: "/app/dictionaries", icon: <KeyboardIcon /> },
  { id: 115, type: "divider" },
  { id: 116, label: "users.title", link: "/app/users", icon: <PersonIcon />, roles : [ROLE_ADMIN] },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  var userState = useUserState();
  var user = userState.user;


  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (!link.roles || (link.roles && hasRole(link.roles, user)) ? (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ) : (<></>)))
        }
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
