export const USER_DEFAULT_TYPE_TEMPLATE="TEMPLATE";

export const USER_DEFAULT_NAME_PRIVATE="PRIVATE";


export function getUserDefault(name, user){
  //console.log('Get User defaults searching for :' + name + ' in : ' + JSON.stringify(user.defaults))
  if (user && user.defaults){
    for (let i = 0 ; i < user.defaults.length; i++){
      if (user.defaults[i].name === name){
        return user.defaults[i];
      }
    }
  }
  return null;
}