import React from "react";
import { Button, Grid } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer +" "+ props.className}>
      <Grid item xs={8}>
        <Typography className={classes.typo} variant="h1" size="sm">
          {props.title}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.gridItemButtons}>
      {props.children}
      {props.button && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="primary"
          onClick={props.onButtonClick}
        >
          {props.button}
        </Button>
      )}
      </Grid>
    </div>
  );
}
