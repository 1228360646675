const styles = theme => ({
  root: {

  },

  closeButton : {
    cursor : "pointer"
  },

  textArea : {
    width : "100%"
  },

  cardButtonBlock : {
    textAlign : "right",
    paddingTop: 5
  },

  coverImage: theme.coverImage,

  articleGeneral : theme.showHtmlText,
});

export default styles;