import React from "react";
import {
  Button,
  CircularProgress,
  Grid, IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import {
  URL_CARD_BLOCK_DELETE, URL_PRIVATE_CARD_BLOCK_DELETE,
  URL_PRIVATE_CARD_GENERAL_UPDATE, URL_PRIVATE_CARD_ORDER_UPDATE,
} from "../../../../utils/UrlConstants";
import { API } from "../../../../services/AuthService";
import ErrorContainer from "../../../../components/ErrorContainer";
import Widget from "../../../../components/Widget";
import ToastContainerCustom, { sendNotification } from "../../../../components/ToastContainerCustom/ToastContainerCustom";
import GeneralEditor from "../../../../components/GeneralEditor/GeneralEditor";
import * as Icons from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import CreatePrivateSectionFormDialog
  from "../../../../components/FormDialog/CreateSectionFormDialog/CreatePrivateSectionFormDialog";
import Tooltip from "@material-ui/core/Tooltip";
import DraggableTabPanel from "../../../../components/DraggableTabPanel/DraggableTabPanel";
import { Typography } from "../../../../components/Wrappers";

const styles = useStyles;

class PrivateSection extends React.Component {

  constructor(props) {
    super(props);

    //console.log('PROPS ' + JSON.stringify(props.match));
    //match.params.patientId

    this.state = {
      debug : false,
      entity : 'patients',
      data : { fullName : ''},
      urlUpdateGeneral : URL_PRIVATE_CARD_GENERAL_UPDATE,
      urlDeleteBlock : URL_PRIVATE_CARD_BLOCK_DELETE,
      urlUpdateOrder : URL_PRIVATE_CARD_ORDER_UPDATE,
      isLoading : true,
      error : false,
      isOpenSelectExercise :  false,
      isOpenSelectArticle :  false,
      isOpenAddSection : false,
      isOpenDeletePublicBlock : false,
      activeTabId : 0,
      openUpdateSection : null
    };

    this.onAddSection = this.onAddSection.bind(this);
    this.onCloseAddSection = this.onCloseAddSection.bind(this);
    this.onCreatedNewSection = this.onCreatedNewSection.bind(this);

    this.onUpdateGeneral = this.onUpdateGeneral.bind(this);

    this.openDeletePublicBlockDialog = this.openDeletePublicBlockDialog.bind(this);
    this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);
    this.onDeletePublicBlock = this.onDeletePublicBlock.bind(this);

    this.setActiveTabId = this.setActiveTabId.bind(this);
    this.onChangeOrderTabs = this.onChangeOrderTabs.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentDidMount() {
    this.selecteLastTab();
  }

  loadData(callBackFunction = null){
    if (this.props.onReload){
      this.props.onReload(callBackFunction);
    }
  }

  onAddSection(){
    this.setState({isOpenAddSection : true})
  }

  onCloseAddSection(){
    this.setState({isOpenAddSection : false})
  }

  onCreatedNewSection(){
    let {data} = this.props;
    this.setState({isOpenAddSection : false, activeTabId : 0})
    this.loadData(()=> {
      this.selecteLastTab()
    });
  }

  selecteLastTab(){
    let {data} = this.props;

    try {
      console.log('selecteLastTab - start');
      let lastItemIndex = data.card.cardPrivateBlocks.length - 1;
      console.log('selecteLastTab - lastItemIndex ' + lastItemIndex);
      let block = data.card.cardPrivateBlocks[lastItemIndex];
      console.log('selecteLastTab - block : ' + JSON.stringify(block));
      if (block){
        console.log('selecteLastTab '+block.id + " at position : " + (data.card.cardPrivateBlocks.length - 1))

        this.setActiveTabId(lastItemIndex)

      } else {
        console.error("selecteLastTab Impossible to retrieve last tab")
      }
    } catch (e){
      console.error("selecteLastTab - exception : "+e)
    }


  }

  getBlockId(){
    const {data, isLoading} = this.props;
    const {error, activeTabId} = this.state;
    return (!isLoading && !error && data.card.cardPrivateBlocks[activeTabId]) ? data.card.cardPrivateBlocks[activeTabId].id : null;
  }

  onUpdateGeneral(blockId, content){
    const {t,data, autoSave } = this.props;
    const {urlUpdateGeneral} = this.state;

    let id = data.card.id;
    //let blockId = this.getBlockId();
    if (blockId == null){
      sendNotification(t('patients.detail.general.updateFail'), "error");
    } else {

    let urlWithParam = urlUpdateGeneral.replace(":cardId", id).replace(":blockId", blockId);

      console.log('Updating general-card with id : '+id+' and block id : '+blockId);
      API().put(urlWithParam, { description : content })
        .then(res => {
          if (!autoSave){
            sendNotification(t('patients.detail.general.updateOk'), "success");
          }
          this.loadData();
        }).catch(e => {
          console.log('On get exception '+JSON.stringify(e));
          sendNotification(t('patients.detail.general.updateFail'), "error");
      })
    }
  }

  openDeletePublicBlockDialog(){
    this.setState({isOpenDeletePublicBlock : true});
  }

  onCloseDeleteDialog(){
    this.setState({isOpenDeletePublicBlock : false});
  }

  onDeletePublicBlock() {
    const {t, data} = this.props;
    const {urlDeleteBlock} = this.state;

    this.setState({isOpenDeletePublicBlock : false, activeTabId: 0})

    let blockId = this.getBlockId();
    if (blockId == null){
      console.log('block id is null');
      sendNotification(t('patients.detail.privateBlock.delete.fail'), "error");
    } else {

      let urlWithParam = urlDeleteBlock.replace(":cardId", data.card.id).replace(":blockId", blockId);

      API().delete(urlWithParam)
        .then(res => {
          sendNotification(t('patients.detail.privateBlock.delete.ok'), "success");
          this.loadData();
        }).catch(e => {
        console.log('On get exception ' + JSON.stringify(e));
        sendNotification(t('patients.detail.privateBlock.delete.fail'), "error");
      })
    }
  }

  setActiveTabId(tabId){
    console.log('Switch Tab ' + tabId);
    this.setState({activeTabId : tabId, openUpdateSection : null});
  }

  onChangeOrderTabs(item, idx1, idx2) {
    const {urlUpdateOrder, activeTabId} = this.state;
    const {t, data} = this.props;

    console.log('Dropped Change : ' + JSON.stringify(item) + " idx1 : " + idx1 + " idx2 : " + idx2);

    if (idx1 == activeTabId){
      this.setState({activeTabId : idx2})
    }

    let id = data.card.id;
    let urlWithParam = urlUpdateOrder.replace(":cardId", id);
    API().put(urlWithParam, { item1 : idx1, item2 : idx2 })
      .then(res => {
        this.loadData();
      }).catch(e => {
      console.log('On get exception '+JSON.stringify(e));
      sendNotification(t('errors.save'), "error");
    })
  }


  render() {
    const { t, classes, data, isLoading, autoSave } = this.props;
    const {error, entity, isOpenAddSection, activeTabId, isOpenDeletePublicBlock, openUpdateSection} = this.state;

    let name = ""
    if (!isLoading && !error) {
      name = data.fullName;
    }

    let block = !isLoading && !error ? data.card.cardPrivateBlocks[activeTabId] : null;


    return (
      <>

            { error ? <ErrorContainer msg={t('errors.loadData')} /> : (
              <>
                {isLoading ? (
                  <CircularProgress size={26}/>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <Widget
                        title={t(entity+'.detail.privateInfoTitle')}
                        subTitle={t(entity+'.detail.privateInfoSubTitle')}
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu = {true}
                        leftBorder = "orange"
                        preTitle={(<Icons.Block style={{color : "orange"}}/>)}
                      >

                        <DraggableTabPanel id="private"
                                           blocks={data.card.cardPrivateBlocks}
                                           onChange={this.setActiveTabId}
                                           activeTabId={activeTabId}
                                           onChangeActiveTab={this.setActiveTabId}
                                           onChangeOrder={this.onChangeOrderTabs}
                                           onAdd={this.onAddSection}
                                           addTooltip={t(entity+".detail.privateInfoAddSubTitle")}
                        />


                        {(!block) ? (
                          <Grid container spacing={4}>
                            <Grid item xs={12}>
                              <Widget
                                subTitle={t(entity+".detail.privateInfoAddSubTitle")}
                                upperTitle
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu = {true}
                                button={t(entity+".detail.privateInfoAddButton")}
                                onButtonClick={this.onAddSection}
                              >
                              </Widget>
                            </Grid>
                          </Grid>
                        ) : (<></>)}

                        {block? (
                          <Grid container spacing={4}>

                            <Grid item xs={12}>
                              <Widget
                                title={t(entity+'.detail.privateBlock.general.title') + " : "}
                                postTitle={(<b>{block.title} {(block && block.canEdit) ? (
                                  <Tooltip title={t(entity+".detail.privateInfoRemoveSubTitle")}>
                                    <IconButton className={classes.iconButton} onClick={() => {this.setState({openUpdateSection : 1})}}>
                                      <Icons.Delete className={classes.editIcon} />
                                    </IconButton>
                                  </Tooltip>
                                ):(<></>)}</b>)}
                                upperTitle
                                bodyClass={classes.fullHeightBody}
                                className={classes.card}
                                disableWidgetMenu = {true}
                              >
                                <Grid container spacing={4}>

                                  {(!block || openUpdateSection === 0) ? (
                                      <Grid item xs={12}>
                                        <Widget
                                          subTitle={t(entity+".detail.privateInfoAddSubTitle")}
                                          upperTitle
                                          bodyClass={classes.fullHeightBody}
                                          className={classes.card}
                                          disableWidgetMenu = {true}
                                          button={t(entity+".detail.privateInfoAddButton")}
                                          onButtonClick={this.onAddSection}
                                        >
                                        </Widget>
                                      </Grid>
                                  ) : (<></>)}

                                  {
                                    (block && openUpdateSection === 1) ? (
                                      <>
                                          <Grid item xs={12}>
                                            <Widget
                                              subTitle={t(entity+".detail.privateInfoRemoveSubTitle")}
                                              upperTitle
                                              bodyClass={classes.fullHeightBody}
                                              className={classes.card}
                                              disableWidgetMenu = {true}
                                              button={t(entity+".detail.privateInfoRemoveButton") + " : '" + block.title+"'"}
                                              onButtonClick={this.openDeletePublicBlockDialog}
                                              buttonColor="secondary"
                                            >
                                            </Widget>
                                            <Dialog
                                              open={isOpenDeletePublicBlock}
                                              onClose={this.onCloseDeleteDialog}
                                              aria-labelledby="alert-dialog-title"
                                              aria-describedby="alert-dialog-description"
                                            >
                                              <DialogTitle id="alert-dialog-title">{t(entity+'.detail.block.delete.title')}</DialogTitle>
                                              <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                  {t(entity+'.detail.block.delete.description')}
                                                </DialogContentText>
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={this.onCloseDeleteDialog}>
                                                  {t('buttons.cancel')}
                                                </Button>
                                                <Button onClick={this.onDeletePublicBlock} color="primary" autoFocus>
                                                  {t('buttons.delete')}
                                                </Button>
                                              </DialogActions>
                                            </Dialog>
                                          </Grid>
                                      </>
                                    ) : (<></>)
                                  }

                                  {(openUpdateSection != null) ? (
                                      <Grid item xs={12} style={{textAlign: "right"}}>
                                        <Button variant="outlined" color="primary" size="small" onClick={() => {this.setState({openUpdateSection : null})}}>
                                          {t('buttons.hide')}
                                        </Button>
                                      </Grid>
                                  ) : (<></>)}



                                  {block.canEdit ? (
                                    <GeneralEditor key={"private-block-section-"+block.id}
                                                   data={block.general}
                                                   onSave={(content) => {this.onUpdateGeneral(block.id, content)}}
                                                   placeholder={t('placeholders.addText')}
                                                   autoSave={autoSave}
                                                   activeOverlay={true}
                                                   overlayedTitle={(<Typography size="md"><b>{name}</b> ({block.title})</Typography>)}
                                    />
                                  ) : (
                                    <div style={{margin: "20px"}} dangerouslySetInnerHTML={{ __html: block.general }} className={classes.privateBlockGeneral}/>
                                  )}
                                </Grid>
                              </Widget>
                            </Grid>


                          </Grid>
                        ) : (
                          <div></div>
                        )}

                      </Widget>
                    </Grid>

                  </>
                )
                }
              </>
            ) }
        <CreatePrivateSectionFormDialog open={isOpenAddSection} onClose={this.onCloseAddSection} onSubmit={this.onCreatedNewSection} cardId={(data && data.card) ? data.card.id : null} />
        <ToastContainerCustom />
      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(PrivateSection));

