import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DataTable from "../DataTable";

const styles = useStyles;

class SelectionDialog extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      enableButton : false,
      rowsSelected : []
    };
    this.baseState = this.state;
    this.onClose = this.onClose.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onRowsSelect = this.onRowsSelect.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.open === true && this.props.open === false) {
      //console.log('reopening');
      this.setState(this.baseState);
    }
  }

  onClose() {
    const { onClose } = this.props;
    onClose();
  }

  onSelect(){
    const {rowsSelected} = this.state;
    const {configuration} = this.props;
    const {selectableRows} = configuration;

    if (this.props.onSelect){
      if (selectableRows === "single"){
        this.props.onSelect(rowsSelected[0]);
      } else {
        this.props.onSelect(rowsSelected);
      }
    }
  }

  onRowsSelect(rowsSelected){
    console.log('rowsSelected : ' + JSON.stringify(rowsSelected));
    this.setState({
      rowsSelected : rowsSelected,
      enableButton : (rowsSelected.length > 0)
    })
  }

  render (){

    const { t, open, onClose, configuration } = this.props;
    const {entity, apiGet, columns, selectableRows, notIds} = configuration;
    const { enableButton} = this.state;

    return (
      <>
        <Grid container spacing={4}>
          <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" disableBackdropClick={true} fullWidth={true} maxWidth="xl">
            <DialogTitle id="form-dialog-title">{t(entity+'.select.title')}</DialogTitle>
            <DialogContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <DataTable entity={entity} api={apiGet} columns={columns} selectableRows={selectableRows} disableToolbarSelect={true} onRowsSelect={this.onRowsSelect} notId={notIds} selectedEntireItem={true}/>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={onClose}>
                {t("buttons.cancel")}
              </Button>
              <Button onClick={this.onSelect} color="primary" disabled={!enableButton}>
                <div>{t("buttons.select")}</div>
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        </>
    );

  }
}

export default withTranslation()(withStyles(styles)(SelectionDialog));

SelectionDialog.defaultProps = {
  configuration : {
    entity : 'entity',
    apiGet : '',
    columns : [],
    selectableRows : "single",
    notIds : []
  }
}