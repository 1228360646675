import React from "react";
import { API } from "../../../../services/AuthService";
import { getRandomKey } from "../../../../utils/ReactUtils";

class DashboardDataWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data : null,
      loading : true,
      error : null,
      loadGeneratedKey : getRandomKey()
    };

    this.loadData = this.loadData.bind(this);

  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch (DashboardDataWidget)');
  }

  componentDidMount() {
    console.log('MOUNT DashboardDataWidget. Api Call prepare (loadData)');
    this.loadData();
  }

  loadData(generateNewKey = true){
    const {url} = this.props;
    const {loadGeneratedKey} = this.state;

    console.log('DashboardDataWidget is Loading from api : ' + url)

    API().get(url)
      .then(res => {
        const data = res.data;
        console.log('RESPONSE : ' +JSON.stringify(res.data))

        this.setState({
          data : data,
          loading: false,
          error: false,
          loadGeneratedKey : generateNewKey ? getRandomKey() : loadGeneratedKey
        });
      }).catch(e => {
      console.error(e);
      console.log('DashboardDataWidget On get exception '+JSON.stringify(e));
      this.setState({ error : true, isLoading: false });
    })

  }

  render() {
    let {data, loading, error, loadGeneratedKey} = this.state;
    let {component = null} = this.props;

    return React.cloneElement(component, { data, loading, error, key : loadGeneratedKey })
  }
}

export default DashboardDataWidget;