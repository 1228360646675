import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

  htmlPreviewBox : {
    maxWidth: "50vw",
    padding : "10px",
    border :"1px solid #eee",
    overflow : "hidden"
  },

  contentMaxHeight : {
    padding : "10px",
    border :"1px solid #eee",
    overflow : "hidden",
    maxHeight : '10em',
    '@media (min-width:1200px)': {
      maxHeight : '10em',
    },
    '@media (min-width:1800px)': {
      maxHeight : '10em',
    },
    '@media (min-width:2600px)': {
      maxHeight : '25em',
    },
  }



}));

