import React from "react";

const logContext="[UIUtils] - ";

export function uiScrollTo(identifier){
    console.log('scrolling into ' + identifier)
    document.getElementById(identifier).scrollIntoView();
}

export function valueFromId(opts, id) {
    return opts ? opts.find(o => o && (o.value === id)) : [];
}

export function selectValuesOrder(values){
    values.sort( (a,b) => {
        if (a.label.toUpperCase() < b.label.toUpperCase()){
            return -1;
        }
        if ( a.label.toUpperCase() > b.label.toUpperCase() ){
            return 1;
        }
        return 0;
    } );

    return values;
}

export function stopEvent(e){
    if (e){
        e.preventDefault();
        e.stopPropagation();
    }
}

export function makeTextBold(text, color = 'red', bold=false, underlined = true){

    let style = "color:"+color;
    if (bold){
        style = style + ";font-weight:bold"
    }
    if (underlined){
        style = style + ";text-decoration:underline"
    }

    return "<span style='"+style+"'>"+text+"</span>"
}

export function makeBoldKeywords (text, keywords) {
    let retValue = text;
    keywords.map((currKeyword) => {
        let re = new RegExp(currKeyword, 'g')
        retValue = retValue.replace(re, '<b>'+currKeyword+ '</b>');
    })
    return (
        <span dangerouslySetInnerHTML={{__html: retValue}} >
        </span>

    );

}

export function scrollHorizontalContentToItemIndex(itemIndex, itemSize, refContainerScrollable){
    //console.log(logContext+"Scrolling to item : " + itemIndex)
    // Ensure the container and item refs are defined
    if (refContainerScrollable.current) {
        //console.log(logContext+"Total items are : " + items.length)
        if (itemIndex >= 0) {
            //console.log(logContext+"Calculating the position")
            // Calculate the position to scroll to
            const scrollX = itemSize*(itemIndex);
            //console.log(logContext+"Scroll X for item ("+itemIndex+"). offset left -> "+JSON.stringify(scrollX))
            // Scroll the container to the target item
            refContainerScrollable.current.scrollTo({
                left: scrollX,
                behavior: 'smooth', // Optional: smooth scrolling
            });
        }
    }
}

export function scrollVerticalSectionToBottom(refContainerScrollable){
    const scrollHeight = refContainerScrollable.current.scrollHeight;
    const height = refContainerScrollable.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    refContainerScrollable.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
}