import React from "react";
import { URL_AUTH } from "../services/UrlConstants";
import { API } from "../services/AuthService";
import { URL_USER_ME_GET } from "../utils/UrlConstants";
import { cleanCache } from "../utils/LocalstorageCache";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      console.log('LOGIN SUCCESS');
      return { ...state, isAuthenticated: true, user : action.user };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false, user : null };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false, user : null};
    case "ALREADY_LOGGED":
      return { ...state, isAuthenticated: true, user : action.user };
    case "REFRESH":
      return { ...state, isAuthenticated: true, user : action.user };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    user : null
  });

  console.log('RELOAD USER PROVIDER')


  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, reloadUser, UserStateContext };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {

    API(false).post(URL_AUTH+"?password="+password+"&username="+login, {}
    ).then(result => {
      console.log('On login result');
      if (result.status === 200) {

        // clean old entries from cache
        cleanCache()

        localStorage.setItem('id_token', result.data)

        reloadUser(dispatch)

      } else {
        console.log('Login error');
        setError(true);
        setIsLoading(false);
        //dispatch({ type: "LOGIN_FAILURE" });
      }
    }).catch(e => {
      console.log('On login exception');
      setIsLoading(false);
      setError(true);
      //dispatch({ type: "LOGIN_FAILURE" });
    });

  } else {
    console.log('On login missed data');
    //dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  if (dispatch) dispatch({ type: "SIGN_OUT_SUCCESS" });
  if (history) history.push("/login");
}

function reloadUser(dispatch){
  var token = localStorage.getItem("id_token");
  if (token){
    API().get(URL_USER_ME_GET)
      .then(result => {
        console.log('USER DATA FROM API : '+JSON.stringify(result));
        dispatch({ type: 'REFRESH', user : result.data })
      })
  }
}
