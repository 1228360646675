const styles = theme => ({
  root: {

  },

  closeButton : {
    cursor : "pointer"
  },

  textArea : {
    width : "100%"
  },

  cardButtonBlock : {
    textAlign : "right",
    paddingTop: 5
  },
  cardArticle : {
    [theme.breakpoints.down('sm')]: {
      padding: "20px 0px !important",
    },
  },
  coverImage: theme.coverImage
});

export default styles;