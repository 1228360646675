import moment from "moment";

export const DEFAULT_FORMAT = 'DD/MM/YYYY HH:mm:ss'
export const SORT_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const DATETIME_FORMAT = 'DD/MM/YYYY'

export function convert(inputUTCString, format=DEFAULT_FORMAT){
  var localTime  = moment.utc(inputUTCString).toDate();
  return moment(localTime).format(format);
}

export function nowString(format = DEFAULT_FORMAT){
  return moment().format(format);
}

export function calculateAge(date, format = DATETIME_FORMAT){
  if (date) {
    let now = moment()
    let birthDate = moment(date, format);
    return now.diff(birthDate, 'y');
  }
  return null;
}

