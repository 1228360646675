import tinycolor from "tinycolor2";

const primary = "#45B4A3";
const primaryTransparent = "#45B4A3CC";
const secondary = "#FF5C93";
const error = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const textHint = "#B9B9B9";
const testSizeHint = "15px";

const lightenRate = 7.5;
const darkenRate = 15;


export default {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    primaryTransparent: {
      main: primaryTransparent,
      light: tinycolor(primaryTransparent)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primaryTransparent)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    error: {
      main: error,
      light: tinycolor(error)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(error)
        .darken(darkenRate)
        .toHexString(),
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
      white : "#FFFFFF",
      success : success
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
        padding:"5px 15px"
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    MuiTab : {
      root : {
        borderBottom: "1px solid #eee",
        marginRight: "10px",
        "&:hover": {
          borderBottom: "1px solid "+primaryTransparent,
        },
      },
      wrapper : {
        flexDirection: "row"
      }
    },
    MuiTypography : {
      body1 : {
        alignItems: 'center',
        display: 'flex',
        fontSize : '14px',
        '@media (min-width:1200px)': {
          fontSize: '14px',
        },
        '@media (min-width:1800px)': {
          fontSize: '15px',
        },
        '@media (min-width:2600px)': {
          fontSize: '17px',
        },
      },
      h4 : {
        alignItems: 'center',
        display: 'flex',
        fontSize : '14px',
        '@media (min-width:1200px)': {
          fontSize: '14px',
        },
        '@media (min-width:1800px)': {
          fontSize: '15px',
        },
        '@media (min-width:2600px)': {
          fontSize: '20px',
        },
      },
      h5 : {
        alignItems: 'center',
        fontWeight:500,
        display: 'flex',
        fontSize : '15px',
        '@media (min-width:1200px)': {
          fontSize: '15px',
        },
        '@media (min-width:1800px)': {
          fontSize: '16px',
        },
        '@media (min-width:2600px)': {
          fontSize: '20px',
        },
      },
      h6 : {
        alignItems: 'center',
        fontWeight:400,
        display: 'flex',
        fontSize : '14px',
        '@media (min-width:1200px)': {
          fontSize: '14px',
        },
        '@media (min-width:1800px)': {
          fontSize: '15px',
        },
        '@media (min-width:2600px)': {
          fontSize: '17px',
        },
      }
    },
    MuiButton : {
      containedSizeLarge : {
        fontSize : '13px',
        '@media (min-width:1200px)': {
          fontSize: '13px',
        },
        '@media (min-width:1800px)': {
          fontSize: '14px',
        },
        '@media (min-width:2600px)': {
          fontSize: '16px',
        },
      }
    },
    MuiDialogTitle: {
      root: {
        // Target the h2 element specifically
        '& h2': {
          fontWeight: 'bold', // Make the title bold
          fontSize: '1.5rem', // Increase the font size
        },
      },
    },
  },
  coverImageThumb: {
    width : "150px",
    height: "150px",
    objectFit:"contain",
    backgroundColor: "#f9f9f9"
  },
  coverImage: {
    objectFit:"contain",
    backgroundColor: "#f9f9f9"
  },
  infoLabel: {
    color: textHint,
    fontSize: testSizeHint
  },
  successLabel: {
    color: success,
    fontSize: testSizeHint
  },
  showHtmlText : {
    padding: "20px",
    border : "1px solid #eee",
    width: "100%"
  },
  buttonRow : {
    marginRight: "5px"
  },

};
