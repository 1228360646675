import { DATETIME_FORMAT } from "./DateUtils";
import moment from "moment";

export const IMAGE = 1;
export const REQUIRED_INPUT = 2;
export const REQUIRED_INPUT_HTML = 3;
export const REQUIRED_CHECKBOX = 4;
export const REQUIRED_EMAIL = 5;
export const REQUIRED_URL = 6;
export const URL = 7;
export const REQUIRED_OPTION = 8;
export const INPUT_TEXTAREA = 9;
export const EMAIL = 10;
export const DATE = 11;
export const CHECKBOX = 14;
export const PHONE = 15;
export const CUSTOM = 99;


const debug = true;

export function checkRichEditor(content) {
  if (content !== undefined) {
    // eslint-disable-next-line
    const withImages = content.replace(/<img\s[^>]*?src\s*=\s*['\"]([^'\"]*?)['\"][^>]*?>/g, '<p>img</p>');
    const noTags = withImages.replace(/<[^>]*>/g, '');
    const noSpaces = noTags.replace(/&nbsp;/g, '');
    const clean = noSpaces.replace(/\n/g, '');
    if (clean && clean.trim()) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function handleValueChange(value, field, component, formValidation) {
  let formWithNewValues = { ...component.state.form, [field]: value };
  component.setState({ form: formWithNewValues }, () => checkSingleFormValue(formValidation, formWithNewValues, field, false));
}

export function handleInputChange(event, component, id=null) {
  if (debug) console.log('handleInputChange event -> id : ' + event.target.id + " value : " + event.target.value + " component : " + component + " id : " + id);
  let fieldName = event.target.id;
  if (id != null){
    fieldName = id;
  }
  let fleldVal = event.target.value;
  let formWithNewValues = { ...component.state.form, [fieldName]: fleldVal };

  let formValidation = component.getFormValidation ? component.getFormValidation() : component.state.formValidation;

  component.setState({ form: formWithNewValues }, () => {
    let valid = checkSingleFormValue(formValidation, formWithNewValues, fieldName);
    let {formError} = component.state;
    if (debug) console.log('valid '+JSON.stringify(valid));
    formError[fieldName] = !valid;
    if (debug) console.log('formError '+JSON.stringify(formError));
    component.setState({formError});
  });
}

export function handleCheckboxChange(event, component, formValidation) {
  if (debug) console.log('handleCheckboxChange event -> id : ' + event.target.id + " value : " + event.target.checked + " component : " + component);
  let fieldName = event.target.id;
  let fieldVal = event.target.checked;
  let fieldCheckbox = getCheckbox(formValidation, fieldName);
  let formWithNewValues = { ...component.state.form, [fieldName]: fieldVal };
  component.setState({ form: formWithNewValues }, () => checkSingleFormValue(formValidation, formWithNewValues, fieldCheckbox, false));
}

export function handleSelectChange(event, component, formValidation) {
  // console.log('handleSelectChange event -> id : ' + event.target.id + " value : " + event.target.value);
  let fieldName = event.target.id;
  let fieldVal = event.target.value;
  let formWithNewValues = { ...component.state.form, [fieldName]: fieldVal };
  component.setState({ form: formWithNewValues }, () => checkSingleFormValue(formValidation, formWithNewValues, fieldVal, false));
}


export function handleCheckNumberChange(event, component, formValidation, specificField) {
  let fieldName = event.target.id;
  let fieldVal = event.target.value;
  // console.log('before filter : ' + fieldVal);
  let valFiltered = fieldVal.replace(/\D/g, '');
  // console.log('field: ' + fieldName + " value : " + valFiltered);
  let formWithNewValues = { ...component.state.form, [fieldName]: valFiltered };
  component.setState({ form: formWithNewValues }, () => checkSingleFormValue(formValidation, formWithNewValues, specificField, false));
}

export function getCheckbox(formValidation, field) {
  let found = null;
  Object.keys(formValidation).forEach(function (key) {
    // console.log('key : ' + key);
    let currItem = formValidation[key];
    // console.log('currItem : ' + currItem);
    // console.log('currItem.checkbox : ' + currItem.checkbox);
    if (currItem.checkbox !== undefined) {
      // console.log('included : ' + currItem.checkbox.includes(field));
      if (currItem.checkbox.includes(field)) {
        // console.log('getCheckbox -> found ' + key + " for checkbox : " + field);
        found = key;
      }
    }
  });
  // console.log("Checkbox field : " + found);
  return found;
}

export function checkForm(formValidation, form) {
  let validForm = true;

  Object.keys(formValidation).forEach(function (key) {
    let valid = checkSingleFormValue(formValidation, form, key);
    if (!valid) {
      validForm = false;
    }
  });

  return validForm;
}

export function getFormValidation(formValidation, form) {
  let errors = {};

  Object.keys(formValidation).forEach(function (key) {
    let valid = checkSingleFormValue(formValidation, form, key);

    if (!valid) {
      errors[key] = !valid;
    }
  });

  return errors;
}

export function checkSingleFormValue(formValidation, form, what) {
  let debug = false;

  if (debug) console.log('checkSingleFormValue : ' + what);
  if (what == null) {
    if (debug) console.log('ERROR checkSingleFormValue - what is null : ' + what);
    return false;
  }
  let validItem = formValidation[what];
  if (validItem != null) {
    let selector = '#' + what;
    let type = validItem.type;
    // console.log("sdfdsfdfdsffdsffffff : " + validItem.min + " " + what);
    let opt = { min: validItem.min };
    if (validItem.selector !== undefined) {
      selector = validItem.selector;
    }
    let selectorLabel = selector + 'Error';
    if (validItem.selectorLabel !== undefined) {
      selectorLabel = validItem.selectorLabel;
    }

    let value = form[what];
    if (type === REQUIRED_CHECKBOX) {
      value = [];
      let arrayLength = validItem.checkbox.length;
      for (let i = 0; i < arrayLength; i++) {
        //Do something
        let currCheckbox = validItem.checkbox[i];
        if (form[currCheckbox]) {
          // console.log("Selected checkbox : " + currCheckbox);
          value.push(currCheckbox);
        }
      }
    } else if (type === CUSTOM) {
      value = validItem.method();
    } else if (type === DATE) {
      opt = {format: validItem.format};
    }

    return checkValue(value, type, opt, selector, selectorLabel);
    // console.log('checkSingleFormValue - field ' + what + " valid : " + valid +" scroll if error : " + scroll + " selector : " + selector + " selectorLabel : " + selectorLabel);
  } else {
    // console.log('ERROR checkSingleFormValue - not found what : ' + what);
    return false;
  }

}

export function checkValue(value, type, opt, selector = '', selectorLabel = '') {
   //console.log('check value ' + value + " type check : " + type + " selector : " + selector + " selectorLabel : " + selectorLabel);

  if (type === IMAGE) {
    if (value === null || value === -1) {
      // console.log('ERROR check value ' + value + " type check : " + type + " selector : " + selector);
      return false;
    } else {
    }
  }

  if (type === EMAIL){
    if (value !== null && value !== ''){
      if (validateUrl(value)) {
      } else {
        return false;
      }
    }
  }

  if (type === PHONE){
    if (value !== null && value !== ''){
      if (validatePhoneNumber(value)) {
      } else {
        return false;
      }
    }
  }

  // check not empty fields
  if (type === REQUIRED_INPUT) {
    if (value !== null && typeof value === 'number') {
    } else {
      if (value == null || value === '' || value.trim() === '') {
        //console.log("--------------null : " + JSON.stringify(opt));
        return false;
      } else {
         //console.log("--------------options : " + JSON.stringify(opt));
        if (opt != null && opt.min != null) {
          if (value.length < opt.min) {
            // console.log('selector min ' + selectorLabel);
            return false;
          }
        }
      }
    }
  }

  // check email fields
  if (type === REQUIRED_EMAIL) {
    if (value === null || value === '' || value.trim() === '') {
      return false;
    } else {
      if (validateEmail(value)) {
      } else {
        return false;
      }
    }
  }

  // check email fields
  if (type === REQUIRED_OPTION) {
    if (value === null || value === '' || value.trim() === '') {
      return false;
    } else {
      return true;
    }
  }

  // check url fields
  if (type === REQUIRED_URL) {
    if (value == null || value === '' || value.trim() === '') {
      return false;
    } else {
      if (validateUrl(value)) {
      } else {
        return false;
      }
    }
  }

  // check url fields
  if (type === URL) {
    if (value == null || value === '' || value.trim() === '') {
    } else {
      if (validateUrl(value)) {
      } else {
        return false;
      }
    }
  }

  //console.log('validating type ' + type + " opt " + JSON.stringify(opt))
  if (type === DATE){
    if (value == null || value === '' || value.trim() === '') {
    } else {
      let format = DATETIME_FORMAT;
      if (opt != null && opt.format != null) {
        format = opt.format;
      }
      //console.log('validating ' + value + " opt " + JSON.stringify(opt))
      if (validateDate(value, format)) {
      } else {
        return false;
      }
    }
  }

  // check not empty fields html
  if (type === REQUIRED_INPUT_HTML) {
    if (value == null || value === '' || value.trim() === '' || value.trim() === '<p></p>') {
      return false;
    } else {
    }
  }

  // check not empty fields html
  if (type === REQUIRED_CHECKBOX) {
    //console.log('checking checkbox - value '+ value)
    if (value == null) {
      return false;
    } else {
      // check at least one selected
      if (value.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (type === CUSTOM) {
    if (value) {
      return true;
    } else {
      return false;
    }
  }

  if (type === CHECKBOX) {
    //console.log('checking checkbox - value '+ value)
    if (value !== null) {

    } else {

    }
  }

  return true;
}


export function validateEmail(email) {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
}

export function validateUrl(str) {
  // eslint-disable-next-line
  let pattern2 = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
  return pattern2.test(str);
}

export function validatePhoneNumber(phoneNumber) {
  // Check if phoneNumber is not null or empty
  // Regular expression to match a phone number that may start with a + and contains digits
  const regex = /^\+?\d{8,}$/;

  return regex.test(phoneNumber);
}

export function validateDate(str, format = DATETIME_FORMAT){
  return moment(str, format,true).isValid()
}