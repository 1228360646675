import { convert } from "./DateUtils";
import React from "react";
import HtmlPreview from "../components/HtmlPreview/HtmlPreview";

export const TEMPLATE_TYPE_PRIVATE="PRIVATE";


export function getTemplateColumns(classes) {
  return [
    {
      name: 'title',
    },
    {
      name: 'type',
      options: {
        filter: true,
        filterOptions: {
          names: ['private', 'public']
        },
      }
    },
    {
      name: 'value',
      options: {
        customBodyRender: (value) => {
          return <HtmlPreview value={value}/>
        }
      }
    },
    {
      name: 'createdAt',
      options: {
        customBodyRender: (value) => {
          return convert(value)
        }
      }
    }
  ];
}