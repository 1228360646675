import axios from 'axios';

import { BASE_PATH } from "./UrlConstants";

import { signOut } from "../../src/context/UserContext";

export const API = function (auth=true) {
  const token = localStorage.getItem("id_token");

  let adapter =  axios.create({
    baseURL: BASE_PATH,
    timeout: 40000,
    validateStatus: function (status) {
      return status <= 201;
    }
  });

  if (auth){
    adapter.defaults.headers.common['Authorization'] = 'Bearer '+token;
  }

  adapter.interceptors.response.use(response => {
    return response;
  }, error => {
    console.log('ERROR custom behaviour ' + JSON.stringify(error));
    if (error.response.status === 401) {
      //place your reentry code
      console.log('NOT AUTHORIZED custom behaviour')
      signOut();
      window.location.reload();
    }
    return Promise.reject(error.response);
  });

  return adapter;
};