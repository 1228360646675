import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";

const styles = useStyles;

class GeneralEditorCustomOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showCreate: false,
      createText: "",
      showList : false,
    }
  }

  toggleModal(){
    this.setState({
      showCreate : !this.state.showCreate,
    })
  }




  togglePasteFromDictionaryShow(){
    console.log('Toggle dictionary list show')
    this.setState({
      showList : !this.state.showList
    })
  }

  render() {
    const { onCreate, onList, t} = this.props;

    return (
      <div>
        <div style={{display:"flex", alignItems:"center", flexDirection:"row"}}>
          <div className={"rdw-option-wrapper"} onClick={() => {onCreate()}}>
            {t('dictionaries.suggestions.add')}
          </div>
          <div className={"rdw-option-wrapper"} onClick={() => {onList()}}>
            {t('dictionaries.suggestions.paste')}
          </div>
        </div>

      </div>

    );
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(GeneralEditorCustomOptions));
