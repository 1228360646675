import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import {
  URL_TEMPLATES_CREATE, URL_TEMPLATES_DELETE,
  URL_TEMPLATES_GET, URL_TEMPLATES_GET_BY_ID, URL_TEMPLATES_UPDATE,
} from "../../utils/UrlConstants";
import * as formUtils from "../../utils/FormUtils";
import EntityManager from "../../components/EntityManager/EntityManager";
import { getTemplateColumns } from "../../utils/TemplateUtils";
import { hasRole, ROLE_ADMIN } from "../../utils/UserUtils";
import { UserStateContext } from "../../context/UserContext";

const styles = useStyles;

class Templates extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      error: false,
    };


  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  render() {
    let {classes, t} = this.props;
    let user = this.context.user;

    let configuration = {
      entity : 'templates',
      apiGet : URL_TEMPLATES_GET,
      apiGetById : URL_TEMPLATES_GET_BY_ID,
      apiDelete : URL_TEMPLATES_DELETE,
      apiCreate : URL_TEMPLATES_CREATE,
      apiUpdate : URL_TEMPLATES_UPDATE,
      columns : getTemplateColumns(classes),
      formValidation : {
        title: {
          type: formUtils.REQUIRED_INPUT,
        },
        type: {
          type: formUtils.REQUIRED_INPUT,
          select : [
            {id : "PRIVATE", label : t('patients.detail.tabs.PRIVATE')},
            //{id : "public", label : "Public something"}
          ],
          defaultValue : "private"
        },
        value : {
          type : formUtils.REQUIRED_INPUT,
          editor : true
        }
      },
      formApiMapper : (res) => {
        return {
          title : res.data.title,
          type : res.data.type,
          value : res.data.value,
        }
      },
      deleteRow : hasRole([ROLE_ADMIN], user)
    }

    return (
      <EntityManager configuration={configuration}  />
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Templates));
Templates.contextType = UserStateContext;