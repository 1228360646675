export function getDateTimezone(date){
  if (date instanceof Date){
    let dateOffset = date.getTimezoneOffset();
    let timeZoneString = "GMT";
    let hours = -1*(date.getTimezoneOffset() / 60);
    if (dateOffset < 0){
      timeZoneString=timeZoneString+"+";
    } else if (dateOffset > 0){
      timeZoneString=timeZoneString+"-";
    }
    timeZoneString = timeZoneString + ""+hours

    return timeZoneString;
  }
  return null;
}