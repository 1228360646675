import React from "react";
import {
  Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import MUIDataTable from "mui-datatables";
import { API } from "../../services/AuthService";
import ErrorContainer from "../../components/ErrorContainer";
import { sendNotification } from "../ToastContainerCustom/ToastContainerCustom";
import DataTableSelectToolbar from "./DataTableSelectToolbar";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = useStyles;

class DataTable extends React.Component {

  constructor(props) {
    super(props);

    let columns = props.columns;
    let columnDefault = columns[0].name;

    this.state = {
      items: {"content" : [], "totalElements" : 0},
      error: false,
      rowsPerPage : 100,
      page : 0,
      isLoading : true,
      sortComplete : "&sort="+columnDefault+",asc",
      columnSortDirection: this.getDefaultColumnsOrder(true),
      columnFilter : [],
      search : "",
      isOpenDeleteDialog : false,
      init : false,
      debug : false
    };

    // This binding is necessary to make `this` work in the callback
    this.onChangePage = this.onChangePage.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.loadDate = this.loadDate.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onRowsSelect = this.onRowsSelect.bind(this);
    this.onRowsDelete = this.onRowsDelete.bind(this);
    this.onRowsUpdate = this.onRowsUpdate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onCloseDeleteDialog = this.onCloseDeleteDialog.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }


  componentDidMount() {
    this.loadDate();
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.reload){
      if (nextProps.reload === true && this.props.reload === false) {
        this.setState({page : 0 }, () => {
          this.loadDate();
        })
      }
    }
  }


  loadDate(){
    let {api} = this.props;
    let {page, sortComplete, search, rowsPerPage, debug} = this.state;

    this.setState({isLoading : true, rowsSelected: []});
    let url = api + "?size="+rowsPerPage+"&page="+page+""+sortComplete;
    if (search !== null && search !== "") {
      url = url + "&search="+search;
    }
    let additionalFilters = this.getFilters();
    //console.log("additionalFilters : " + additionalFilters);
    if (additionalFilters !== null && additionalFilters !== "") {
      url = url + additionalFilters;
    }

    if (this.props.notId && this.props.notId.length > 0) {
      let concatIds = "";
      for (let i = 0 ; i < this.props.notId.length; i++){
        if (i > 0){
          concatIds = concatIds + ",";
        }
        concatIds = concatIds + this.props.notId[i];
      }
      url = url + "&not="+concatIds;
    }

    if (debug) console.log("onReloadData : " + url);
    API().get(url)
      .then(res => {
        const items = res.data;
        this.setState({ items : items, isLoading: false, error: false, init: true}, () => {
          if (this.props.onReload){
            this.props.onReload();
          }
        });
      }).catch(e => {
        console.log('On get exception '+JSON.stringify(e));
        this.setState({ error : true });
    })
  }

  getFilters(){
    let {columnFilter} = this.state;
    let {columns} = this.props;

    let retFilter = "";
    for(let i = 0 ; i < columnFilter.length; i++){
      //console.log('array -> '+JSON.stringify(columnFilter[i]));
      let currFilter = columnFilter[i];
      if (currFilter.length > 0){
        let columnName = columns[i].name;
        let values = "";
        for(let j = 0; j < currFilter.length; j++){
          if (j>0){
            values = values+",";
          }
          values = values + currFilter[j];
        }

        retFilter = "&"+columnName+"="+values
      }
    }

    return retFilter;
  }

  onChangePage(page){
    console.log('on change page');
    this.setState({page : page}, () => {
      this.loadDate();
    })
  }

  onRowClick(rowData, rowMeta){
    const {items} = this.state;
    //console.log("on click : " + JSON.stringify(rowData) + " rowMeta " + JSON.stringify(rowMeta) + " content : " + JSON.stringify(items.content));
    if (this.props.onRowClick){
      this.props.onRowClick(items.content[rowMeta.rowIndex]);
    }
  }

  onChangeRowsPerPage(data){
    let {debug} = this.state;

    if (debug) console.log("on onChangeRowPerPage : " + JSON.stringify(data));

    if (this.state.rowsPerPage !== data) {
      this.setState({rowsPerPage : data, page : 0}, () => {
        this.loadDate()
      })
    }
  }

  onSearch(searchQuery){
    //console.log("onSearch : " + JSON.stringify(searchQuery));
    if (this.state.search !== searchQuery){
      let time = 1000;
      if (searchQuery === "") {
        time = 0;
      }
      this.setState({search : searchQuery}, () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.loadDate, time);
      })
    }
  }

  getColumns(){
    let {columns, entity, t} = this.props;
    let {columnSortDirection, columnFilter} = this.state;

    let retColumns = [];

    for (let i = 0; i < columns.length; i++){
      let currColumn = columns[i];
      if (!currColumn.label){
        currColumn.label = t(entity+'.list.columns.'+currColumn.name);
      }
      if(!currColumn.options){
        currColumn.options = {};
      }
      if (!currColumn.options.filter){
        currColumn.options.filter = false
      } else {
        currColumn.options.filterList = columnFilter[i];
      }
      currColumn.options.sortDirection = columnSortDirection[i];
      retColumns.push(currColumn);
    }
    return retColumns;
  }

  getDefaultColumnsOrder(init=false){
    let {columns} = this.props;
    let ret = [];
    for (let i = 0; i < columns.length; i++) {
      if (init && i === 0) {
        ret.push("asc");
      } else {
        ret.push("none");
      }
    }
    return ret;
  }

  onSortChange (column, direction){
    let {debug} = this.state;

    if (debug) console.log("on onSortChange : " + JSON.stringify(column) + " direction " + direction);
    let directionShort = "";
    if (direction === "ascending"){
      directionShort = "asc";
    } else {
      directionShort = "desc";
    }

    let newColumnSortDirections = this.getDefaultColumnsOrder();
    let columns = this.getColumns();

    for (let i=0; i < columns.length; i++) {
      if (column === columns[i].name){
        newColumnSortDirections[i] = directionShort;
      }
    }

    this.setState({columnSortDirection: newColumnSortDirections, sortComplete : "&sort="+column+","+directionShort, page : 0}, () => {
      this.loadDate();
    })
  }

  onFilterChange(changedColumn, filterList){
    let {debug} = this.state;

    if (debug) console.log(changedColumn, filterList);
    this.setState({
      columnFilter : filterList,
      page : 0
    }, () => {
      this.loadDate();
    })
  }

  onRowsSelect(currentRowsSelected, allRows){
    const {items, debug} = this.state;
    const {selectedProperty, selectedEntireItem} = this.props;

    if (debug) console.log('currentRowsSelected : ' + JSON.stringify(allRows));
    let rowsSelected = allRows.map(row => row.dataIndex);
    //console.log('before set state row selected : ' + JSON.stringify(rowsSelected));
    this.setState ({rowsSelected : rowsSelected});

    // caller event
    if (this.props.onRowsSelect){
      // remap ids
      let retArray = [];
      for(let i = 0 ; i < allRows.length; i++){
        if (selectedEntireItem){
          retArray.push(items.content[allRows[i].index]);
        } else {
          retArray.push(items.content[allRows[i].index][selectedProperty]);
        }
      }
      if (debug) console.log("Selected array : " + JSON.stringify(retArray) + " filtering property : " + selectedProperty);
      this.props.onRowsSelect(retArray);
    }
  }

  onRowsDelete(){
    const {items, rowsSelected, debug} = this.state;
    const {t, entity} = this.props;

    if (debug) console.log('on rows delete with rowsSelected' + JSON.stringify(rowsSelected));


    if (this.props.apiDelete){
      if (debug) console.log('indexes are ' + rowsSelected.length);
      for(let i = 0; i < rowsSelected.length; i++){
        let itemId = (items.content[rowsSelected[i]]).id;
        if (debug) console.log('deleting id ' + itemId);
        let url = this.props.apiDelete.replace(':id', itemId);
        API().delete(url)
          .then(res => {
            this.setState({items : {"content" : [], "totalElements" : 0}, isOpenDeleteDialog : false})
            sendNotification(t(entity+".delete.ok"), "success");
            this.setState({rowsSelected : []}, () => {
              this.loadDate();
            })
          }).catch(e => {
          sendNotification(t(entity+".delete.fail"), "error");
        })
      }
    }

  }

  onRowsUpdate(){
    const {items, rowsSelected, debug} = this.state;
    const {t, entity} = this.props;

    if (debug) console.log('on rows update : '+JSON.stringify(rowsSelected));

    if (this.props.onUpdate){
      for(let i = 0; i < rowsSelected.length; i++){
        let item = items.content[rowsSelected[i]];
        this.props.onUpdate(item.id, item);
      }
    }

  }

  onDeleteClick(){
    this.setState({isOpenDeleteDialog : true})
  }

  onCloseDeleteDialog(){
    this.setState({isOpenDeleteDialog : false})
  }

  render() {
    const { t, entity, rowHover, selectableRows, disableToolbarSelect, deleteRow = true, buttonsSelect} = this.props;
    const { page, items, rowsPerPage, error, search, columnFilter, rowsSelected, isOpenDeleteDialog, init} = this.state;

    let columns = this.getColumns();

    //console.log('columns during render : ' + JSON.stringify(columns));
    //console.log('rowHover '+rowHover);

    let opts = {
      filter: true,
        filterType: "checkbox",
        rowHover : rowHover,
        count : items.totalElements,
        serverSideFilterList : columnFilter,
        rowsPerPage : rowsPerPage,
        onChangePage : this.onChangePage,
        serverSide: true,
        page: page,
        selectableRows : selectableRows,
        onRowClick : this.onRowClick,
        onChangeRowsPerPage: this.onChangeRowsPerPage,
        onColumnSortChange : this.onSortChange,
        onSearchChange: this.onSearch,
        onFilterChange: this.onFilterChange,
        searchText : search,
        download : false,
        print : false,
        disableToolbarSelect : disableToolbarSelect,
        viewColumns : false,
        onRowsSelect : this.onRowsSelect,
        rowsSelected : rowsSelected,
        textLabels: {
        body: {
          noMatch: init ? t('dataTable.body.noMatch') : t('dataTable.body.loading'),
          toolTip: t('dataTable.body.toolTip'),
        },
        pagination: {
          next: t('dataTable.pagination.next'),
            previous: t('dataTable.pagination.previous'),
            rowsPerPage: t('dataTable.pagination.rowsPerPage'),
            displayRows: t('dataTable.pagination.displayRows'),
        },
        toolbar: {
          search: t('dataTable.toolbar.search'),
            downloadCsv: t('dataTable.toolbar.downloadCsv'),
            print: t('dataTable.toolbar.print'),
            viewColumns: t('dataTable.toolbar.viewColumns'),
            filterTable: t('dataTable.toolbar.filterTable'),
        },
        filter: {
          all: t('dataTable.filter.all'),
            title: t('dataTable.filter.title'),
            reset: t('dataTable.filter.reset'),
        },
        viewColumns: {
          title: t('dataTable.viewColumns.title'),
            titleAria: t('dataTable.viewColumns.titleAria'),
        },
        selectedRows: {
          text: t('dataTable.selectedRows.text'),
            delete: t('dataTable.selectedRows.delete'),
            update: t('dataTable.selectedRows.update'),
            deleteAria: t('dataTable.selectedRows.deleteAria'),
        },
      }
    }

    let optCompleted = {
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <DataTableSelectToolbar selectedRows={selectedRows}
                                displayData={displayData}
                                setSelectedRows={setSelectedRows}
                                onRowsUpdate={this.onRowsUpdate}
                                onRowsDelete={this.onDeleteClick}
                                options={opts}
                                deleteRow={deleteRow}
                                buttonsSelect={() => {
                                  if (buttonsSelect){
                                    for(let i = 0; i < rowsSelected.length; i++){
                                      let item = items.content[rowsSelected[i]];
                                      return buttonsSelect(item.id, item);
                                    }
                                  }
                                }}
        />
      ),
      ...opts
    }

    return (
      <>
          <Grid item xs={12}>
            { error ? <ErrorContainer msg={t('errors.loadData')} /> : <></> }
            <MUIDataTable
              title={t(entity+'.list.title')  + " (" + items.totalElements +")"}
              data={items.content}
              columns={columns}
              options={optCompleted}
            />
          </Grid>
          <Dialog
            open={isOpenDeleteDialog}
            onClose={this.onCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t(entity+'.delete.title')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(entity+'.delete.description')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onCloseDeleteDialog}>
                {t('buttons.cancel')}
              </Button>
              <Button onClick={this.onRowsDelete} color="primary" autoFocus>
                {t('buttons.delete')}
              </Button>
            </DialogActions>
          </Dialog>
      </>
    )
  }
}

DataTable.defaultProps = {
  rowHover: false,
  selectableRows : "single",
  disableToolbarSelect : false,
  selectedProperty : "id",
  selectedEntireItem : "false"
}

export default withTranslation()(withStyles(styles)(DataTable));