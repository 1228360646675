import React from "react";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import UserAvatar from "../UserAvatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MEDIAQUERY_BIG, MEDIAQUERY_MEDIUM } from "../Responsive/ResponsiveBlock";
import DevDebugJson from "../Dev/DevDebugJson";

export default function UserPreview({ size="md", color = "primary", user, debug = false, ...props }) {
  let classes = useStyles();
  let theme = useTheme();

  let completeName = user.name + " " + user.surname;

  const isBig = useMediaQuery(MEDIAQUERY_BIG);
  const isMedium = useMediaQuery(MEDIAQUERY_MEDIUM);

  let classContainerName = classes.containerName;
  let classContainer = classes.container;
  let classContainerAvatar = classes.containerAvatar;

  if (size === "sm") {
    classContainerName = classes.containerNameSmall
    classContainer = classes.containerSmall;
    classContainerAvatar = classes.containerAvatarSmall;
  } else if (size === "xs"){
    classContainerName = classes.containerNamexSmall
    classContainer = classes.containerxSmall;
    classContainerAvatar = classes.containerAvatarxSmall;
  } else if (isMedium){
  } else {
  }

  return (
    <div className={classContainer}>
      <div className={classContainerAvatar}>
        <UserAvatar key={"avatar-user-"+user.id} size={size} name={completeName} url={user.profilePicture}/>
      </div>
      <div className={classContainerName}>
        {completeName}
        {debug && (
          <div>
            <DevDebugJson data={user} title={"user"} component={"UserPreview"} />
          </div>
        )}
      </div>
    </div>
  );
}
