import { calculateAge, convert, DATETIME_FORMAT } from "./DateUtils";
import React from "react";
import * as formUtils from "./FormUtils";
import UserPreview from "../components/User/UserPreview";
import {
  URL_PATIENTS_CREATE,
  URL_PATIENTS_DELETE,
  URL_PATIENTS_GET,
  URL_PATIENTS_GET_BY_ID,
  URL_PATIENTS_UPDATE,
} from "./UrlConstants";
import { hasRole, ROLE_ADMIN } from "./UserUtils";

export function getPatientsColumns(classes, t) {
  return [
    {
      name: 'name',
    },
    {
      name: 'surname',
    },
    {
      name: 'birthDate',
      options: {
        customBodyRender: (value) => {
          return value ? calculateAge(value) : "--"
        }
      }
    },
    //{
    //  name: 'email',
    //},
    {
      name: 'sex',
      options : {
        customBodyRender: (value) => {
          return value ? (value === "ALL" ? t("patients.fields.sex."+value) : value) : "--"
        }
      }
    },
    {
      name: 'createdBy',
      options : {
        sortDirection: 'none',
        customBodyRender:(value) => {
          return <UserPreview size={"sm"} user={value} />
        }
      }
    },
    {
      name: 'createdAt',
      options: {
        customBodyRender: (value) => {
          return convert(value)
        }
      }
    },
    {
      name: 'updatedAt',
      options: {
        customBodyRender: (value) => {
          return convert(value)
        }
      }
    },
  ];
}

export function getFormValidation(t){

  return {
    name: {
      type: formUtils.REQUIRED_INPUT
    },
    surname: {
      type: formUtils.REQUIRED_INPUT
    },
    email : {
      type : formUtils.EMAIL,
      required : false
    },
    emailMarketing : {
      type : formUtils.CHECKBOX,
      required : false
    },
    phone : {
      type : formUtils.PHONE,
      required : false
    },
    birthDate : {
      type: formUtils.DATE,
      format : DATETIME_FORMAT,
      required : false
    },
    sex : {
      type : formUtils.REQUIRED_OPTION,
      options : [
        {id : "f", label : t("patients.fields.sex.f")},
        {id : "m", label : t("patients.fields.sex.m")},
        {id : "ALL", label : t("patients.fields.sex.ALL")},
      ],
      defaultValue : "",
      required : false
    },
    billingInfo : {
      type : formUtils.INPUT_TEXTAREA,
      textarea : true,
      required : false
    }
  }
}

export function getFormApiMapper(res){
  return {
    name : res.data.name,
    surname : res.data.surname,
    email : res.data.email,
    emailMarketing : res.data.emailMarketing,
    phone : res.data.phone,
    sex : res.data.sex,
    billingInfo : res.data.billingInfo,
    birthDate : res.data.birthDate
  }
}

export function getFormRequestMapper(form){
  let value = form.birthDate;
  if (value === null || value === '' || value.trim() === '') {
    console.log('form birthDate is null')
    form = {...form, birthDate : null};
  }

  let valueEmail = form.email;
  if (valueEmail === null || valueEmail === '' || valueEmail.trim() === '') {
  } else {
    form = {...form, email : valueEmail.trim()};
  }

  return form;
}

export function getConfigurationEntityManagerPatients(user, t, classes, onRowClick = ()=>{}){
  let configuration = {
    entity : 'patients',
    apiGet : URL_PATIENTS_GET,
    apiGetById : URL_PATIENTS_GET_BY_ID,
    apiDelete : URL_PATIENTS_DELETE,
    apiCreate : URL_PATIENTS_CREATE,
    apiUpdate : URL_PATIENTS_UPDATE,
    columns : getPatientsColumns(classes, t),
    formValidation : getFormValidation(t),
    formApiMapper : (res) => {
      return getFormApiMapper(res)
    },
    formRequestMapper : (form) => {
      return getFormRequestMapper(form)
    },
    onRowClick : (data) => {
      onRowClick(data);
    },
    rowHover: true,
    deleteRow : hasRole([ROLE_ADMIN], user),
    titleField : (form) => {return form['name'] + " " + form["surname"]}
  }

  return configuration;
}