import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import {
  URL_PATIENTS_GET,
  URL_PATIENTS_GET_ALL,
} from "../../utils/UrlConstants";
import {
  getPatientsColumns,
  getConfigurationEntityManagerPatients,
} from "../../utils/PatientsUtils";
import { patientDetail } from "../../context/PatientContext";
import { UserStateContext } from "../../context/UserContext";
import { CircularProgress, Grid } from "@material-ui/core";
import SimpleDataTable from "../../components/DataTable/SimpleDataTable";
import { API } from "../../services/AuthService";
import TextField from "@material-ui/core/TextField";
import DevDebugJson from "../../components/Dev/DevDebugJson";
import { generateKey, normalizeTextForSearch } from "../../utils/StringUtils";
import EntityManager from "../../components/EntityManager/EntityManager";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import ErrorContainer from "../../components/ErrorContainer";
import { CACHE_PATIENTS_LIST, getFromCache, storeInCache } from "../../utils/LocalstorageCache";
import Widget from "../../components/Widget/Widget";
import _ from "lodash";

// eslint-disable-next-line import/no-webpack-loader-syntax
import SearchWorker from "worker-loader!../../workers/searchWorker";



const styles = useStyles;

class Patients extends React.Component {

  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      data : [],
      id : generateKey(),
      search : "",
      filteredData : [],
      lastPatients: [], // New state variable for last 15 patients
      isLoading : true,
      isLoadingLasts : true,
      isSearching : false,
      error: false,
    };

    this.debouncedSearch = _.debounce(this.search, 200);
    this.worker = null;
  }

  componentDidMount() {
    this.loadDateOptimized();
    this.loadLastPatients();
    // Dynamically load the worker
    this.loadWorker();
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentWillUnmount() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  loadWorker() {
    try {
      this.worker = new SearchWorker();
      this.worker.onmessage = this.handleWorkerMessage;
    } catch (error) {
      console.error("Failed to load worker", error);
    }
  }

  onRowClick(data){
    const {history} = this.props;
    patientDetail(data.id, history)
  }

  handleWorkerMessage = (event) => {
    const { resultItems } = event.data;
    this.setState({
      filteredData: resultItems,
      isSearching: false,
      id: generateKey()
    });
  }

  async search(value) {
    let { data } = this.state;

    // Set the loading state and let React re-render before running the search
    this.setState(
      {
        search: value,
        isSearching: true,
      },
      () => {
        // If the value is empty, clear results
        if (!value) {
          this.setState({
            filteredData: [],
            isSearching: false,
          });
          return;
        }

        // Use a timeout to ensure the spinner is displayed before searching
        setTimeout(() => {
          const queryNormalized = normalizeTextForSearch(value);
          // Send data to the worker for processing
          if (this.worker) {
            this.worker.postMessage({ data, queryNormalized });
          }
        }, 0);
      }
    );
  }


  loadDateOptimized(){
    let value = getFromCache(CACHE_PATIENTS_LIST)
    if (value){
      console.log('Patients list from cache is ' + JSON.stringify(value))
      this.setState({
        isLoading : false,
        data : value,
        error : false
      }, () => {
        this.loadDate(false)
      })
    } else {
      console.log('Patients list from cache is empty')
      this.loadDate()
    }
  }

  loadDate(initLoading = true){
    let api = URL_PATIENTS_GET_ALL;

    if (initLoading){
      this.setState({isLoading : true, rowsSelected: []});
    }

    let url = api

    console.log("onReloadData from API : " + url);
    API().get(url)
      .then(res => {
        const items = res.data;
        //console.log('items '+JSON.stringify(items));

        storeInCache(CACHE_PATIENTS_LIST, items)

        this.setState({
          data : items,
          isLoading: false,
          error: false
        }, () => {
          this.search()
        });
      }).catch(e => {
      console.log('On get exception '+JSON.stringify(e));
      this.setState({ error : true, isLoading: false });
    })
  }

  loadLastPatients(){
    console.log('loading last patients')

    let limit = 15;

    let url = URL_PATIENTS_GET + "?onlyConsent=true&sort=createdAt,desc&size="+limit;
    API().get(url)
      .then(res => {
        const items = res && res.data && res.data.content ? res.data.content : [];
        console.log('items '+JSON.stringify(items));

        this.setState({
          lastPatients : items,
          isLoadingLasts: false,
        });
      }).catch(e => {
      console.log('On get exception '+JSON.stringify(e));
      this.setState({ isLoadingLasts: false });
    })

  }

  goToPatientDetail(data){
    const {history} = this.props;
    patientDetail(data.id, history)
  }

  render() {
    let {isLoading, isSearching, isLoadingLasts, error, filteredData, lastPatients, data, id,search = ""} = this.state;
    let {debug = false} = this.props;

    let {classes, t} = this.props;
    let user = this.context.user;

    let columns = getPatientsColumns(classes, t);
    let onRowClick = (data) => {
      this.onRowClick(data);
    };

    let configuration = getConfigurationEntityManagerPatients(user, t, classes)


    return (
      <div style={{width:"100%"}}>

        <EntityManager configuration={configuration}
                       showDataTable={false}
                       onCreateSuccess={(res)=>{
                         console.log('create ok with response ' + JSON.stringify(res));
                         let patiendId = res.data.id;
                         console.log('MOVE TO PATIENT DETAIL : ' + patiendId);
                         this.goToPatientDetail(res.data)
                       }}
        />

        <Grid item xs={12}  style={{marginTop:"10px"}}/>

        {isLoading ? (
          <CircularProgress size={26}/>
        ) : (
          <Grid item xs={12}>

            {error ? (
              <ErrorContainer msg={t('errors.loadData')} />
            ) : (
              <Card className={classes.root}>
                <CardContent>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <div style={{flex:1}}>
                      <TextField
                        id="search-bar"
                        className="text"
                        onChange={(e) => {
                          this.debouncedSearch(e.target.value);
                        }}
                        label={t('patients.search.title')}
                        placeholder={t('patients.search.placeholder')}
                        size="big"
                        style={{width:"100%"}}
                      />
                    </div>

                    {isSearching && (
                      <div>
                        <CircularProgress size={20}/>
                      </div>
                    )}

                  </div>

                </CardContent>
              </Card>
            )}

          </Grid>
        )}


        <Grid item xs={12} style={{marginTop:"10px"}}>

          {(search && search !== "" && search.length > 0) && (
            <SimpleDataTable entity={'patients'}
                             showTitle={false}
                             key={id}
                             columns={columns}
                             data={filteredData}
                             enableSearch={false}
                             disableToolbarSelect={true}
                             selectableRows={false}
                             onRowClick={onRowClick}
                             isLoading={isSearching}
            />
          )}

          {(search === null || search === "" || (search && search.length === 0)) && (
            <Widget
              title={t('patients.lastUsersTitle')}
              upperTitle
              disableWidgetMenu = {true}
            >
              <div>
                {isLoadingLasts ? (
                  <div style={{textAlign:"center", padding:"40px 0px"}}>
                    <CircularProgress size={26}/>
                  </div>
                ) : (
                  <div style={{marginTop:"20px"}}>

                    <SimpleDataTable entity={'patients'}
                                     showTitle={false}
                                     key={id}
                                     columns={columns}
                                     data={lastPatients}
                                     enableSearch={false}
                                     disableToolbarSelect={true}
                                     selectableRows={false}
                                     onRowClick={onRowClick}
                    />

                  </div>
                )}
              </div>
            </Widget>
          )}

          {debug && (
            <div>
              <DevDebugJson data={data} title={"data"} />
              <DevDebugJson data={search} title={"search"} />
              <DevDebugJson data={lastPatients} title={"lastPatients"} />
              <DevDebugJson data={filteredData} title={"filteredData"} />
            </div>
          )}

        </Grid>
      </div>
    )
  }
}


export default withTranslation()(withStyles(styles, { withTheme: true})(Patients));
Patients.contextType = UserStateContext;
