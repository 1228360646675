import React from "react";
import useStyles from "./styles";
import { Button, CircularProgress, Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import Widget from "../../components/Widget";
import {URL_PUBLIC_PATIENT,
} from "../../utils/UrlConstants";
import ErrorContainer from "../../components/ErrorContainer";
import { API } from "../../services/AuthService";
import ExercisePublic from "./ExercisePublic/ExercisePublic";
import ArticlePublic from "./ArticlePublic/ArticlePublic";

const styles = useStyles;

class PatientCardPublic extends React.Component {

  constructor(props) {
    super(props);

    console.log('PROPS ' + JSON.stringify(props.match));
    //match.params.patientId

    this.state = {
      debug: false,
      entity: 'patients',
      data: { fullName: '' },
      url: URL_PUBLIC_PATIENT,
      isLoading: true,
      error: false
    };

  }

  componentDidMount() {
    const {match} = this.props;
    let patientId = match.params.patientId;
    let cardId = match.params.cardId;
    this.setState({patientId : patientId, cardId : cardId}, () => {
      this.loadData();
    })
  }

  loadData(){
    const {patientId, cardId, url} = this.state;
    console.log('loading data... : patient : ' + patientId + " card : " + cardId)
    let urlWithParam = url.replace(":patientId", patientId).replace(":cardId", cardId);

    API(false).get(urlWithParam).then(res => {
      const data = res.data;
      this.setState({ data : data, isLoading: false, error: false});

    }).catch(e => {
      console.log('On get exception '+JSON.stringify(e));
      this.setState({ error : true, isLoading: false });
    })
  }

  render(){

    const {classes, t} = this.props;
    const {data, debug, isLoading, error, entity} = this.state;
    let sectionRefs = [];

    if (data && data.card && data.card.blocks){
      sectionRefs = [];
      for (let i = 0 ; i < data.card.blocks.length; i++){
        sectionRefs[i] = React.createRef();
      }
    }

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Grid container spacing={4} className={classes.fullContainer}>
            { error ? <ErrorContainer msg={t('errors.loadData')} /> : (
              <>
                {isLoading ? (
                  <CircularProgress size={26}/>
                ) : (
                  <>
                    {debug ?
                      (<Grid item xs={12}>
                          <Widget
                            title="Api Data"
                            upperTitle
                            bodyClass={classes.fullHeightBody}
                            className={classes.card}
                            disableWidgetMenu = {true}
                          >
                            <div>{JSON.stringify(data)}</div>
                          </Widget>
                        </Grid>
                      ) : <></>
                    }

                    <Grid item xs={12}>
                      <PageTitle title={data.fullName} className={classes.pagetitle}/>
                    </Grid>

                    {data.card.blocks.map((block, index) => {
                      return (
                        <Button
                          classes={{ root: classes.button }}
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={() => {console.log('scrolling ' + block.id + " index : "+index + " ref : " + sectionRefs[index] + " offset top : "+sectionRefs[index].current.offsetTop); window.scrollTo(0, sectionRefs[index].current.offsetTop); }}
                          style={{marginRight: "10px"}}
                        >
                          {block.title}
                        </Button>
                      )
                    })}

                    {data.card.blocks.map((block, index) => {
                      return (
                        <div ref={sectionRefs[index]} style={{width: "100%"}}>
                          <Grid container spacing={4} style={{marginTop: "40px"}} >
                            <Widget
                              title={block.title}
                              upperTitle
                              bodyClass={classes.fullHeightBody}
                              className={classes.card}
                              disableWidgetMenu = {true}
                            >
                              <Grid container spacing={4}>
                                <Grid item xs={12}>
                                  <Widget
                                    title={t(entity+'.detail.general.title')}
                                    upperTitle
                                    bodyClass={classes.fullHeightBody}
                                    className={classes.card}
                                    disableWidgetMenu = {true}
                                  >
                                    <div dangerouslySetInnerHTML={{ __html: block.general }} className={classes.general}/>
                                  </Widget>
                                </Grid>

                              {block.exercises.length > 0 ?
                                <Grid item xs={12}>
                                  <Widget
                                    title={t(entity+'.detail.exercises.title')}
                                    upperTitle
                                    bodyClass={classes.fullHeightBody}
                                    className={classes.card}
                                    disableWidgetMenu = {true}
                                  >
                                    <Grid container spacing={4}>
                                      {block.exercises.map(item => {
                                        return (
                                          <ExercisePublic data={item} key={item.id} />
                                        );
                                      })}
                                    </Grid>
                                  </Widget>
                                </Grid> : <></>
                              }

                              {block.articles.length > 0 ?
                                <Grid item xs={12}>
                                  <Widget
                                    title={t(entity+'.detail.articles.title')}
                                    upperTitle
                                    bodyClass={classes.fullHeightBody}
                                    className={classes.card}
                                    disableWidgetMenu = {true}
                                  >
                                    <Grid container spacing={4}>
                                      {block.articles.map(item => {
                                        return (
                                          <ArticlePublic data={item} key={item.id} />
                                        );
                                      })}
                                    </Grid>
                                  </Widget>
                                </Grid>
                                 : <></>
                              }
                            </Grid>
                          </Widget>
                        </Grid>
                      </div>
                      );
                    })}




                  </>
                )
                }
              </>
            ) }

          </Grid>
        </div>
      </div>
    )
  }
}
export default withTranslation()(withStyles(styles, { withTheme: true})(PatientCardPublic));