const styles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    width: "100vw",

    overflowX: "hidden",
    overflowY: "hidden",
    backgroundColor: theme.palette.background.default
  },
  content: {
    flexGrow: 1,

    minHeight: "100vh",
    [theme.breakpoints.down('sm')]: {
      margin : 0,
      width: "100vw",
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      margin : "0 10vw",
      width: "80vw",
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      margin: "0 20vw auto",
      width: "60vw",
      padding: theme.spacing(3),
    },
  },
  general : {
  },
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  fullContainer: {
    paddingBottom: 50,
  },
  pagetitle: {
    [theme.breakpoints.down('sm')]: {
      margin: "20px 0px 0px 0px !important",
    },
  }
});

export default styles;