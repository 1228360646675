import React, { useCallback, useEffect, useRef, useState } from "react";
import Exercise from "./Exercise";
import { useTheme } from "@material-ui/styles";
import useStyles from "../../../../components/DraggableTabPanel/styles";
import { useDrag, useDrop } from "react-dnd";
import { Grid } from "@material-ui/core";
import DevDebugJson from "../../../../components/Dev/DevDebugJson";

export default function ExerciseDraggable({
                                            itemType, id, item, index, onDrop = () => {}, moveItem = () => {}, onClick = () => {}, onDrag=()=>{}, icon = null, compressed=false,
                                            debug = false,
                                            ...props
}){
  let theme = useTheme();
  let classes = useStyles(theme);

  const ref = useRef(null)

  debug = true;

  const [, drop] = useDrop({
    accept: itemType,
    hover(hoverItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = hoverItem.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        //console.log('same item')
        return
      }

      if (debug) console.log('hover hoverItem : ' + JSON.stringify(hoverItem))
      if (debug) console.log('hover drag : ' + dragIndex + " hover : " + hoverIndex)
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?ref.current.getBoundingClientRect():null;
      // Get vertical middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // Only perform the move when the mouse has crossed half of the items height When dragging downwards, only move when the cursor is below 50% When dragging upwards, only move when the cursor is above 50%
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        if (debug) console.log('not half of')
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        if (debug) console.log('Dragging upwards')
        return
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      hoverItem.index = hoverIndex
    },
    drop(hoverItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = hoverItem.index
      const hoverIndex = index

      onDrop(item, hoverIndex)
    }
  })

  const [{ isDragging, opacity}, drag] = useDrag({
    item: {
      type: itemType,
      id,
      index
    },
    type:itemType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity : monitor.isDragging() ? 0 : 1,
    }),
  })

  useEffect(() => {
    console.log('Changing status is dragging : ' + isDragging)
    onDrag(isDragging)
  }, [isDragging])

  useEffect(() => {
    console.log('Intercepted change of compressed : ' + compressed)
  }, [compressed])

  drag(drop(ref))

  let itemsPerRow = compressed ? 2 : 4;

  if (isDragging) console.log('Moving and rendering dragging item (dragging : ' + (isDragging ? "true" : "false") + " compressed : " + (compressed ? "true" : "false"))

    return (
      <Grid item xs={itemsPerRow} md={itemsPerRow} lg={itemsPerRow}
            style={{opacity : opacity}}
            ref={ref}

      >
          <Exercise data={item}
                    onDelete={()=>{props.onDelete(item)}}
                    onSave={(id, data)=>{props.onSave(item, data)}}
                    autoSave={props.autoSave}
                    canEdit={props.canEdit}
                    compressed={compressed}
          />

        <DevDebugJson data={item} title={"item"} component={"ExerciseDraggable"} debug={false} />

      </Grid>
    )
}