import React from 'react';
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import PageTitle from "../PageTitle";
import { Grid } from "@material-ui/core";
import DataTable from "../DataTable";
import GeneralCreateUpdateFormDialog from "../FormDialog/GeneralCreateUpdateFormDialog/GeneralCreateUpdateFormDialog";
import ToastContainerCustom from "../ToastContainerCustom/ToastContainerCustom";
import { ENTITY_MANAGER_TYPE_LIST, ENTITY_MANAGER_TYPE_MODAL_CREATE } from "../../utils/EntityManagerUtils";

const styles = useStyles;

class EntityManager extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpenDialogCreate : false,
      isReloadData : false,
      updateId : null,
      error : false,
      debug : false
    };
    this.baseState = this.state;

  }

  componentDidCatch(error, info) {
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  onCreateClick = () => {
    this.setState({
      isOpenDialogCreate : true, updateId : null
    });
  }

  onUpdateClick = (id = null) => {
    this.setState({isOpenDialogCreate : true, updateId : id});
  }


  onSubmitCreate = (res) => {
    let {onCreateClose = () => {}, onCreateSuccess = () => {}} = this.props;
    console.log('EntityManager - On Submit Create Dialog with response : ' + JSON.stringify(res))
    this.setState({isOpenDialogCreate : false, isReloadData : true}, () => {
      onCreateClose()
      onCreateSuccess(res)
    });
  }

  onCloseCreate = () => {
    let {onCreateClose = () => {}} = this.props;
    console.log('EntityManager - On Close Create Dialog')
    this.setState({isOpenDialogCreate : false}, ()=>{
      onCreateClose()
    });
  }

  onReloadData = () => {
    console.log('EntityManager - On Reload Data Dialog')
    this.setState({isReloadData : false});
  }



  render() {
    let {configuration, t, showDataTable = true} = this.props;
    let {entity, apiGet, apiGetById, apiDelete, apiCreate, apiUpdate,
      columns, formValidation, formValidationUpdate, formApiMapper, formRequestMapper, buttonsSelect,
      onRowClick = () => {}, deleteRow = true, rowHover = false, titleField = (form) => {return form["title"]}
    } = configuration;

    let {isOpenDialogCreate, isReloadData, updateId, debug} = this.state;

    let configurationUpdateOrCreateModal = {
      apiGet : apiGetById.replace(":id", updateId),
      apiUpdate : apiUpdate.replace(":id", updateId),
      apiCreate : apiCreate,
      formValidation : formValidation,
      formValidationUpdate : formValidationUpdate,
      formApiMapper : formApiMapper,
      formRequestMapper : formRequestMapper,
      entity: entity,
      titleField:titleField
    }

    if (debug) console.log('Entity Manager - formValidationUpdate : ' + JSON.stringify(formValidationUpdate))

    let logicalOpenModalCreate = isOpenDialogCreate;
    if (configuration.type && configuration.type === ENTITY_MANAGER_TYPE_MODAL_CREATE){
      logicalOpenModalCreate = true;
    }

    if (debug) console.log('Render Entity Manager open create : ' + logicalOpenModalCreate)
    let createUpdateForm = (
      <GeneralCreateUpdateFormDialog configuration={configurationUpdateOrCreateModal}
                                     open={logicalOpenModalCreate}
                                     onSubmit={this.onSubmitCreate}
                                     onClose={this.onCloseCreate}
                                     id={updateId}
                                     size="xl"
      />
    )

    let returnContent = (
      <>
        <PageTitle title={t(entity+'.title')} button={t(entity+'.button')} onButtonClick={this.onCreateClick}>

        </PageTitle>
        {showDataTable && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataTable entity={entity}
                         api={apiGet}
                         apiDelete={apiDelete}
                         columns={columns}
                         reload={isReloadData}
                         onReload={this.onReloadData}
                         onUpdate={this.onUpdateClick}
                         onRowClick={onRowClick}
                         rowHover={rowHover}
                         deleteRow={deleteRow}
                         buttonsSelect={buttonsSelect}
              />
            </Grid>
          </Grid>
        )}

        {createUpdateForm}

        <ToastContainerCustom />
      </>
    );

    if (configuration.type && configuration.type === ENTITY_MANAGER_TYPE_MODAL_CREATE){
      return createUpdateForm;
    } else if (configuration.type && configuration.type === ENTITY_MANAGER_TYPE_LIST){
      returnContent = (
        <div>
          {returnContent}
        </div>
      )
    }


    return returnContent
  }
}

EntityManager.defaultProps = {
  configuration : {
    entity : 'entity',
    apiGet : '',
    apiGetById : '',
    apiDelete : '',
    apiCreate : '',
    apiUpdate : '',
    columns : [],
    formValidation : {},
    formValidationUpdate : null,
    formApiMapper : (res) => {
      return {}
    },
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(EntityManager));
