import React, { useState } from "react";
import { useTheme } from "@material-ui/styles";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function UserAvatar({ color = "primary", size="md", url, ...props }) {
  let [sourceAvatar, setSourceAvatar] = useState(url);

  var classes = useStyles();
  var theme = useTheme();

  var letters = props.name
    .split(" ")
    .map(word => word[0])
    .join("");

  let width = "30px";
  switch (size){
    case "sm":
      width = "20px"
      break;
    case "md":
      width = "30px"
      break;
    case "lg":
      width = "40px"
      break;
  }

  let otherProps = {};
  if (width && width !==""){
    otherProps = {width : width, height: width};
  }

  if (sourceAvatar){
    return (
      <img {...otherProps} className={classes.avatar} src={sourceAvatar} onError={() => {setSourceAvatar(null)}} alt={props.name}/>
    )
  } else {
    return (
      <div
        className={classes.avatar}
        style={{ backgroundColor: theme.palette[color].main, width:width, height:width }}
      >

        <Typography className={classes.text}>{letters.toUpperCase()}</Typography>

      </div>
    )
  }
}
