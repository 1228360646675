import React from "react";
import {
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";

// styles
import useStyles from "./styles";

// components
import MUIDataTable from "mui-datatables";
import { API } from "../../services/AuthService";
import ErrorContainer from "../../components/ErrorContainer";
import DevDebugJson from "../Dev/DevDebugJson";

const styles = useStyles;

class SimpleDataTable extends React.Component {

  constructor(props) {
    super(props);

    let columns = props.columns;
    let columnDefault = columns[0].name;

    this.state = {
      items: {"content" : [], "totalElements" : 0},
      error: false,
      rowsPerPage : 10,
      page : 0,
      isLoading : true,
      columnFilter : [],
      search : "",
      isOpenDeleteDialog : false
    };

    // This binding is necessary to make `this` work in the callback
    this.onRowClick = this.onRowClick.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.loadDate = this.loadDate.bind(this);
    this.onRowsSelect = this.onRowsSelect.bind(this);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }


  componentDidMount() {
    let {api, data} = this.props;

    if (api){
      this.loadDate();
    } else if (data){
      //console.log('Simple Data initialize with Data : ' + JSON.stringify(data))
      this.setState({
        items : {content : data},
        isLoading : false
      })
    }

  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.reload){
      if (nextProps.reload === true && this.props.reload === false) {
        this.setState({page : 0 }, () => {
          this.loadDate();
        })
      }
    }
  }


  loadDate(){
    let {api} = this.props;

    this.setState({isLoading : true, rowsSelected: []});
    let url = api

    console.log("onReloadData : " + url);
    API().get(url)
      .then(res => {
        const items = res.data;
        console.log('items '+JSON.stringify(items));
        this.setState({ items : {content : items }, isLoading: false, error: false}, () => {
          if (this.props.onReload){
            this.props.onReload();
          }
        });
      }).catch(e => {
        console.log('On get exception '+JSON.stringify(e));
        this.setState({ error : true });
    })
  }



  onRowClick(rowData, rowMeta){
    const {items} = this.state;
    //console.log("on click : " + JSON.stringify(rowData) + " rowMeta " + JSON.stringify(rowMeta) + " content : " + JSON.stringify(items.content));
    if (this.props.onRowClick){
      this.props.onRowClick(items.content[rowMeta.rowIndex]);
    }
  }

  onChangeRowsPerPage(data){
    console.log("on onChangeRowPerPage : " + JSON.stringify(data));

    if (this.state.rowsPerPage !== data) {
      this.setState({rowsPerPage : data, page : 0}, () => {
        this.loadDate()
      })
    }
  }

  getColumns(){
    let {columns, entity, t} = this.props;
    let {columnFilter} = this.state;

    let retColumns = [];

    for (let i = 0; i < columns.length; i++){
      let currColumn = columns[i];
      if (!currColumn.label){
        currColumn.label = t(entity+'.list.columns.'+currColumn.name);
      }
      if(!currColumn.options){
        currColumn.options = {};
      }
      if (!currColumn.options.filter){
        currColumn.options.filter = false
      } else {
        currColumn.options.filterList = columnFilter[i];
      }
      retColumns.push(currColumn);
    }
    return retColumns;
  }

  onRowsSelect(currentRowsSelected, allRows){
    const {items} = this.state;
    console.log('currentRowsSelected : ' + JSON.stringify(allRows));
    this.setState ({rowsSelected : allRows.map(row => row.dataIndex)});
    if (this.props.onRowsSelect){
      // remap ids
      let retArray = [];
      for(let i = 0 ; i < allRows.length; i++){
        retArray.push(items.content[allRows[i].index]);
      }
      console.log("Selected array : " + JSON.stringify(retArray));
      this.props.onRowsSelect(retArray);
    }
  }


  render() {
    const { t, entity, rowHover, selectableRows, disableToolbarSelect, enableSearch = true, showTitle = true, debug = false} = this.props;
    const {  items, error, rowsSelected, isLoading } = this.state;

    let columns = this.getColumns();

    //console.log('columns during render : ' + JSON.stringify(columns));
    //console.log('rowHover '+rowHover);

    let opts = {
        pagination : false,
        filter: false,
        search:enableSearch,
        filterType: "checkbox",
        rowHover : rowHover,
        selectableRows : selectableRows,
        onRowClick : this.onRowClick,
        download : false,
        print : false,
        disableToolbarSelect : disableToolbarSelect,
        viewColumns : false,
        onRowsSelect : this.onRowsSelect,
        rowsSelected : rowsSelected,
        textLabels: {
        body: {
          noMatch: t('dataTable.body.noMatch'),
            toolTip: t('dataTable.body.toolTip'),
        },
        toolbar: {
          search: t('dataTable.toolbar.search'),
            downloadCsv: t('dataTable.toolbar.downloadCsv'),
            print: t('dataTable.toolbar.print'),
            viewColumns: t('dataTable.toolbar.viewColumns'),
            filterTable: t('dataTable.toolbar.filterTable'),
        },
        viewColumns: {
          title: t('dataTable.viewColumns.title'),
            titleAria: t('dataTable.viewColumns.titleAria'),
        },
        selectedRows: {
          text: t('dataTable.selectedRows.text'),
            delete: t('dataTable.selectedRows.delete'),
            update: t('dataTable.selectedRows.update'),
            deleteAria: t('dataTable.selectedRows.deleteAria'),
        },
      }
    }


    return (
      <>
          <Grid item xs={12}>
            { error ? <ErrorContainer msg={t('errors.loadData')} /> : <></> }
            {isLoading ? (
              <CircularProgress size={26}/>
            ) : (
            <MUIDataTable
              title={showTitle ? t(entity+'.list.title') : null}
              data={items.content}
              columns={columns}
              options={opts}
            />
            )}
            {debug && (
              <DevDebugJson data={items.content} />
            )}

          </Grid>
      </>
    )
  }
}

SimpleDataTable.defaultProps = {
  rowHover: false,
  selectableRows : "single",
  disableToolbarSelect : false
}

export default withTranslation()(withStyles(styles)(SimpleDataTable));
