import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import { Grid, Typography } from "@material-ui/core";

import { EditorState, convertToRaw, ContentState, SelectionState, Modifier } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as Icons from "@material-ui/icons";
import DevDebugJson from "../Dev/DevDebugJson";
import GeneralEditorCustomOptions from "./GeneralEditorCustomOptions";
import EntityManager from "../EntityManager/EntityManager";
import { getDictionaryConfiguration } from "../../utils/DictionaryUtils";
import { ENTITY_MANAGER_TYPE_MODAL_CREATE } from "../../utils/EntityManagerUtils";
import { UserStateContext } from "../../context/UserContext";
import SelectionDialog from "../SelectionDialog/SelectionDialog";
import { getSelectedBlocks } from "../../utils/GeneralEditorUtils";

const styles = useStyles;


class GeneralEditor extends React.Component {
  constructor(props) {
    super(props);


    //const content = ContentState.createFromBlockArray(convertFromHTML(props.data ? props.data : ""));
    const content = ContentState.createFromBlockArray(htmlToDraft(props.data ? props.data : ""));

    this.state = {
      data : props.data,
      editorState: EditorState.createWithContent(content),
      saved : false,
      overlay : false,
      showCreate: false,
      createText: "",
      showList : false,
      debug : true
    }
    this.onSubmitChange = this.onSubmitChange.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange(editorState){
    let {debug} = this.state;

    if (debug) console.log('change state : ' + draftToHtml(convertToRaw(editorState.getCurrentContent())))
    let htmlConverted = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (debug) console.log('change state htmlConverted : ' + htmlConverted)
    this.setState({ editorState, data : htmlConverted}, () => {
      this.onChange(htmlConverted, false, true);
    });
  };

  onSubmitChange(){
    const {data} = this.state;

    if (this.props.onSave){
      this.props.onSave(data);
    }

    console.log('changing saved state');
    this.setState({saved : true})
  }

  onGoToEnd(){
    let {editorState, debug} = this.state;

    if (debug) console.log('Go To End')

    window.scrollTo(0,document.body.scrollHeight);

    //let newState = EditorState.moveSelectionToEnd(editorState);
    //this.setState({editorState : newState});

    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    // On Chrome and Safari, calling focus on contenteditable focuses the
    // cursor at the first character. This is something you don't expect when
    // you're clicking on an input element but not directly on a character.
    // Put the cursor back where it was before the blur.
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    });
    //let newEditor = EditorState.forceSelection(editorState, selection);
    let newEditor = EditorState.moveFocusToEnd(editorState);

    /*
    let newEditor = EditorState.push(editorState, Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    ), 'insert-characters');
*/

    this.setState({editorState : newEditor});
  }

  onChange(html, force = false, notify = true){
    const {autoSave, autoSaveDelay, data} = this.props;
    let {debug} = this.state;


    if (debug) console.log('on change ' + this.state.data);
    let changed = force || data !== this.state.data;
    if (changed){
      if (debug) console.log('changed data in props : '+data);
      //console.log('changed data in state : '+this.state.data);
    }

    this.setState({data : html}, () => {
      if (changed && autoSave){
        clearTimeout(this.timer);
        this.timer = setTimeout(this.onSubmitChange, autoSaveDelay);
      }
    });

    if (notify && this.props.onChange){
      this.props.onChange(html, false);
    }


  }

  injectData(){

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.update && this.state.data !== nextProps.data && nextProps.controlUpdate) {
      console.log('injecting new data in General Editor : next data' + nextProps.data + " type "+(typeof nextProps.data) + " Curent Data : " + this.state.data);
      const newContent = nextProps.data;

      const contentBlock = htmlToDraft(newContent ? newContent : "");
      const contentState = ContentState.createFromBlockArray(contentBlock);
      const editorStateNew = EditorState.createWithContent(contentState);

      this.setState({
        data : nextProps.data,
        editorState: editorStateNew
      }, () => {
        this.onChange(nextProps.data, true, false);
      });
    }
  }

  onClick(){
    let {activeOverlay} = this.props;
    if (activeOverlay && !this.state.overlay){
      console.log('on click - activate overlay');
      this.setState({overlay: true});
    }
  }

  closeOverlay(){
    this.setState({overlay:false})
  }

  onCreate(){
    this.setState({
      showCreate: !this.state.showCreate,
    })
  }

  togglePasteFromDictionaryShow(){
    console.log('Toggle dictionary list show')
    this.setState({
      showList : !this.state.showList
    })
  }

  onPaste(item){
    let {editorState} = this.state;

    let newValue = item[0].value;
    console.log('Pasting item ' + JSON.stringify(newValue))

    const data = "<p>"+newValue+"</p>"

    let { contentBlocks, entityMap } = htmlToDraft(data);
    let contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    )

    let newEditorState = EditorState.push(editorState, contentState, 'insert-fragment');
    this.setState({
      editorState : newEditorState
    })

    this.togglePasteFromDictionaryShow();
  }

  openCreateWithText(){
    let {editorState} = this.state;
    console.log('OPEN WITH TEXT')

    const selectionState = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const entityMap = contentState.getEntityMap()
    const startKey = selectionState.getStartKey()
    const endKey = selectionState.getEndKey()

    const selectedBlocks = getSelectedBlocks({contentState, startKey, endKey});

    const blockArray=[];
    selectedBlocks.forEach((bloke) => {
      if (bloke) {
        blockArray.push(bloke)
      }
    })
    const newContentState = ContentState.createFromBlockArray(
      blockArray,
      entityMap
    )

    const editorStateNew = EditorState.createWithContent(newContentState);
    let htmlConverted = draftToHtml(convertToRaw(editorStateNew.getCurrentContent()));

    //let htmlConverted = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    let selectedText = htmlConverted;
    //onChange(EditorState.push(editorState, newContentState, 'insert-fragment'))
    console.log('Selected text to inject into creation modal is : ' + selectedText)

    this.setState({
      showCreate : true,
      createText : selectedText
    }, () => {
      
    })

  }

  render(){
    let {user, data, t, classes, placeholder, button, autoSave, activeOverlay, overlayedTitle, additionalTitle = null,
      buttonSaveText, mode="NORMAL", buttonColor="primary" ,buttonVariant="contained", enableCancel = false, onCancel = () => {},
      textButtonCancel = t('generalEditor.cancelButton'),
      debug = false,
    } = this.props;
    let { editorState, saved, overlay, showCreate, showList, createText} = this.state;

    let changed = data !== this.state.data;

    let classContainer = "";
    let classGeneralEditor = classes.generalEditor;
    if (mode === "FLAT"){
      classGeneralEditor = classes.generalEditorFlat;
    }

    let styleMainContainer = {};
    if (activeOverlay){
      styleMainContainer = {paddingTop: "0px"}
    }

    if (overlay){
      classContainer = classes.generalEditorOverlay;
      classGeneralEditor = classes.generalEditorOverlayed;
      styleMainContainer = {padding:"0px 10px"};
    }

    let buttonText = t('buttons.save');
    if (buttonSaveText){
      buttonText = buttonSaveText;
    }

    let cancelButton = null;
    if (enableCancel){
      cancelButton = (
        <Button variant={"outlined"} color={buttonColor} size="small" onClick={() => {onCancel()}} style={{marginRight:"2px"}}>
          {textButtonCancel}
        </Button>
      )
    }

    let configuration = getDictionaryConfiguration(user, classes);
    if (showCreate){
      configuration.type = ENTITY_MANAGER_TYPE_MODAL_CREATE;
      configuration.formValidation.value.defaultValue = createText;
      console.log('showCreate is : ' + JSON.stringify(showCreate))
      console.log('createText is : ' + JSON.stringify(createText))
      console.log('Configuration is : ' + JSON.stringify(configuration))
    }

    return (
      <>
      <Grid item xs={12} className={classContainer} style={styleMainContainer}>
        {overlay && (
          <Grid container style={{paddingTop:"10px"}}>
            <Grid xs={6}>
              {overlayedTitle}
            </Grid>
            <Grid item xs={6} style={{textAlign:"right", paddingBottom:"5px"}}>
              <Button variant="outlined" color="primary" size="small" onClick={() => {this.closeOverlay()}}>
                <Icons.FullscreenExit style={{marginRight: "5px"}}/> {t("generalEditor.openOverlayExit")}
              </Button>
            </Grid>
          </Grid>

        )}

        {!overlay && activeOverlay && (
          <Grid container>
            <Grid items xs={6}>
              {additionalTitle}
            </Grid>
            <Grid item xs={6} style={{textAlign:"right", paddingBottom:"5px"}}>
              <Button variant="outlined" color="primary" size="small" onClick={() => {this.onClick()}}>
                <Icons.Fullscreen style={{marginRight: "5px"}}/> {t("generalEditor.openOverlay")}
              </Button>
            </Grid>
          </Grid>
        )}
        <Editor
          handlePastedText={() => false}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName={classGeneralEditor}
          onEditorStateChange={this.onEditorStateChange}
          toolbarCustomButtons={[<GeneralEditorCustomOptions onCreate={() => {this.openCreateWithText()}} onList={()=>{this.togglePasteFromDictionaryShow()}}/>]}
          placeholder={placeholder}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "emoji",
              "image",
              "remove",
              "history"
            ],
            inline: {
              options: [
                "bold",
                "italic",
                "underline",
                "strikethrough",
                "monospace",
              ]
            }
          }}
        />

        {changed && button && !autoSave ?
          <Grid item xs={12} className={classes.gridButtonBlock}>
            {cancelButton}
            <Button variant={buttonVariant} color={buttonColor} size="small" onClick={this.onSubmitChange}>
              {buttonText}
            </Button>
          </Grid>
          : <></>
        }

        {changed && button && autoSave ?
          <Grid item xs={12} className={classes.gridButtonBlock}>
            <span className={classes.infoLabel}>
              {t('generalEditor.autoSave.updated')}
            </span>
          </Grid>
          : <></>
        }

        {!changed && saved && (
          <Grid item xs={12} className={classes.gridButtonBlock}>
            <span className={classes.successLabel}>
              {t('generalEditor.autoSave.saved')}
            </span>
          </Grid>
        )}

        {!changed && button && (
          <Grid item xs={12} className={classes.gridButtonBlock}>
            {cancelButton}
          </Grid>
        )}


        {showCreate && (
          <EntityManager configuration={configuration} onCreateClose={()=>{
            this.onCreate();
          }} />
        )}

        {showList && (
          <SelectionDialog open={true}
                           configuration={configuration}
                           onClose={() => {this.togglePasteFromDictionaryShow()}}
                           onSelect={(item)=>{this.onPaste(item)}}
          />
        )}

        {debug && (
          <div>
            <DevDebugJson data={saved} title={"saved"} component={"GeneralEditor"} />
            <DevDebugJson data={changed} title={"changed"} component={"GeneralEditor"} />
            <DevDebugJson data={data} title={"this.props.data"} component={"GeneralEditor"} />
            <DevDebugJson data={this.state.data} title={"this.state.data"} component={"GeneralEditor"} />
          </div>
        )}

      </Grid>


      </>
    )
  }
}

GeneralEditor.defaultProps = {
  button : true,
  autoSave : false,
  autoSaveDelay : 2000,
  buttonEnd : false,
  activeOverlay : false
}

export default withTranslation()(withStyles(styles, { withTheme: true})(GeneralEditor));
GeneralEditor.contextType = UserStateContext;