import React, { useCallback, useEffect, useRef, useState } from "react";
import useStyles from "./styles";
import update from "immutability-helper";
import { Grid, IconButton, Tab, Tabs } from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import { useTheme } from "@material-ui/styles";
import * as Icons from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

export default function DraggableTabPanel({
                                            id, blocks, activeTabId,
                                            onChange = () => {}, onChangeActiveTab = () => {}, onChangeOrder = () => {}, onAdd = () => {}, onDelete = () => {}, addTooltip = "",
                                            ...props
}){
  let [items, setItems] = useState(blocks);

  useEffect(() => {
    setItems(blocks)
  }, [blocks])

  var theme = useTheme();
  var classes = useStyles(theme);

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      console.log('move item drag : ' + dragIndex + " to : " + hoverIndex);
      const dragCard = items[dragIndex]
      setItems(
        update(items, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
      //console.log('active tab : ' + activeTabId + " dragIndex " + dragIndex + " hoverIndex " + hoverIndex)
      //if (dragIndex === activeTabId){
      //  onChangeActiveTab(hoverIndex)
      //}
    },
    [items],
  )

  const onDrop = useCallback(
    (item, hoverIndex) => {

      if (item.orderValue !== hoverIndex) {
        onChangeOrder(item, item.orderValue, hoverIndex);
      }
    },
    [items],
  )

  //icon={ <Icons.Delete className={classes.editIcon} onClick={ () => { onDelete(index)} } />}

  return (
    <>
      {(items && items.length > 0) ?
        (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className={classes.tabContainer}>
                <Tooltip title={addTooltip}>
                  <IconButton className={classes.iconButton} onClick={onAdd}>
                    <Icons.Add className={classes.editIcon} />
                  </IconButton>
                </Tooltip>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  value={activeTabId}
                  onChange={onChange}
                  size={"small"}
                  classes={{ flexContainer: classes.tabBarContainer }}
                >
                  {
                    items.map((item, index) => {

                      console.log('Putting tab with id : ' + id)

                      return (
                        <TabPanelItem item={item}
                                      index={index}
                                      key={item.id}
                                      id={item.id}
                                      itemType={id}
                                      moveItem={moveItem}
                                      onClick={onChange}
                                      onDrop={onDrop}
                        />
                      );
                    })}
                </Tabs>
              </div>
            </Grid>
          </Grid>
        ) :
        (<></>)
      }
    </>
  )
}


export function TabPanelItem({
                               itemType, id, item, index, onDrop = () => {}, moveItem = () => {}, onClick = () => {}, icon = null,
                               ...props
}){

  var theme = useTheme();
  var classes = useStyles(theme);

  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: itemType,
    hover(hoverItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = hoverItem.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?ref.current.getBoundingClientRect():null;
      // Get vertical middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // Only perform the move when the mouse has crossed half of the items height When dragging downwards, only move when the cursor is below 50% When dragging upwards, only move when the cursor is above 50%
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }
      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      hoverItem.index = hoverIndex
    },
    drop(hoverItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = hoverItem.index
      const hoverIndex = index

      onDrop(item, hoverIndex)
    }
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, id, index },
    type: itemType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <Tab ref={ref}
         label={item.title}
         classes={{ root: classes.tab }}
         style={{opacity}}
         onClick={() => {onClick(index)}}
         icon={icon}
    />
  )
}