import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  iconButton : {
    marginRight: "10px"
  },
  tabContainer: {
    display : "flex",
    flexDirection: "row",
    flex : 1
  },
  editIcon : {
    marginBottom: "0px !important",
    margin: "0 2px",
  },
  tabBarContainer : {
    flexWrap: 'wrap'
  },
  tab : {
    fontSize: '14px',
    lineHeight: "15px",
    minWidth: 50,
    padding: '6px 12px'
  }
}));

