import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor : "#f5f5f5",
    overflowX:"hidden"
  },
  content : {
    backgroundColor:"white",
    borderRadius:"3px",
    padding:"40px 20px",
    width:"100%"
  },
  logotypeImage: {
    maxWidth:"10vw",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "30vw",
    },
  },
  title : {
    width:"100%",
    textAlign:"center",
    fontSize:"26px",
    fontWeight:"bold",
    color: 'black',
    marginBottom:"20px",
    textDecoration:"underline"
  },

  generalForm : {
    width:"80%",
    border: "1px solid #eee",
    borderRadius:"3px",
    margin:"auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },

  formCell : {
    padding:"20px",
    width:"50%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      padding : "5px 10px"
    },
  },

  formCellFull : {
    padding:"20px",
    width: "100%",
  },

  nameSurnameRow : {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      flexDirection : "column"
    },
  },

  buttonContainer : {
    width:"60%",
    margin: "auto",
    textAlign:"center",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },

  button : {
    width:"100%",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },
  },

  contentPage : {
    width:"80%",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },

  subsectionTitle: {
    fontSize:"20px",
    fontWeight:"bold",
    color: '#666',
    textAlign:"center",
    marginTop:"40px",
    textTransform:"uppercase",
    marginBottom:"40px"
  },

  subsectionBlock : {
    marginBottom:"20px"
  },

  subsectionHeader: {
    fontWeight:"bold",
    color:"black",
    textTransform:"uppercase",
    marginRight:"10px",
    fontSize:"16px"
  },
  subsectionContent : {
    color:'black',
    fontSize:"16px"
  },

  checkContainer : {
    width:"80%",
    border: "1px solid #eee",
    borderRadius:"3px",
    margin:"auto",
    marginTop:"20px",
    marginBottom:"20px",
    color:'black',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },

  signContainer : {
    margin:"auto",
    textAlign:"center",
    width:"60%",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    },
  },
  success : {
    margin:"40px 10px",
    textAlign:"center",
    color : "#45B4A3",
    fontWeight:"bold",
    fontSize:"20px",
  }
}));
