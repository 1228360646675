import React from "react";
import {
  Button,
  CircularProgress,
  Grid, Tab, Tabs,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon
} from "@material-ui/icons";

// styles
import useStyles from "./styles";

// components
import {
  URL_PATIENTS_CREATE,
  URL_PATIENTS_GET_BY_ID, URL_PATIENTS_UPDATE,
} from "../../utils/UrlConstants";
import { API } from "../../services/AuthService";
import ErrorContainer from "../../components/ErrorContainer";
import Widget from "../../components/Widget";
import { Typography } from "../../components/Wrappers";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { patientsList } from "../../context/PatientContext";
import { calculateAge, convert } from "../../utils/DateUtils";
import ToastContainerCustom, { sendNotification } from "../../components/ToastContainerCustom/ToastContainerCustom";
import PublicSection from "./components/PublicSection/PublicSection";
import PrivateSection from "./components/PrivateSection/PrivateSection";
import { COOKIE_AUTO_SAVE, DEFAULT_COOKIE_AUTO_SAVE, getCookieWithDefault, setCookie } from "../../utils/CookiesUtils";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GeneralCreateUpdateFormDialog
  from "../../components/FormDialog/GeneralCreateUpdateFormDialog/GeneralCreateUpdateFormDialog";
import { getFormApiMapper, getFormValidation } from "../../utils/PatientsUtils";
import CommentSection from "./components/Comment/CommentSection";
import DescriptionEditor from "./components/Description/DescriptionEditor";
import { getRandomKey } from "../../utils/ReactUtils";
import DevDebugJson from "../../components/Dev/DevDebugJson";

const styles = useStyles;

const TAB_PRIVATE = "PRIVATE";
const TAB_PUBLIC = "PUBLIC";

class PatientsDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      entity : 'patients',
      data : { fullName : ''},
      url : URL_PATIENTS_GET_BY_ID,
      isLoading : true,
      error : false,
      tab : TAB_PRIVATE,
      autoSave : getCookieWithDefault(COOKIE_AUTO_SAVE, DEFAULT_COOKIE_AUTO_SAVE),
      isOpenDialogCreate : false,
      isExtendedComment : false,
      loadGeneratedKey : getRandomKey()
    };

    this.loadData = this.loadData.bind(this);

    // This binding is necessary to make `this` work in the callback
    this.onPatientsList = this.onPatientsList.bind(this);
    this.onButtonTitleClick = this.onButtonTitleClick.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onChangeAutoSave = this.onChangeAutoSave.bind(this)

  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentDidMount() {
    console.log('MOUNT PATIENT DETAIL');
    let patientId = this.getId();
    this.setState({patientId : patientId}, () => {
      this.loadData();
    })
  }

  getId(){
    const {match} = this.props;
    return match.params.patientId;
  }

  loadData(callBackFunction = null, generateNewKey = true){
    const {patientId, url, loadGeneratedKey} = this.state;
    let urlWithParam = url.replace(":id", patientId);

    //this.setState({isLoading : true});

    API().get(urlWithParam)
      .then(res => {
        const data = res.data;
        let activeTab = null;
        console.log('RESPONSE : ' +JSON.stringify(res))
        if (data.card.cardBlocks.length > 0){
          activeTab = data.card.cardBlocks[0].id;
        }

        this.setState({
          data : data,
          isLoading: false,
          error: false,
          activeTab : activeTab,
          loadGeneratedKey : generateNewKey ? getRandomKey() : loadGeneratedKey
        }, () => {
          if (callBackFunction){
            callBackFunction();
          }
        });
      }).catch(e => {
        console.error(e);
        console.log('On get exception '+JSON.stringify(e));
        this.setState({ error : true, isLoading: false });
    })

  }

  onButtonTitleClick(){
    let url = this.getPatientCard();
    let win = window.open(url, '_blank');
    win.focus();
  }

  getPatientCard(){
    const {data} = this.state;

    if (data.card){
      let getUrl = window.location;
      let baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
      return baseUrl + "#/p/"+data.id+"/c/"+data.card.id;
    }

    return "";
  }

  onPatientsList(){
    const {history} = this.props;
    patientsList(history);
  }

  onChangeTab(event, newValue){
    this.setState({tab : newValue})
  }

  onChangeAutoSave(event){
    let value = event.target.checked;
    this.setState({autoSave : value}, () => {
      setCookie(COOKIE_AUTO_SAVE, value);
    })
  }

  onOpenCreate = () => {
    this.setState({isOpenDialogCreate : true});
  }

  onSubmitCreate = () => {
    this.setState({isOpenDialogCreate : false}, () => {
      this.loadData();
    });
  }

  onCloseCreate = () => {
    this.setState({isOpenDialogCreate : false});
  }

  render() {
    const { t, classes, debug = false } = this.props;
    const {error, data, isLoading, entity, tab, autoSave, isOpenDialogCreate, isExtendedComment,loadGeneratedKey} = this.state;

    let patientId = this.getId();
    let name = ""
    let configurationUpdateOrCreateModal = {};

    if (!isLoading && !error) {
      name = data.fullName;
      configurationUpdateOrCreateModal = {
        apiGet : URL_PATIENTS_GET_BY_ID.replace(":id", patientId),
        apiCreate : URL_PATIENTS_CREATE,
        apiUpdate : URL_PATIENTS_UPDATE.replace(":id", patientId),
        formValidation : getFormValidation(t),
        formApiMapper : (res) => {
          return getFormApiMapper(res)
        },
        entity: entity,
        titleField : (form) => {return form['name'] + " " + form["surname"]}
      }
    }

    let sizePrivateBlock = {
      xs:8,
      md:9
    }
    let sizeCommentBlock = {
      xs:4,
      md:3
    }

    if (isExtendedComment){
      sizePrivateBlock = {
        xs:6,
        md:7
      }
      sizeCommentBlock = {
        xs:6,
        md:5
      }
    }

    /**
     * <Grid item xs={8} md={9}>
     <PrivateSection data={data} isLoading={isLoading} onReload={this.loadData} autoSave={autoSave}/>
     </Grid>
     <Grid item xs={4} md={3}>
     <CommentSection data={data} isLoading={isLoading} onReload={this.loadData}/>
     </Grid>
     */

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" onClick={this.onPatientsList}>
            {t(entity+'.title')}
          </Link>
          <Typography color="primary">{data.fullName}</Typography>
        </Breadcrumbs>
        <Grid container spacing={4} className={classes.fullContainer} style={{marginTop: "10px"}}>
            { error ? <ErrorContainer msg={t('errors.loadData')} /> : (
              <>
                {isLoading ? (
                  <CircularProgress size={26}/>
                ) : (
                  <>

                    <Grid item xs={12}>
                      <Widget
                        upperTitle
                        bodyClass={classes.fullHeightBody}
                        className={classes.card}
                        disableWidgetMenu = {true}
                        header={
                          <Grid item xs={12}  style={{textAlign: "right"}}>
                            <Button variant="outlined" color="primary" size="small" onClick={this.onOpenCreate}>
                              {t('buttons.edit')}
                            </Button>
                          </Grid>
                        }
                      >
                        <Grid container spacing={1} style={{marginTop: "-40px"}}>
                          <Grid item xs={12}>
                            <div style={{display:'flex', flexDirection:"row", alignItems:"initial", marginRight:"5em"}}>
                              <div style={{minWidth:"15em"}}>
                                <Typography color="text" colorBrightness="secondary">
                                  {t(entity+'.detail.name')}
                                </Typography>
                                <Typography variant="h4">
                                  {data.name}
                                </Typography>
                              </div>
                              <div style={{paddingLeft:"20px", flex:"1"}}>
                                <Typography color="text" colorBrightness="secondary">
                                  {t(entity+'.detail.surname')}
                                </Typography>
                                <Typography variant="h4">
                                  {data.surname}
                                </Typography>
                              </div>
                              <div style={{paddingLeft:"30px", paddingRight:"30px"}}>
                                <Typography color="text" colorBrightness="secondary">
                                  {t(entity+'.detail.birthDate')}
                                </Typography>
                                <div style={{display:'flex', flexDirection:"row", alignItems:"center"}}>
                                  <div>
                                    <Typography variant="h4">
                                      {data.birthDate ? calculateAge(data.birthDate): "--"}
                                    </Typography>
                                  </div>

                                  <div style={{marginLeft:"10px", paddingTop:"10px"}}>
                                    <Typography size="sm">
                                      <b>{data.birthDate ? data.birthDate : "--"}</b>
                                    </Typography>
                                  </div>

                                </div>
                              </div>
                              <div style={{paddingLeft:"30px", paddingRight:"30px"}}>
                                <Typography color="text" colorBrightness="secondary">
                                  {t(entity+'.detail.sex')}
                                </Typography>
                                <Typography variant="h4">
                                  {data.sex ? t(entity+'.fields.sex.'+data.sex) : '--'}
                                </Typography>
                              </div>

                            </div>

                          </Grid>

                        </Grid>

                        <div style={{borderTop:"1px solid #EEE", marginTop:"10px"}}> </div>

                        <DescriptionEditor key={"descriptionEditor-"+loadGeneratedKey}
                                           data={data}
                                           onReload={() => {this.loadData(null, false)}}
                                           debug={debug}
                        />

                        {debug && (
                          <DevDebugJson data={data.card.description} title={"data.card.description"} component={"PatientsDetails"} />
                        )}

                        <div style={{borderTop:"1px solid #EEE", marginTop:"10px"}}> </div>

                        <Grid container spacing={1} style={{marginTop: "10px"}}>
                          <Grid item xs={3}>
                            <Typography color="text" colorBrightness="secondary">
                              {t(entity+'.detail.email')}
                            </Typography>
                            <Typography size={"sm"}>
                              {(data.email) ? data.email + " ("+ (data.emailMarketing ? t(entity+'.fields.emailMarketing.enabled') : t(entity+'.fields.emailMarketing.disabled')) +")" : "--"}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography color="text" colorBrightness="secondary">
                              {t(entity+'.detail.phone')}
                            </Typography>
                            <Typography size={"sm"}>
                              {data.phone}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography color="text" colorBrightness="secondary">
                              {t(entity+'.create.form.billingInfo.label')}
                            </Typography>
                            <Typography size={"sm"}>
                              {data.billingInfo ? data.billingInfo : '--'}
                            </Typography>
                          </Grid>

                          <Grid item xs={3}>
                            <Typography size={"sm"}>
                              {t(entity+'.detail.createdAt')}: {convert(data.createdAt)} ({data.createdBy.fullName})
                            </Typography>
                            <Typography size={"sm"}>
                              {t(entity+'.detail.updatedAt')}:{convert(data.updatedAt)} ({data.updatedBy.fullName})
                            </Typography>
                          </Grid>
                        </Grid>
                      </Widget>
                    </Grid>


                    <Grid item xs={12} >

                      <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.onChangeTab}
                        aria-label={t(entity+".detail.tab.ariaLabel")}
                      >

                        <Tab label={t(entity+'.detail.tabs.' + TAB_PRIVATE)} value={TAB_PRIVATE}/>
                        <Tab label={t(entity+'.detail.tabs.' + TAB_PUBLIC)} value={TAB_PUBLIC} />
                      </Tabs>

                      <div style={{marginTop:"0px"}}>

                        <div style={{textAlign:"right"}}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={autoSave}
                                onChange={this.onChangeAutoSave}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            }
                            label={t(entity+'.detail.buttons.autoSave')}
                          />
                        </div>

                        {tab === TAB_PRIVATE && (
                          <Grid container spacing={2}>
                            <Grid item {...sizePrivateBlock}>
                              <PrivateSection data={data} isLoading={isLoading} onReload={this.loadData} autoSave={autoSave}/>
                            </Grid>
                            <Grid item {...sizeCommentBlock} style={{position:"relative"}}>
                                <div style={{display:"flex",cursor:"pointer", position:"absolute", left:0, top:0, border : "1px solid #eee", backgroundColor:"white", margin:"9px"}}
                                     onClick={() => {this.setState({isExtendedComment : !isExtendedComment})}}
                                >
                                  {isExtendedComment ? (<ArrowForwardIcon/>) : (<ArrowBackIcon />)}
                                </div>
                                <CommentSection data={data} isLoading={isLoading} onReload={this.loadData}/>
                            </Grid>
                          </Grid>

                        )}
                        {tab === TAB_PUBLIC && (
                          <PublicSection data={data}
                                         isLoading={isLoading}
                                         onReload={this.loadData}
                                         autoSave={autoSave}
                          />
                        )}
                      </div>
                    </Grid>

                    <GeneralCreateUpdateFormDialog configuration={configurationUpdateOrCreateModal}
                                                   open={isOpenDialogCreate}
                                                   onSubmit={this.onSubmitCreate}
                                                   onClose={this.onCloseCreate}
                                                   id={patientId}
                                                   size="xl"
                    />
                  </>
                )
                }
              </>
            ) }
        </Grid>
        <ToastContainerCustom />

      </>
    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(PatientsDetails));

