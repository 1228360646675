import { convert } from "./DateUtils";
import React from "react";
import UserAvatar from "../components/UserAvatar";

export const ROLE_ADMIN = "ROLE_ADMIN"
export const ROLE_TEAM = "ROLE_TEAM"

export function hasRole(arrRoles, user){
  if (user && user.roles){
    for (let i = 0 ; i < arrRoles.length; i++){
      if (user.roles.includes(arrRoles[i])) {
        return true;
      }
    }
  }
  return false;
}

export function getUserColumns(classes, t) {
  return [
    {
      name: 'profilePicture',
      options: {
        customBodyRender: (value, row) => {
          return (
            <div>
            <UserAvatar url={value} name={row.rowData[1] + " " + row.rowData[2]} />
            </div>
            )
        }
      }
    },
    {
      name: 'name',
    },
    {
      name: 'surname',
    },
    {
      name: 'email',
    },
    {
      name: 'roles',
      options: {
        customBodyRender: (value) => {
          return getRolesLabel(value,t)
        }
      }
    },
    {
      name: 'createdAt',
      options: {
        customBodyRender: (value) => {
          return convert(value)
        }
      }
    },
    {
      name: 'updatedAt',
      options: {
        customBodyRender: (value) => {
          return convert(value)
        }
      }
    }
  ];
}


function getRolesLabel(role, t){
  let retString = "";
  for (let i = 0; i < role.length; i++){
    retString = retString + t('roles.'+role[i]);
    if (i < role.length -1){
      retString = retString + ","
    }
  }
  return retString;
}