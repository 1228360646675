import { useTheme } from "@material-ui/styles";
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { DATETIME_FORMAT, nowString } from "../../../utils/DateUtils";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Typography } from "../../Wrappers";
import { useUserState } from "../../../context/UserContext";
// styles
import useStyles from "./styles";

export default function TitleSectionSuggestion({ title="", onClick= () => {}}) {
  var theme = useTheme();
  var classes = useStyles(theme);
  const {i18n} = useTranslation();
  var { user } = useUserState();

  var nameAndDate = nowString(DATETIME_FORMAT) + " - " + (user ? user.fullName : "--");
  var date = nowString(DATETIME_FORMAT);
  var name = (user ? user.fullName : "--");

  return (
    <>
        <Grid item xs={12} >
          <Typography variant="h6" color="textSecondary">{title}</Typography>
        </Grid>

        <Grid item xs={12}>

          <Tooltip title={i18n.t("suggestion.timeName")}>
            <Button
              classes={{ root: classes.buttonRow }}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {onClick(nameAndDate)}}
              style={{marginLeft: "0px"}}
            >
              {nameAndDate}
            </Button>
          </Tooltip>

          <Tooltip title={i18n.t("suggestion.time")}>
            <Button
              classes={{ root: classes.buttonRow }}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {onClick(date)}}
              style={{marginLeft: "0px"}}
            >
              {date}
            </Button>
          </Tooltip>

          <Tooltip title={i18n.t("suggestion.name")}>
            <Button
              classes={{ root: classes.buttonRow }}
              variant="outlined"
              size="small"
              color="secondary"
              onClick={() => {onClick(name)}}
              style={{marginLeft: "0px"}}
            >
              {name}
            </Button>
          </Tooltip>
        </Grid>
    </>
  );
}