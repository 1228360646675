import React from "react";
import {
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import DashboardLayout from "./components/DashboardLayout";
import DevDebugJson from "../../components/Dev/DevDebugJson";
import {
  URL_DASHBOARD_STATS_CONSENT_GET,
  URL_DASHBOARD_STATS_MARKETING_GET,
  URL_DASHBOARD_STATS_PATIENTS_GET,
  URL_DASHBOARD_STATS_USERS_GET,
} from "../../utils/UrlConstants";

import PageTitle from "../../components/PageTitle";
import DashboardDataWidget from "./components/Table/DashboardDataWidget";
import DashboardStatsTimedGeneric from "./components/Table/DashboardStatsTimedGeneric";

import useStyles from "./styles";
import DashboardStatsUsers from "./components/Table/DashboardStatsUsers";
const styles = useStyles;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading : false,
      error : false,
    };


  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: true });
    console.log('Error detected componentDidCatch');
  }

  componentDidMount() {
    console.log('MOUNT Dashboard. Api Call prepare (loadData)');
  }

  render() {
    let {t, debug = false} = this.props;
    let {data, loadGeneratedKey} = this.state;

    let showOldDashboard = false;

    return (
      <div>

        {debug && (
          <div>
            <DevDebugJson data={loadGeneratedKey} title={"loadGeneratedKey"} component={"Dashboard"} />
            <DevDebugJson data={data} title={"data"} component={"data"} />
          </div>
        )}

        <PageTitle title={t('dashboard.title')}/>

        <Grid container spacing={4}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <DashboardDataWidget url={URL_DASHBOARD_STATS_PATIENTS_GET} component={<DashboardStatsTimedGeneric />}/>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <DashboardDataWidget url={URL_DASHBOARD_STATS_CONSENT_GET} component={<DashboardStatsTimedGeneric title={t('dashboard.titles.consent')} />}/>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <DashboardDataWidget url={URL_DASHBOARD_STATS_MARKETING_GET} component={<DashboardStatsTimedGeneric title={t('dashboard.titles.marketing')} />}/>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
            <DashboardDataWidget url={URL_DASHBOARD_STATS_USERS_GET} component={<DashboardStatsUsers />}/>
        </Grid>

        {showOldDashboard && (
          <DashboardLayout key={loadGeneratedKey} />
        )}

      </div>

    )
  }
}

export default withTranslation()(withStyles(styles, { withTheme: true})(Dashboard));